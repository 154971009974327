import {
  KeyData,
  StoreHeaderTitle,
  StoreKeyList,
  StoreNFTsBaseInfo,
  StoreNFTsVisualImage,
  useTheme,
  useAppSelector,
  StoreData,
  type StoreDataType,
  type RootState,
  DeployedContractsList,
  ActiveEventsList,
} from 'ds_share'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { WalletModalHandling } from '../../components/common/WalletModalHandling'

import styles from './StoreNFTs.module.css'

export const StoreNFTs = () => {
  const theme = useTheme()
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const { isConnected } = useAccount()
  const [, setIsShowConfirmModal] = useState(false)

  const navigate = useNavigate()
  const { storeName } = useParams()

  const handleShowConfirmModal = () => {
    setIsShowConfirmModal((prev) => !prev)
  }

  const handleNavigateBuyNFT = (storeUrl: string, tokenId: number) => {
    navigate(`/${storeUrl}/nft`, { state: { keyId: tokenId } })
  }

  const handleNavigateToScanPage = (address: string) => {
    if (process.env.REACT_APP_VERCEL_ENV === 'production') {
      window.open(`https://polygonscan.com/address/${address}`, '_blank')
    } else {
      window.open(`https://mumbai.polygonscan.com/address/${address}`, '_blank')
    }
  }

  const handleNavigateEventPage = (url: string) => {
    navigate(url)
  }

  const keys = KeyData.data

  const currentStore = StoreData.data.find((store: StoreDataType) => {
    return store.name === storeName
  })

  if (currentStore === undefined) return null

  return (
    <>
      <WalletModalHandling />
      <div className={theme?.media.isMobile ? styles.mobileContainer : styles.container}>
        <div className={theme?.media.isMobile ? styles.mobileHeader : styles.header}>
          <StoreHeaderTitle title="Store Detail" handleBackNavigate={() => navigate(-1)} />
        </div>
        <StoreNFTsVisualImage storeVisualImage={currentStore.visual} />
        <div
          className={theme?.media.isMobile ? styles.mobileBaseInfoContaienr : styles.baseInfoContainer}
          style={{ background: theme?.media.isMobile ? theme.color.dark : 'inherit' }}
        >
          <StoreNFTsBaseInfo store={currentStore} />
          <div className={theme?.media.isMobile ? styles.mobileOwnershipContainer : styles.ownershipContainer}>
            <StoreKeyList
              isLoggedIn={isConnectPaperWallet || isConnected}
              keys={keys}
              handleNavigateBuyNFT={handleNavigateBuyNFT}
              handleShowConfirmModal={handleShowConfirmModal}
            />
          </div>
          <div className={theme?.media.isMobile ? styles.mobileOwnershipContainer : styles.ownershipContainer}>
            <ActiveEventsList store={currentStore} handleNavigateEventPage={handleNavigateEventPage} />
          </div>
          <div className={theme?.media.isMobile ? styles.mobileOwnershipContainer : styles.ownershipContainer}>
            <DeployedContractsList store={currentStore} handleNavigateToScanPage={handleNavigateToScanPage} />
          </div>
        </div>
      </div>
    </>
  )
}
