import {
  ExploreDeStoreModal,
  HomeCta,
  HomeEnterDeStoreSection,
  HomeExploreDeStoreSection,
  Spacer,
  useTheme,
  useAppSelector,
  type RootState,
  useAppDispatch,
  closeAllModal,
  showConfirmModal,
} from 'ds_share'
import { useMemo, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { WalletModalHandling } from '../../components/common/WalletModalHandling'

import styles from './Home.module.css'

export const Home = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const { isConnected } = useAccount()

  const [isShowExploleModal] = useState(false)

  const navigate = useNavigate()

  const handleShowConfirmModal = () => {
    if (isConnectPaperWallet || isConnected) {
      navigate('/stores')
      // if (auth.hasNFT) {
      // navigate('/live')
      // } else {
      //   setIsShowExploreModal(true)
      // }
    } else {
      dispatch(showConfirmModal())
    }
  }

  const isShowExploreModal = useMemo(() => {
    if ((isConnectPaperWallet && isShowExploleModal) || (isConnected && isShowExploleModal)) {
      return true
    } else {
      return false
    }
  }, [isConnectPaperWallet, isConnected, isShowExploleModal])

  return (
    <>
      <WalletModalHandling />
      <ExploreDeStoreModal visible={isShowExploreModal} onRequestClose={() => dispatch(closeAllModal())} />
      <div className={theme?.media.isMobile ? styles.mobileContainer : styles.container}>
        <HomeCta logoImagePath="/logo/logo_icon_white.svg" keyImagePath="/key/key_main_rainbow.svg" />
        <div className={styles.buttonContainer}>
          <HomeEnterDeStoreSection handleOpenClick={handleShowConfirmModal} />
          <Spacer direction="horizontal" space={24} />
          <HomeExploreDeStoreSection />
        </div>
      </div>
    </>
  )
}
