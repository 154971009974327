"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc2) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc2 = __getOwnPropDesc(from, key)) || desc2.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  ActiveEventItem: () => ActiveEventItem,
  ActiveEventsList: () => ActiveEventsList,
  AppsModal: () => AppsModal,
  AppsSlide: () => AppsSlide,
  AuthorizationSubHeaderForProposal: () => AuthorizationSubHeaderForProposal,
  AuthorizedByNFTHeader: () => AuthorizedByNFTHeader,
  BrandSalesRankingData: () => BrandSalesRankingData,
  Button: () => Button,
  ButtonWithChildren: () => ButtonWithChildren,
  CheckInCta: () => CheckInCta,
  CheckInRewardModal: () => CheckInRewardModal,
  CheckinStoreRecordsTable: () => CheckinStoreRecordsTable,
  ConnectWalletModal: () => ConnectWalletModal,
  ContainerLayout: () => ContainerLayout,
  DashboardIndicators: () => DashboardIndicators,
  DashboardTabview: () => DashboardTabview,
  DeStoreThemeProvider: () => DeStoreThemeProvider,
  DeployedContractItem: () => DeployedContractItem,
  DeployedContractsList: () => DeployedContractsList,
  ExploreDeStoreModal: () => ExploreDeStoreModal,
  FungibleTokenList: () => FungibleTokenList,
  GetTokenReceiveSection: () => GetTokenReceiveSection,
  Header: () => Header,
  HomeCta: () => HomeCta,
  HomeEnterDeStoreSection: () => HomeEnterDeStoreSection,
  HomeExploreDeStoreSection: () => HomeExploreDeStoreSection,
  Image: () => Image,
  InitSpinner: () => InitSpinner,
  KeyData: () => KeyData,
  LineItemData: () => LineItemData,
  Link: () => Link,
  LiveHeaderCenterChildren: () => LiveHeaderCenterChildren,
  LiveLineItems: () => LiveLineItems,
  LiveSalesInfoItem: () => LiveSalesInfoItem,
  LiveSalesInfoItems: () => LiveSalesInfoItems,
  MembershipNFTsItem: () => MembershipNFTsItem,
  MembershipNFTsList: () => MembershipNFTsList,
  MobileHeader: () => MobileHeader,
  Modal: () => Modal,
  NetworkDifferentModal: () => NetworkDifferentModal,
  OutlineButton: () => OutlineButton,
  POAPNFTsList: () => POAPNFTsList,
  PrimaryButton: () => PrimaryButton,
  ProfileData: () => ProfileData,
  ProposalMenuAppData: () => ProposalMenuAppData,
  ProposalRequirementSection: () => ProposalRequirementSection,
  ProposalsUserRequirementSection: () => ProposalsUserRequirementSection,
  SalesAndExpenseData: () => SalesAndExpenseData,
  SettingsHeaderCenterChildren: () => SettingsHeaderCenterChildren,
  SettingsItem: () => SettingsItem,
  SettingsItems: () => SettingsItems,
  SettingsItemsPropsData: () => SettingsItemsPropsData,
  Spacer: () => Spacer,
  Spinner: () => Spinner,
  StoreData: () => StoreData,
  StoreHeaderTitle: () => StoreHeaderTitle,
  StoreIndicatorsData: () => StoreIndicatorsData,
  StoreKeyBaseInfo: () => StoreKeyBaseInfo,
  StoreKeyDescriptionItem: () => StoreKeyDescriptionItem,
  StoreKeyList: () => StoreKeyList,
  StoreKeyListItem: () => StoreKeyListItem,
  StoreListItem: () => StoreListItem,
  StoreLiveStreamingData: () => StoreLiveStreamingData,
  StoreMenuAppData: () => StoreMenuAppData,
  StoreNFTsBaseInfo: () => StoreNFTsBaseInfo,
  StoreNFTsVisualImage: () => StoreNFTsVisualImage,
  StoreSalesInfoData: () => StoreSalesInfoData,
  TabGroupSpacer: () => TabGroupSpacer,
  TabOverlay: () => TabOverlay,
  Text: () => Text3,
  Toasts: () => Toasts,
  WalletConfirmModal: () => WalletConfirmModal,
  closeAllModal: () => closeAllModal,
  connectWallet: () => connectWallet,
  disconnectWallet: () => disconnectWallet,
  hideToast: () => hideToast,
  paperAuthLoadingEnd: () => paperAuthLoadingEnd,
  paperAuthLoadingStart: () => paperAuthLoadingStart,
  poapAccessEnd: () => poapAccessEnd,
  poapAccessStart: () => poapAccessStart,
  showConfirmModal: () => showConfirmModal,
  showConnectModal: () => showConnectModal,
  showToast: () => showToast,
  store: () => store,
  storeZeroAddresses: () => storeZeroAddresses,
  useAppDispatch: () => useAppDispatch,
  useAppSelector: () => useAppSelector,
  useLinking: () => useLinking,
  useMediaQuery: () => useMediaQuery,
  useTheme: () => useTheme
});
module.exports = __toCommonJS(src_exports);

// src/globalStore/index.tsx
var import_toolkit4 = require("@reduxjs/toolkit");
var import_react_redux = require("react-redux");

// src/globalStore/auth.tsx
var import_toolkit = require("@reduxjs/toolkit");
var authSlice = (0, import_toolkit.createSlice)({
  name: "auth",
  initialState: {
    isConnectPaperWallet: false,
    isPaperAuthLoading: false,
    connectedPaperWallet: null,
    ownedDeStoreNFT: [],
    poapAccessFlag: false
  },
  reducers: {
    paperAuthLoadingStart: (state) => {
      state.isPaperAuthLoading = true;
    },
    connectWallet: (state, action) => {
      const { authType, walletAddress } = action.payload;
      state.isConnectPaperWallet = true;
      state.connectedPaperWallet = {
        authType,
        walletAddress
      };
      state.isPaperAuthLoading = false;
    },
    disconnectWallet: (state) => {
      if (!state.isConnectPaperWallet || state.connectedPaperWallet === null) {
        console.log("Error: disconnectWallet but isConnectWallet is false");
      } else {
        state.isConnectPaperWallet = false;
        state.connectedPaperWallet = null;
        state.isPaperAuthLoading = false;
      }
    },
    paperAuthLoadingEnd: (state) => {
      state.isPaperAuthLoading = false;
    },
    poapAccessStart: (state) => {
      state.poapAccessFlag = true;
    },
    poapAccessEnd: (state) => {
      state.poapAccessFlag = false;
    }
  }
});
var {
  paperAuthLoadingStart,
  connectWallet,
  disconnectWallet,
  paperAuthLoadingEnd,
  poapAccessStart,
  poapAccessEnd
} = authSlice.actions;
var auth_default = authSlice;

// src/globalStore/modalCtrl.tsx
var import_toolkit2 = require("@reduxjs/toolkit");
var modalCtrlSlice = (0, import_toolkit2.createSlice)({
  name: "modalCtrl",
  initialState: {
    isShowConfirmModal: false,
    isShowConnectModal: false
  },
  reducers: {
    showConfirmModal: (state) => {
      state.isShowConfirmModal = true;
      state.isShowConnectModal = false;
    },
    showConnectModal: (state) => {
      state.isShowConnectModal = true;
      state.isShowConfirmModal = false;
    },
    closeAllModal: (state) => {
      state.isShowConfirmModal = false;
      state.isShowConnectModal = false;
    }
  }
});
var { showConfirmModal, showConnectModal, closeAllModal } = modalCtrlSlice.actions;
var modalCtrl_default = modalCtrlSlice;

// src/globalStore/toast.tsx
var import_toolkit3 = require("@reduxjs/toolkit");
var toastSlice = (0, import_toolkit3.createSlice)({
  name: "toast",
  initialState: {
    toasts: []
  },
  reducers: {
    showToast: (state, action) => {
      state.toasts = [action.payload, ...state.toasts];
    },
    hideToast: (state, action) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    }
  }
});
var { showToast, hideToast } = toastSlice.actions;
var toast_default = toastSlice;

// src/globalStore/index.tsx
var store = (0, import_toolkit4.configureStore)({
  reducer: {
    [auth_default.name]: auth_default.reducer,
    [modalCtrl_default.name]: modalCtrl_default.reducer,
    [toast_default.name]: toast_default.reducer
  }
});
var useAppDispatch = () => (0, import_react_redux.useDispatch)();
var useAppSelector = import_react_redux.useSelector;

// src/constants/deployedContractAddress.ts
var MUMBAI_TEST_StoreZeroVoteAddress = "0xc73eaa2F8e5D3A017C246b6CF3e9Dd4fC443e1AA";
var MUMBAI_TEST_StoreManagerAddress = "0x8BBE92c53a98f2Fc199780815D59ab507Dd4B4E9";
var MUMBAI_TEST_StoreTreasuryAddress = "0xA1e470446DE93E87285864Da4C625023bDb7f82D";
var POLYGON_MAIN_StoreZeroManagerAddress = "0xC87738Be56f29a0a2872De046cb4e3dEB9f9aA65";
var POLYGON_MAIN_StoreZeroVoteAddress = "0xa304504f942A0ba5397d88de9ee2CE634AdBd2a7";
var POLYGON_MAIN_StoreZeroTrasuryAddress = "0xA1e470446DE93E87285864Da4C625023bDb7f82D";
var storeZeroAddresses = () => {
  if (process.env["REACT_APP_VERCEL_ENV"] === "production") {
    return {
      VOTE_CONTRACT_ADDRESS: POLYGON_MAIN_StoreZeroVoteAddress,
      STORE_MANAGER_ADDRESS: POLYGON_MAIN_StoreZeroManagerAddress,
      TREASURY_ADDRESS: POLYGON_MAIN_StoreZeroTrasuryAddress
    };
  } else {
    return {
      VOTE_CONTRACT_ADDRESS: MUMBAI_TEST_StoreZeroVoteAddress,
      STORE_MANAGER_ADDRESS: MUMBAI_TEST_StoreManagerAddress,
      TREASURY_ADDRESS: MUMBAI_TEST_StoreTreasuryAddress
    };
  }
};

// src/components/ui/Button.tsx
var import_react4 = require("react");
var import_react_native3 = require("react-native");

// src/hooks/useLinking.ts
var import_react = require("react");
var import_react_native = require("react-native");
var prefix = "destore://destore";
var useLinking = () => {
  const handleLinking = (0, import_react.useCallback)(async (url) => {
    if (import_react_native.Platform.OS === "web")
      throw new Error("Linking must be used in native");
    if (!url)
      throw new Error("Url is required in Linking");
    const supported = await import_react_native.Linking.canOpenURL(url);
    if (!supported)
      throw new Error("The specified url is not supported");
    return import_react_native.Linking.openURL(prefix + url);
  }, []);
  return { handleLinking };
};

// src/hooks/useMediaQuery.ts
var import_react_native2 = require("react-native");

// src/theme/index.ts
var DeStoreColor = {
  dark: "#161f29",
  // background
  primaryBlack: "#161f29",
  // text primary
  secondaryWhite: "#ffffff",
  // text secondary
  secondaryLight: "#f6f6f6",
  // text secondary light
  gray: "#b7b7b7",
  // gray or border
  secondaryGray: "#67686A",
  // gray or border light
  secondaryLiteGray: "#C5C8CC",
  // gray or border more lighter
  secondaryLightGreen: "#E4EFE6",
  mint: "#bdfdc7",
  // mint, ex) primary button background
  purple: "#d8c9f6",
  pink: "#ffa0a0",
  black: "#000000"
};
var DeStoreFontSize = {
  xxs: 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  "2xl": 24,
  "3xl": 28,
  "4xl": 36,
  "5xl": 40,
  "6xl": 48
};
var DeStoreRadius = {
  none: 0,
  sm: 2,
  base: 4,
  md: 6,
  lg: 8,
  full: 999
};
var DeStoreBreakPoint = {
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280
};
var DeStoreTheme = {
  color: DeStoreColor,
  fontSize: DeStoreFontSize,
  radius: DeStoreRadius,
  breakpoint: DeStoreBreakPoint,
  media: {
    isMobile: false,
    isTablet: false,
    isDesktop: false
  }
};

// src/hooks/useMediaQuery.ts
var useMediaQuery = () => {
  const { width } = (0, import_react_native2.useWindowDimensions)();
  const isMobile = width < DeStoreBreakPoint.md;
  const isTablet = DeStoreBreakPoint.md <= width && width <= DeStoreBreakPoint.lg;
  const isDesktop = DeStoreBreakPoint.lg < width;
  return { isMobile, isTablet, isDesktop };
};

// src/hooks/useTheme.ts
var import_react3 = require("react");

// src/theme/ThemeProvider.tsx
var import_react2 = require("react");
var import_jsx_runtime = require("react/jsx-runtime");
var ThemeStateContext = (0, import_react2.createContext)(void 0);
var DeStoreThemeProvider = ({ children }) => {
  const { isMobile, isTablet, isDesktop } = useMediaQuery();
  const theme = (0, import_react2.useMemo)(() => {
    return {
      ...DeStoreTheme,
      media: {
        ...DeStoreTheme.media,
        isMobile,
        isTablet,
        isDesktop
      }
    };
  }, [isMobile, isTablet, isDesktop]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ThemeStateContext.Provider, { value: theme, children });
};

// src/hooks/useTheme.ts
var useTheme = () => {
  return (0, import_react3.useContext)(ThemeStateContext);
};

// src/components/ui/Button.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var DefaultButton = ({
  title,
  subText,
  textColor,
  buttonColor,
  pressableColor,
  isOutline = false,
  customStyle,
  ...props
}) => {
  const [isHover, setIsHover] = (0, import_react4.useState)(false);
  const theme = useTheme();
  const handleHoverIn = (0, import_react4.useCallback)(() => {
    setIsHover(true);
  }, []);
  const handleHoverOut = (0, import_react4.useCallback)(() => {
    setIsHover(false);
  }, []);
  const styles3 = (0, import_react4.useMemo)(() => {
    return import_react_native3.StyleSheet.create({
      pressable: {
        paddingHorizontal: 40,
        paddingVertical: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: isOutline ? pressableColor : isHover ? "transparent" : "transparent",
        borderRadius: theme?.radius.full,
        color: isHover ? theme?.color.primaryBlack : textColor,
        backgroundColor: isHover ? pressableColor : buttonColor,
        opacity: isOutline ? 1 : isHover ? 0.7 : 1
      },
      pressablePressed: {
        backgroundColor: pressableColor,
        opacity: 0.7
      },
      text: {
        color: "inherit",
        fontWeight: "bold",
        textAlign: "center"
      },
      subText: {
        color: "gray",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile, pressableColor, isHover]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
    import_react_native3.Pressable,
    {
      style: (state) => [styles3.pressable, customStyle, state.pressed && styles3.pressablePressed],
      onHoverIn: () => {
        props.onHoverIn, handleHoverIn();
      },
      onHoverOut: () => {
        props.onHoverOut, handleHoverOut();
      },
      ...props,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react_native3.Text, { style: styles3.text, children: title }),
        subText && /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react_native3.Text, { style: styles3.subText, children: subText })
      ]
    }
  );
};
var Button = ({ title, textColor, buttonColor, ...props }) => {
  const theme = useTheme();
  const txtColor = textColor || theme?.color.secondaryWhite || "#000000";
  const btnColor = buttonColor || theme?.color.mint || "#ffffff";
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(DefaultButton, { title, textColor: txtColor, buttonColor: btnColor, pressableColor: btnColor, ...props });
};
var PrimaryButton = ({ title, textColor, buttonColor, pressableColor, ...props }) => {
  const theme = useTheme();
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    DefaultButton,
    {
      title,
      textColor: textColor || theme?.color.primaryBlack || "",
      buttonColor: buttonColor || theme?.color.mint || "",
      pressableColor: pressableColor || theme?.color.mint || "",
      ...props
    }
  );
};
var OutlineButton = ({
  title,
  subText,
  textColor,
  buttonColor,
  pressableColor,
  ...props
}) => {
  const theme = useTheme();
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    DefaultButton,
    {
      title,
      subText,
      textColor: textColor || theme?.color.secondaryWhite || "",
      buttonColor: buttonColor || "transparent",
      pressableColor: pressableColor || theme?.color.mint || "",
      isOutline: true,
      ...props
    }
  );
};
var ButtonWithChildren = ({ children, buttonColor, customStyle, ...props }) => {
  const [isHover, setIsHover] = (0, import_react4.useState)(false);
  const theme = useTheme();
  const handleHoverIn = (0, import_react4.useCallback)(() => {
    setIsHover(true);
  }, []);
  const handleHoverOut = (0, import_react4.useCallback)(() => {
    setIsHover(false);
  }, []);
  const styles3 = (0, import_react4.useMemo)(() => {
    return import_react_native3.StyleSheet.create({
      pressable: {
        paddingHorizontal: 40,
        paddingVertical: 16,
        borderRadius: theme?.radius.full,
        color: theme?.color.primaryBlack,
        backgroundColor: buttonColor,
        opacity: isHover ? 0.7 : 1
      },
      pressablePressed: {
        backgroundColor: buttonColor,
        opacity: 0.7
      },
      text: {
        color: "inherit",
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile, buttonColor, isHover]);
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
    import_react_native3.Pressable,
    {
      style: (state) => [styles3.pressable, customStyle, state.pressed && styles3.pressablePressed],
      onHoverIn: () => {
        props.onHoverIn, handleHoverIn();
      },
      onHoverOut: () => {
        props.onHoverOut, handleHoverOut();
      },
      ...props,
      children
    }
  );
};

// src/components/ui/Image.tsx
var import_react_native4 = require("react-native");
var import_jsx_runtime3 = require("react/jsx-runtime");
var Image = ({ ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_react_native4.Image, { ...props });
};

// src/components/ui/Link.tsx
var import_react_native5 = require("react-native");
var import_react_router_dom = require("react-router-dom");
var import_jsx_runtime4 = require("react/jsx-runtime");
var Link = ({ children, customStyle, webStyle, ...props }) => {
  const theme = useTheme();
  const styles3 = import_react_native5.StyleSheet.create({
    link: {
      textDecorationColor: theme?.color.primaryBlack,
      color: theme?.color.primaryBlack
    }
  });
  if (import_react_native5.Platform.OS === "web" && !["http://", "https://"].includes(props.href)) {
    return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(import_react_router_dom.Link, { style: webStyle, to: props.href, role: "link", children });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(import_react_native5.Text, { style: [styles3.link, customStyle], accessibilityRole: props.accessibilityRole || "link", ...props, children });
};

// src/components/ui/Modal.tsx
var import_react5 = require("react");
var import_fi = require("react-icons/fi");
var import_react_native7 = require("react-native");

// src/components/ui/Spacer.tsx
var import_react_native6 = require("react-native");
var import_jsx_runtime5 = require("react/jsx-runtime");
var Spacer = ({ direction, space }) => {
  const styles3 = import_react_native6.StyleSheet.create({
    spacer: {
      width: direction === "vertical" ? space : "100%",
      height: direction === "horizontal" ? space : "100%"
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_react_native6.View, { style: styles3.spacer });
};

// src/components/ui/Modal.tsx
var import_jsx_runtime6 = require("react/jsx-runtime");
var Modal = ({
  maxWidth = 576,
  width = "90%",
  background = "secondaryWhite",
  animationType = "fade",
  transparent = true,
  headerChildren,
  bodyChildren,
  footerChildren,
  ...props
}) => {
  const { onRequestClose } = props;
  const theme = useTheme();
  const styles3 = (0, import_react5.useMemo)(() => {
    return import_react_native7.StyleSheet.create({
      overlay: {
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "0px 0px 1px 4px rgba(0, 0, 0, 0.25)"
      },
      container: {
        maxWidth,
        width,
        margin: "auto",
        paddingVertical: 40,
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        borderRadius: theme?.radius.lg,
        backgroundColor: theme?.color[background],
        position: "relative"
      },
      fixIcon: {
        width: "24px",
        position: "absolute",
        top: 16,
        right: 16
      },
      header: {
        width: "100%"
      },
      body: {
        width: "100% "
      },
      footer: {
        width: "100%"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_react_native7.Modal, { animationType, transparent, ...props, children: [
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.Pressable, { style: styles3.overlay, onPress: onRequestClose }),
    /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_react_native7.View, { style: styles3.container, children: [
      import_react_native7.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.Pressable, { style: styles3.fixIcon, onPress: onRequestClose, children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_fi.FiX, { color: theme?.color.primaryBlack, size: 24 }) }) : null,
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.View, { style: styles3.header, children: headerChildren }),
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(Spacer, { direction: "horizontal", space: 12 }),
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.View, { style: styles3.body, children: bodyChildren }),
      footerChildren && /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_jsx_runtime6.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(Spacer, { direction: "horizontal", space: 32 }),
        /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react_native7.View, { style: styles3.footer, children: footerChildren })
      ] })
    ] })
  ] });
};

// src/components/ui/Spinner.tsx
var import_react_native8 = require("react-native");
var import_jsx_runtime7 = require("react/jsx-runtime");
var Spinner = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_react_native8.View, { style: styles.container, children: /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_react_native8.Image, { source: { uri: "/key/key_rainbow.gif" }, style: styles.image, resizeMode: "contain" }) });
};
var InitSpinner = () => {
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(import_react_native8.View, { style: styles.view, children: /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(Spinner, {}) });
};
var styles = import_react_native8.StyleSheet.create({
  view: {
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    width: 160,
    height: 160
  },
  image: {
    width: "80%",
    height: "80%"
  }
});

// src/components/ui/Text.tsx
var import_react_native9 = require("react-native");
var import_jsx_runtime8 = require("react/jsx-runtime");
var Text3 = ({ children, ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_react_native9.Text, { ...props, children });
};

// src/components/ui/Toast.tsx
var import_react6 = require("react");
var import_react_native10 = require("react-native");
var import_jsx_runtime9 = require("react/jsx-runtime");
var Toast = ({ ...props }) => {
  const { id, message, description, status } = props;
  const opacity = (0, import_react6.useMemo)(() => new import_react_native10.Animated.Value(0), []);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  (0, import_react6.useEffect)(() => {
    const timerId = setTimeout(() => {
      dispatch(hideToast(id));
    }, 5200);
    return () => {
      clearTimeout(timerId);
    };
  }, [dispatch]);
  (0, import_react6.useEffect)(() => {
    import_react_native10.Animated.timing(opacity, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true
    }).start();
    const timerId = setTimeout(() => {
    }, 5200);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  const TOAST_COLOR = {
    SUCCESS: theme?.color.mint,
    ERROR: theme?.color.pink
  };
  const TEXT_COLOR = {
    SUCCESS: theme?.color.primaryBlack,
    ERROR: theme?.color.secondaryLight
  };
  const styles3 = (0, import_react6.useMemo)(
    () => import_react_native10.StyleSheet.create({
      container: {
        minWidth: 280,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        backgroundColor: TOAST_COLOR[status],
        color: TEXT_COLOR[status]
      },
      title: {
        fontSize: 18,
        fontWeight: "bold",
        color: TEXT_COLOR[status]
      },
      description: {
        fontSize: 14,
        color: theme?.color.secondaryLiteGray
      }
    }),
    [status]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(import_react_native10.Animated.View, { style: [{ opacity }, styles3.container], children: [
    /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Text3, { style: styles3.title, children: message }),
    description ? /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Text3, { style: styles3.description, children: description }) : null
  ] });
};
var Toasts = () => {
  const theme = useTheme();
  const toasts = useAppSelector((state) => state.toast.toasts);
  const styles3 = import_react_native10.StyleSheet.create({
    container: {
      position: "absolute",
      top: theme?.media.isMobile ? 20 : 100,
      right: theme?.media.isMobile ? 20 : 80,
      zIndex: 10
    },
    toastContainer: { marginTop: 10 }
  });
  if (toasts.length === 0)
    return null;
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_react_native10.View, { style: styles3.container, children: toasts.map((toast, i) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_react_native10.View, { style: i !== 0 ? styles3.toastContainer : null, children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(Toast, { ...toast }) }, toast.id)) });
};

// src/components/common/AppsModal.tsx
var import_react9 = require("react");
var import_react_native13 = require("react-native");

// src/components/common/AppCards.tsx
var import_react8 = require("react");
var import_react_native12 = require("react-native");

// src/components/common/AppCard.tsx
var import_react7 = require("react");
var import_react_native11 = require("react-native");
var import_jsx_runtime10 = require("react/jsx-runtime");
var AppCard = ({ imagePath, name, description, upRightArrowImagePath }) => {
  const theme = useTheme();
  const styles3 = (0, import_react7.useMemo)(() => {
    return import_react_native11.StyleSheet.create({
      container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      },
      iconAndNameContainer: {
        flexDirection: "row",
        alignItems: "center",
        gap: 24
      },
      image: {
        width: 60,
        height: 60
      },
      nameContainer: {
        maxWidth: theme?.media.isMobile ? 165 : 233,
        minWidth: theme?.media.isMobile ? 165 : 233,
        gap: 4
      },
      name: {
        color: theme?.color.secondaryWhite,
        fontSize: 14,
        lineHeight: 18
      },
      description: {
        color: theme?.color.secondaryWhite,
        opacity: 0.5,
        fontSize: 12,
        lineHeight: 16
      },
      upRightArrowButton: {
        width: 38,
        height: 38,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 48,
        alignItems: "center",
        justifyContent: "center"
      },
      upRightArrowImage: {
        width: 22,
        height: 22
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_react_native11.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_react_native11.View, { style: styles3.iconAndNameContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Image, { source: { uri: imagePath }, style: styles3.image }),
      /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(import_react_native11.View, { style: styles3.nameContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Text3, { style: styles3.name, children: name }),
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Text3, { style: styles3.description, children: description })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.upRightArrowButton,
        buttonColor: theme?.color.secondaryWhite + "1A",
        children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(Image, { source: { uri: upRightArrowImagePath }, style: styles3.upRightArrowImage })
      }
    )
  ] });
};

// src/components/common/AppCards.tsx
var import_jsx_runtime11 = require("react/jsx-runtime");
var AppCards = ({ appCards }) => {
  const styles3 = (0, import_react8.useMemo)(() => {
    return import_react_native12.StyleSheet.create({
      container: {
        width: "100%",
        gap: 24
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(import_react_native12.View, { style: styles3.container, children: appCards.map((appCard) => /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(AppCard, { ...appCard }, appCard.name)) });
};

// src/components/common/AppsModal.tsx
var import_jsx_runtime12 = require("react/jsx-runtime");
var ModalBody = ({ appCards }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(AppCards, { appCards });
};
var AppsModal = (props) => {
  const { appCards, close } = props;
  const theme = useTheme();
  const styles3 = (0, import_react9.useMemo)(() => {
    return import_react_native13.StyleSheet.create({
      outside: {
        flex: 1,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
      },
      container: {
        padding: 24,
        borderRadius: 16,
        backgroundColor: theme?.color.primaryBlack,
        maxWidth: theme?.media.isMobile ? void 0 : 427
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_jsx_runtime12.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.Modal, { animationType: "fade", ...props, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.TouchableOpacity, { style: styles3.outside, onPress: close, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.TouchableWithoutFeedback, { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_react_native13.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(ModalBody, { appCards }) }) }) }) }) });
};

// src/components/common/AppsSlide.tsx
var import_react10 = require("react");
var import_io5 = require("react-icons/io5");
var import_react_native14 = require("react-native");
var import_jsx_runtime13 = require("react/jsx-runtime");
var AppsSlide = ({ close, appCards, ...props }) => {
  const theme = useTheme();
  const styles3 = (0, import_react10.useMemo)(() => {
    return import_react_native14.StyleSheet.create({
      outside: {
        flex: 1,
        width: "100%",
        height: "100%"
      },
      container: {
        backgroundColor: theme?.color.primaryBlack,
        height: "100%",
        marginTop: 23,
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
        padding: 32
      },
      closeButton: {
        width: 56,
        height: 56,
        borderRadius: 16,
        paddingHorizontal: 0,
        paddingVertical: 0,
        alignItems: "center",
        justifyContent: "center",
        color: theme?.color.secondaryWhite
      },
      store: {
        marginTop: 16,
        marginBottom: 48,
        fontSize: 26,
        lineHeight: 34,
        color: theme?.color.secondaryWhite
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.Modal, { animationType: "slide", ...props, children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.TouchableOpacity, { style: styles3.outside, onPress: close, children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.TouchableWithoutFeedback, { children: /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(import_react_native14.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
      ButtonWithChildren,
      {
        buttonColor: theme?.color.secondaryWhite + "1A",
        customStyle: styles3.closeButton,
        onPress: close,
        children: import_react_native14.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_io5.IoCloseOutline, { color: theme?.color.secondaryWhite, size: 28 }) : "x"
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_react_native14.Text, { style: styles3.store, children: "Store_O Menu" }),
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(AppCards, { appCards })
  ] }) }) }) });
};

// src/components/common/AuthorizedByNFTHeader.tsx
var import_react11 = require("react");
var import_io52 = require("react-icons/io5");
var import_react_native15 = require("react-native");
var import_jsx_runtime14 = require("react/jsx-runtime");
var AuthorizedByNFTHeader = ({ headerCenterChildren, headerCenterCustomStyle, noSideButtons }) => {
  const theme = useTheme();
  const [isShowStoreMenu, setIsShowStoreMenu] = (0, import_react11.useState)(false);
  const styles3 = (0, import_react11.useMemo)(() => {
    return import_react_native15.StyleSheet.create({
      container: {
        minWidth: "100%",
        justifyContent: theme?.media.isMobile ? "space-between" : "center",
        flexDirection: "row",
        alignItems: "center"
      },
      // width: 1/3
      itemContainerOnNonMobile: {
        flex: 1,
        alignItems: "center"
      },
      // visibility: hidden
      itemContainerOnNoSideButtons: {
        opacity: 0,
        height: 0
      },
      fourDotsButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.dark,
        color: theme?.color.secondaryWhite,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      fourDotsButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16
      },
      fourDotsButtonNotMobile: {
        width: 56,
        height: 56,
        borderRadius: 28
      },
      fourDotsImage: {
        width: 20,
        height: 20
      },
      walletButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.mint,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      walletButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      walletButtonNotMobile: {
        borderRadius: 60,
        width: 147,
        height: 56
      },
      walletImageContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      },
      walletImage: {
        width: 29,
        height: 30
      },
      walletButtonText: {
        marginLeft: 8,
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.primaryBlack,
        fontWeight: "bold"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native15.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      import_react_native15.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.fourDotsButton,
              theme?.media.isMobile ? styles3.fourDotsButtonMobile : styles3.fourDotsButtonNotMobile
            ],
            onPress: () => setIsShowStoreMenu((prev) => !prev),
            children: isShowStoreMenu ? import_react_native15.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_io52.IoCloseOutline, { color: theme?.color.secondaryWhite, size: 28 }) : "x" : /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(Image, { source: { uri: "/icon/four_dots.svg" }, style: styles3.fourDotsImage })
          }
        )
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_react_native15.View, { style: headerCenterCustomStyle, children: headerCenterChildren }),
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      import_react_native15.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.walletButton,
              theme?.media.isMobile ? styles3.walletButtonMobile : styles3.walletButtonNotMobile
            ],
            children: /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)(import_react_native15.View, { style: styles3.walletImageContainer, children: [
              /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(Image, { source: { uri: "/icon/wallet.svg" }, style: styles3.walletImage }),
              !theme?.media.isMobile && /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(Text3, { style: styles3.walletButtonText, children: "Wallet" })
            ] })
          }
        )
      }
    ),
    theme?.media.isMobile ? /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      AppsSlide,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: StoreMenuAppData.data
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
      AppsModal,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: StoreMenuAppData.data
      }
    )
  ] });
};

// src/components/common/AuthorizationSubHeaderForProposal.tsx
var import_react12 = require("react");
var import_io53 = require("react-icons/io5");
var import_react_native16 = require("react-native");
var import_jsx_runtime15 = require("react/jsx-runtime");
var AuthorizationSubHeaderForProposal = ({
  headerCenterChildren,
  headerCenterCustomStyle,
  noSideButtons
}) => {
  const theme = useTheme();
  const [isShowStoreMenu, setIsShowStoreMenu] = (0, import_react12.useState)(false);
  const styles3 = (0, import_react12.useMemo)(() => {
    return import_react_native16.StyleSheet.create({
      container: {
        minWidth: "100%",
        justifyContent: theme?.media.isMobile ? "space-between" : "center",
        flexDirection: "row",
        alignItems: "center"
      },
      // width: 1/3
      itemContainerOnNonMobile: {
        flex: 1,
        alignItems: "center"
      },
      // visibility: hidden
      itemContainerOnNoSideButtons: {
        opacity: 0,
        height: 0
      },
      fourDotsButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.dark,
        color: theme?.color.secondaryWhite,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      fourDotsButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16
      },
      fourDotsButtonNotMobile: {
        width: 56,
        height: 56,
        borderRadius: 28
      },
      plusIconButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.dark,
        color: theme?.color.secondaryWhite,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      plusIconButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16
      },
      plusIconButtonNotMobile: {
        width: 56,
        height: 56,
        borderRadius: 28
      },
      homeIconImage: {
        width: 20,
        height: 20
      },
      plusIconImage: {
        width: 40,
        height: 40
      },
      walletButton: {
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        backgroundColor: theme?.color.mint,
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      walletButtonMobile: {
        width: 64,
        height: 56,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      walletButtonNotMobile: {
        borderRadius: 60,
        width: 147,
        height: 56
      },
      walletImageContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
      },
      walletImage: {
        width: 29,
        height: 30
      },
      walletButtonText: {
        marginLeft: 8,
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.primaryBlack,
        fontWeight: "bold"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(import_react_native16.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      import_react_native16.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.fourDotsButton,
              theme?.media.isMobile ? styles3.fourDotsButtonMobile : styles3.fourDotsButtonNotMobile
            ],
            onPress: () => {
              if (typeof window !== "undefined") {
                window.location.href = "/";
              }
            },
            children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Image, { source: { uri: "/icon/fi_home.svg" }, style: styles3.homeIconImage })
          }
        )
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_react_native16.View, { style: headerCenterCustomStyle, children: headerCenterChildren }),
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      import_react_native16.View,
      {
        style: [
          !theme?.media.isMobile && styles3.itemContainerOnNonMobile,
          noSideButtons && theme?.media.isMobile && styles3.itemContainerOnNoSideButtons
        ],
        children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
          ButtonWithChildren,
          {
            customStyle: [
              styles3.plusIconButton,
              theme?.media.isMobile ? styles3.plusIconButtonMobile : styles3.fourDotsButtonNotMobile
            ],
            children: isShowStoreMenu ? import_react_native16.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_io53.IoCloseOutline, { color: theme?.color.secondaryWhite, size: 28 }) : "x" : /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Image, { source: { uri: "/icon/plus.svg" }, style: styles3.plusIconImage })
          }
        )
      }
    ),
    theme?.media.isMobile ? /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      AppsSlide,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: ProposalMenuAppData.data
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      AppsModal,
      {
        visible: isShowStoreMenu,
        close: () => isShowStoreMenu && setIsShowStoreMenu(false),
        transparent: true,
        appCards: ProposalMenuAppData.data
      }
    )
  ] });
};

// src/components/common/ConnectWalletModal.tsx
var import_react13 = require("react");
var import_bs = require("react-icons/bs");
var import_react_native17 = require("react-native");
var import_jsx_runtime16 = require("react/jsx-runtime");
var ModalHeader = () => {
  const theme = useTheme();
  const styles3 = (0, import_react13.useMemo)(() => {
    return import_react_native17.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["2xl"] : theme?.fontSize["4xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsxs)(import_jsx_runtime16.Fragment, { children: [
    import_react_native17.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_react_native17.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(import_bs.BsExclamationCircleFill, { color: theme?.color.mint, size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(Text3, { style: styles3.title, children: "CONNECT" })
  ] });
};
var ConnectWalletModal = ({ ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(Modal, { headerChildren: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(ModalHeader, {}), ...props });
};

// src/components/common/ContainerLayout.tsx
var import_react_native18 = require("react-native");
var import_jsx_runtime17 = require("react/jsx-runtime");
var ContainerLayout = ({ children }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(import_react_native18.View, { style: styles2.container, children });
};
var styles2 = import_react_native18.StyleSheet.create({
  container: {
    width: "100%",
    minWidth: "100vw",
    position: "relative"
  }
});

// src/components/common/ExploreDeStoreModal.tsx
var import_react14 = require("react");
var import_bs2 = require("react-icons/bs");
var import_react_native19 = require("react-native");
var import_jsx_runtime18 = require("react/jsx-runtime");
var ModalHeader2 = () => {
  const theme = useTheme();
  const styles3 = (0, import_react14.useMemo)(() => {
    return import_react_native19.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["2xl"] : theme?.fontSize["4xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_jsx_runtime18.Fragment, { children: [
    import_react_native19.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_react_native19.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_bs2.BsExclamationCircleFill, { color: "#BDFDC7", size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(Text3, { style: styles3.title, children: [
      "NO NFT IN YOUR",
      /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("br", {}),
      "WALLET"
    ] })
  ] });
};
var ModalBody2 = ({ onNavigation }) => {
  const theme = useTheme();
  const styles3 = (0, import_react14.useMemo)(() => {
    return import_react_native19.StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 32
      },
      exploreButton: {
        width: "100%",
        maxWidth: 310,
        marginHorizontal: "auto",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme?.radius.full
      },
      buttonText: {
        fontSize: theme?.fontSize.md
      }
    });
  }, [theme?.media.isMobile]);
  const webStyle = {
    width: "100%",
    maxWidth: 310,
    margin: "0 auto",
    padding: theme?.media.isMobile ? "16px 20px" : "16px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme?.radius.full,
    background: theme?.color.mint,
    color: theme?.color.primaryBlack,
    textDecoration: "none"
  };
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_react_native19.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(Link, { href: "/stores", customStyle: styles3.exploreButton, webStyle, onPress: onNavigation, children: "Explore other destores" }) });
};
var ExploreDeStoreModal = ({ onNavigation, ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(Modal, { headerChildren: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(ModalHeader2, {}), bodyChildren: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(ModalBody2, { onNavigation }), ...props });
};

// src/components/common/Header.tsx
var import_react15 = require("react");
var import_io54 = require("react-icons/io5");
var import_react_native20 = require("react-native");
var import_react_router_dom2 = require("react-router-dom");
var import_jsx_runtime19 = require("react/jsx-runtime");
var Header = ({
  imagePath,
  isLoading,
  addressOrEnsName,
  isWagmiConnected,
  handleConnect,
  handleDisConnect
}) => {
  const theme = useTheme();
  const isConnectedPaperState = useAppSelector((state) => state.auth.connectedPaperWallet);
  const shortenAddress = (address) => {
    const prefixLength = 6;
    const suffixLength = 4;
    const prefix2 = address.slice(0, prefixLength);
    const suffix = address.slice(-suffixLength);
    return `${prefix2}...${suffix}`;
  };
  const subText = addressOrEnsName ? shortenAddress(addressOrEnsName) : void 0;
  const renderButton = (0, import_react15.useCallback)(() => {
    const navigate = (0, import_react_router_dom2.useNavigate)();
    const isConnected = isWagmiConnected || isConnectedPaperState;
    const title = isConnected ? "DisConnect Wallet" : "Connect Wallet";
    const onPress = isConnected ? handleDisConnect : handleConnect;
    const styles4 = import_react_native20.StyleSheet.create({
      container: { flexDirection: "row", alignItems: "center", justifyContent: "center" },
      roundedIconContainer: {
        width: 60,
        height: 60,
        borderRadius: 30,
        marginHorizontal: 12,
        alignItems: "center",
        justifyContent: "center"
      }
    });
    const navigateToMyWallet = () => {
      navigate("/mywallet");
    };
    if (isLoading) {
      return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(OutlineButton, { title: "Loading" });
    } else if (isConnected) {
      return /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(import_react_native20.View, { style: styles4.container, children: [
        /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_react_native20.TouchableOpacity, { style: styles4.roundedIconContainer, onPress: navigateToMyWallet, children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_io54.IoWallet, { size: 24, color: theme?.color.mint }) }),
        /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(OutlineButton, { title, onPress, subText })
      ] });
    } else {
      return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(OutlineButton, { title, onPress, subText });
    }
  }, [isWagmiConnected, isConnectedPaperState, isLoading]);
  const styles3 = (0, import_react15.useMemo)(() => {
    return import_react_native20.StyleSheet.create({
      container: {
        width: "100%",
        padding: 20,
        display: theme?.media.isMobile ? "none" : "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottomWidth: 1,
        borderBottomColor: "rgba(255, 255, 255, 0.1)",
        position: "relative",
        zIndex: 2
      },
      image: {
        width: 156,
        height: 30
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(import_react_native20.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(Link, { href: "/", children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(Image, { style: styles3.image, source: { uri: imagePath } }) }),
    renderButton()
  ] });
};

// src/components/common/MobileHeader.tsx
var import_react16 = require("react");
var import_io55 = require("react-icons/io5");
var import_react_native21 = require("react-native");
var import_react_router_dom3 = require("react-router-dom");
var import_jsx_runtime20 = require("react/jsx-runtime");
var MobileHeader = ({
  logoPath,
  isLoading,
  currentPath,
  isWagmiConnected,
  handleBackHome,
  handleBackNavigate,
  handleConnect,
  handleDisConnect
}) => {
  const theme = useTheme();
  const navigate = (0, import_react_router_dom3.useNavigate)();
  const isConnectedPaper = useAppSelector((state) => state.auth.connectedPaperWallet);
  const styles3 = (0, import_react16.useMemo)(() => {
    return import_react_native21.StyleSheet.create({
      container: {
        width: "100%",
        display: theme?.media.isMobile ? "flex" : "none",
        position: currentPath.split("/")[2] === "nfts" ? "absolute" : void 0,
        paddingHorizontal: theme?.media.isMobile ? 10 : 0,
        top: 24,
        left: 0,
        zIndex: 3
      },
      renderButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      logoContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0
      },
      iconContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        backgroundColor: "transparent"
      },
      connectedContainer: {
        flexDirection: "row"
      },
      connectButton: {
        paddingVertical: 6,
        marginRight: 12,
        paddingHorizontal: 16,
        left: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.secondaryWhite,
        color: theme?.color.primaryBlack
      },
      roundedIconContainer: {
        paddingLeft: "inherit",
        borderRadius: 26,
        marginHorizontal: 4,
        alignItems: "center",
        justifyContent: "center"
      }
    });
  }, [theme?.media.isMobile]);
  const navigateToMyWallet = () => {
    navigate("/mywallet");
  };
  const renderButton = (0, import_react16.useCallback)(() => {
    const isConnected = isWagmiConnected || isConnectedPaper;
    const title = isConnected ? "DisConnect Wallet" : "Connect Wallet";
    const onPress = isConnected ? handleDisConnect : handleConnect;
    if (isLoading) {
      return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Button, { customStyle: styles3.connectButton, title: "Loading" });
    } else if (isConnected) {
      return /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(import_react_native21.View, { style: styles3.connectedContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_react_native21.TouchableOpacity, { style: styles3.roundedIconContainer, onPress: navigateToMyWallet, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_io55.IoWallet, { size: 24, color: theme?.color.mint }) }),
        /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Button, { customStyle: styles3.connectButton, title, onPress })
      ] });
    } else {
      return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Button, { customStyle: styles3.connectButton, title, onPress });
    }
  }, [isWagmiConnected, isConnectedPaper, isLoading, navigate, theme]);
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_react_native21.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(import_react_native21.View, { style: styles3.renderButton, children: [
    currentPath === "/stores" ? /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(ButtonWithChildren, { customStyle: styles3.logoContainer, onPress: handleBackHome, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Image, { source: { uri: logoPath, width: 36, height: 36 } }) }) : /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(ButtonWithChildren, { customStyle: styles3.iconContainer, onPress: handleBackNavigate, children: import_react_native21.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_io55.IoChevronBack, { size: 16, color: "#ffffff" }) : null }),
    renderButton()
  ] }) });
};

// src/components/common/TabButtonGroupSpacer.tsx
var import_react17 = require("react");
var import_react_native22 = require("react-native");
var import_jsx_runtime21 = require("react/jsx-runtime");
var TabGroupSpacer = () => {
  const theme = useTheme();
  const styles3 = (0, import_react17.useMemo)(() => {
    return import_react_native22.StyleSheet.create({
      spacer: {
        width: "100%",
        height: theme?.media.isMobile ? (
          // 64px (live-tab-group.tsx) + 32px (overlay-tabs.tsx)
          96
        ) : (
          // 56px (live-tab-group.tsx) + 48px (overlay-tabs.tsx)
          104
        )
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_react_native22.View, { style: styles3.spacer });
};

// src/components/common/TabOverlay.tsx
var import_react20 = require("react");
var import_react_native25 = require("react-native");

// src/components/common/TabButtonGroup.tsx
var import_react19 = require("react");
var import_react_native24 = require("react-native");

// src/components/common/TabButtonWithLabel.tsx
var import_react18 = require("react");
var import_react_native23 = require("react-native");
var import_jsx_runtime22 = require("react/jsx-runtime");
var TabButtonWithLabel = ({
  active,
  activeImagePath,
  inactiveImagePath,
  name,
  marginHorizontal,
  onPress
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react18.useMemo)(() => {
    return import_react_native23.StyleSheet.create({
      image: {
        width: 32,
        height: 32,
        marginRight: theme?.media.isMobile ? 0 : 8
      },
      text: {
        color: active ? theme?.color.secondaryWhite : theme?.color.secondaryGray,
        fontSize: 14,
        lineHeight: 18
      },
      button: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: theme?.color.primaryBlack,
        flexGrow: 0,
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginHorizontal: marginHorizontal ?? 0,
        borderRadius: theme?.radius.none
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(ButtonWithChildren, { customStyle: styles3.button, disabled: active, onPress, children: [
    /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(Image, { source: { uri: active ? activeImagePath : inactiveImagePath }, style: styles3.image }),
    !theme?.media.isMobile && /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(Text3, { style: styles3.text, children: name })
  ] });
};

// src/components/common/TabButtonGroup.tsx
var import_jsx_runtime23 = require("react/jsx-runtime");
var TabButtonGroup = ({
  isActiveDashboard,
  isActiveLive,
  isActiveSettings,
  navigateToDashboard,
  navigateToLive,
  navigateToSettings
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react19.useMemo)(() => {
    return import_react_native24.StyleSheet.create({
      container: {
        borderRadius: theme?.media.isMobile ? 30 : 70,
        backgroundColor: theme?.color.dark,
        width: theme?.media.isMobile ? 256 : 439,
        height: theme?.media.isMobile ? 64 : 56,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsxs)(import_react_native24.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      TabButtonWithLabel,
      {
        active: isActiveDashboard,
        activeImagePath: "/icon/dashboard_active.svg",
        inactiveImagePath: "/icon/dashboard_inactive.svg",
        name: "Dashboard",
        onPress: navigateToDashboard
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      TabButtonWithLabel,
      {
        active: isActiveLive,
        activeImagePath: "/icon/live_active.svg",
        inactiveImagePath: "/icon/live_inactive.svg",
        name: "Live",
        marginHorizontal: 40,
        onPress: navigateToLive
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      TabButtonWithLabel,
      {
        active: isActiveSettings,
        activeImagePath: "/icon/settings_active.svg",
        inactiveImagePath: "/icon/settings_inactive.svg",
        name: "Settings",
        onPress: navigateToSettings
      }
    )
  ] });
};

// src/components/common/TabOverlay.tsx
var import_jsx_runtime24 = require("react/jsx-runtime");
var TabOverlay = ({ children, tabButtonGroupProps }) => {
  const theme = useTheme();
  const containerStylePosition = children ? {
    top: 0,
    height: "100%"
  } : {
    bottom: 0
  };
  const styles3 = (0, import_react20.useMemo)(() => {
    return import_react_native25.StyleSheet.create({
      container: {
        position: "absolute",
        left: 0,
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 1,
        ...containerStylePosition
      },
      top: {
        flexGrow: children ? 1 : 0
      },
      bottom: {
        flexGrow: 0,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme?.media.isMobile ? 32 : 48
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)(import_react_native25.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_react_native25.View, { style: styles3.top, children }),
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_react_native25.View, { style: styles3.bottom, children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(TabButtonGroup, { ...tabButtonGroupProps }) })
  ] });
};

// src/components/common/WalletConfirmModal.tsx
var import_react21 = require("react");
var import_bs3 = require("react-icons/bs");
var import_ri = require("react-icons/ri");
var import_react_native26 = require("react-native");
var import_jsx_runtime25 = require("react/jsx-runtime");
var ModalHeader3 = () => {
  const theme = useTheme();
  const styles3 = (0, import_react21.useMemo)(() => {
    return import_react_native26.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["2xl"] : theme?.fontSize["4xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_jsx_runtime25.Fragment, { children: [
    import_react_native26.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_react_native26.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_bs3.BsExclamationCircleFill, { color: "#BDFDC7", size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Text3, { style: styles3.title, children: "HAVE YOUR WALLET ALREADY?" })
  ] });
};
var ModalBody3 = ({ handleCreateWallet }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const styles3 = (0, import_react21.useMemo)(() => {
    return import_react_native26.StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 32
      },
      connectButton: {
        width: "100%",
        maxWidth: 310,
        marginHorizontal: "auto",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme?.radius.full
      },
      connectButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10
      },
      buttonText: {
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      createButtonContainer: {
        marginTop: 40
      },
      createButtonText: {
        textAlign: "center"
      },
      createButton: {
        marginTop: 16,
        borderWidth: 2,
        color: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_react_native26.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.connectButton,
        buttonColor: theme?.color.mint,
        onPress: () => dispatch(showConnectModal()),
        children: /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_react_native26.View, { style: styles3.connectButtonContainer, children: [
          import_react_native26.Platform.OS === "web" && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_ri.RiWalletLine, { size: 24 }),
          /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Text3, { style: styles3.buttonText, children: "Connect Wallet" })
        ] })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)(import_react_native26.View, { style: styles3.createButtonContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(Text3, { style: styles3.createButtonText, children: "No Wallet yet?" }),
      /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
        OutlineButton,
        {
          customStyle: styles3.createButton,
          title: "Create your wallet with email",
          onPress: handleCreateWallet
        }
      )
    ] })
  ] });
};
var WalletConfirmModal = ({ ...props }) => {
  const { handleCreateWallet } = props;
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    Modal,
    {
      headerChildren: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(ModalHeader3, {}),
      bodyChildren: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(ModalBody3, { handleCreateWallet }),
      ...props
    }
  );
};

// src/components/common/NetworkDifferentModal.tsx
var import_react22 = require("react");
var import_bi = require("react-icons/bi");
var import_bs4 = require("react-icons/bs");
var import_react_native27 = require("react-native");
var import_jsx_runtime26 = require("react/jsx-runtime");
var ModalHeader4 = () => {
  const theme = useTheme();
  const styles3 = (0, import_react22.useMemo)(() => {
    return import_react_native27.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["xl"] : theme?.fontSize["2xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(import_jsx_runtime26.Fragment, { children: [
    import_react_native27.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_react_native27.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_bs4.BsExclamationCircleFill, { color: "#BDFDC7", size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(Text3, { style: styles3.title, children: "You have to change correct network" })
  ] });
};
var ModalBody4 = ({
  handleConnectCorrectNetwork
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react22.useMemo)(() => {
    return import_react_native27.StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 32
      },
      connectButton: {
        width: "100%",
        maxWidth: 310,
        marginHorizontal: "auto",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme?.radius.full
      },
      connectButtonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10
      },
      buttonText: {
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      createButtonContainer: {
        marginTop: 40
      },
      createButtonText: {
        textAlign: "center"
      },
      createButton: {
        marginTop: 16,
        borderWidth: 2,
        color: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_react_native27.View, { style: styles3.container, children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    ButtonWithChildren,
    {
      customStyle: styles3.connectButton,
      buttonColor: theme?.color.mint,
      onPress: handleConnectCorrectNetwork,
      children: /* @__PURE__ */ (0, import_jsx_runtime26.jsxs)(import_react_native27.View, { style: styles3.connectButtonContainer, children: [
        import_react_native27.Platform.OS === "web" && /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_bi.BiSolidNetworkChart, { size: 24 }),
        /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(Text3, { style: styles3.buttonText, children: "Change Network" })
      ] })
    }
  ) });
};
var NetworkDifferentModal = ({ ...props }) => {
  const { handleConnectCorrectNetwork } = props;
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    Modal,
    {
      headerChildren: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(ModalHeader4, {}),
      bodyChildren: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(ModalBody4, { handleConnectCorrectNetwork }),
      ...props
    }
  );
};

// src/components/pages/dashboard/DashboardIndicators.tsx
var import_react23 = require("react");
var import_react_native28 = require("react-native");
var import_jsx_runtime27 = require("react/jsx-runtime");
var DashboardIndicators = ({
  treasuryBalance,
  storeBalance,
  expense,
  grossSales,
  members,
  profit
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react23.useMemo)(() => {
    return import_react_native28.StyleSheet.create({
      container: {
        marginTop: theme?.media.isMobile ? 24 : 32
      },
      indicator: {
        backgroundColor: theme?.color.secondaryLight,
        borderRadius: 10,
        flex: 1,
        height: 118,
        padding: 16
      },
      iconContainer: {
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24
      },
      icon: {
        width: 24,
        height: 24
      },
      title: {
        fontSize: 12,
        lineHeight: 16,
        marginTop: 8,
        marginBottom: 4
      },
      value: {
        fontSize: 26,
        lineHeight: 34
      },
      separator: {
        height: 8
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
    import_react_native28.FlatList,
    {
      style: styles3.container,
      numColumns: theme?.media.isMobile ? 2 : 3,
      data: [
        {
          iconPath: "/icon/wallet_outline.svg",
          title: "Treasury balance",
          value: treasuryBalance,
          iconStyle: {
            width: 19,
            height: 18
          }
        },
        {
          iconPath: "/icon/package_variant_closed.svg",
          title: "Store balance",
          value: storeBalance,
          iconStyle: {
            width: 19,
            height: 20
          }
        },
        {
          iconPath: "/icon/trending_down.svg",
          title: "Expense",
          value: expense,
          iconStyle: {
            width: 18,
            height: 12
          }
        },
        {
          iconPath: "/icon/cart_outline.svg",
          title: "Gross sales",
          value: grossSales,
          iconStyle: {
            width: 21,
            height: 20
          }
        },
        {
          iconPath: "/icon/user.svg",
          title: "Members",
          value: members,
          iconStyle: {
            width: 16,
            height: 16
          }
        },
        {
          iconPath: "/icon/fire.svg",
          title: "Profit",
          value: profit,
          iconStyle: {
            width: 15,
            height: 18
          }
        }
      ],
      renderItem: ({ item: { iconPath, title, value, iconStyle }, index: index2 }) => /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
        import_react_native28.View,
        {
          style: [
            styles3.indicator,
            theme?.media.isMobile ? index2 % 2 === 0 && { marginRight: 8 } : index2 % 3 !== 2 && { marginRight: 8 }
          ],
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native28.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(Image, { source: { uri: iconPath }, style: iconStyle }) }),
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(Text3, { style: styles3.title, children: title }),
            /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(Text3, { style: styles3.value, children: value })
          ]
        }
      ),
      ItemSeparatorComponent: () => /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_react_native28.View, { style: styles3.separator })
    },
    theme?.media.isMobile ? 2 : 3
  );
};

// src/components/pages/dashboard/DashboardTabView.tsx
var import_react27 = require("react");
var import_react_native32 = require("react-native");
var import_react_native_tab_view = require("react-native-tab-view");

// src/components/pages/dashboard/DashboardTab.tsx
var import_randomcolor = __toESM(require("randomcolor"));
var import_react26 = require("react");
var import_react_native31 = require("react-native");

// src/components/common/Divider.tsx
var import_react24 = require("react");
var import_react_native29 = require("react-native");
var import_jsx_runtime28 = require("react/jsx-runtime");
var Divider = ({ color, customStyle }) => {
  const theme = useTheme();
  const styles3 = (0, import_react24.useMemo)(() => {
    return import_react_native29.StyleSheet.create({
      divider: {
        width: "100%",
        height: 1,
        backgroundColor: color,
        ...customStyle
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_react_native29.View, { style: styles3.divider });
};

// src/components/pages/dashboard/DashboardLegendTable.tsx
var import_react25 = require("react");
var import_react_native30 = require("react-native");
var import_jsx_runtime29 = require("react/jsx-runtime");
var DashboardLegendTable = ({ items: items2 }) => {
  const theme = useTheme();
  const styles3 = (0, import_react25.useMemo)(() => {
    return import_react_native30.StyleSheet.create({
      table: {
        flex: 1
      },
      row: {
        flexDirection: "row",
        alignItems: "center"
      },
      symbolColumn: {
        marginRight: 16
      },
      symbol: {
        width: 10,
        height: 6,
        borderRadius: 3
      },
      brand: {
        flex: 2
      },
      brandText: {
        fontSize: 16,
        lineHeight: 20,
        color: theme?.color.primaryBlack
      },
      value: {
        flex: 1,
        textAlign: "right"
      },
      valueText: {
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.primaryBlack
      },
      percentage: {
        textAlign: "right",
        width: 40,
        marginLeft: 16
      },
      percentageText: {
        fontSize: 14,
        lineHeight: 18,
        color: theme?.color.secondaryGray
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.table, children: items2?.map((row, i) => /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_react_native30.View, { style: [styles3.row, { marginBottom: items2.length - 1 === i ? 0 : 8 }], children: [
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.symbolColumn, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: [styles3.symbol, { backgroundColor: row.color }] }) }),
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.brand, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Text3, { style: styles3.brandText, children: "-" }) }),
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.value, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Text3, { style: styles3.valueText, children: "$-" }) }),
    typeof row.percentage === "number" && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_react_native30.View, { style: styles3.percentage, children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(Text3, { style: styles3.percentageText, children: "-%" }) })
  ] }, row.name)) });
};

// src/components/pages/dashboard/DashboardTab.tsx
var import_jsx_runtime30 = require("react/jsx-runtime");
var DashboardTab = ({
  route: {
    brandSalesRankingData,
    salesAndExpenseList,
    dashboardIndicators,
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryPie,
    VictoryStack
  }
}) => {
  const dataTotal = brandSalesRankingData?.reduce((sum, bsr) => sum + bsr.sales, 0) ?? 0;
  const brandSalesRankingColorScale = (0, import_randomcolor.default)({ count: brandSalesRankingData.length, luminosity: "light" });
  const salesTotal = salesAndExpenseList?.reduce((sum, se) => sum + se.sales, 0) ?? 0;
  const expenseTotal = salesAndExpenseList?.reduce((sum, se) => sum + se.expense, 0) ?? 0;
  const salesAndExpenseColorScale = (0, import_randomcolor.default)({ count: 2, luminosity: "light" });
  const theme = useTheme();
  const getAbbreviatedNumber = (0, import_react26.useCallback)(
    (num) => Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1
    }).format(num),
    []
  );
  const getPlusMinus = (0, import_react26.useCallback)((salesTotal2, expenseTotal2) => {
    const result = salesTotal2 - expenseTotal2;
    if (result === 0) {
      return "";
    } else if (result > 0) {
      return "+";
    } else {
      return "-";
    }
  }, []);
  const styles3 = (0, import_react26.useMemo)(() => {
    return import_react_native31.StyleSheet.create({
      brandSalesRankingContainer: {
        width: "100%",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 8,
        paddingVertical: 16,
        marginTop: theme?.media.isMobile ? 16 : 32
      },
      brandSalesRankingSummaryContainer: {
        width: theme?.media.isMobile ? "100%" : "50%",
        marginBottom: theme?.media.isMobile ? 24 : 0,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 24
      },
      brandSalesRankingSummaryPieContainer: {
        width: 109,
        height: 109
      },
      brandSalesRankingSummaryTotalContainer: {
        flexDirection: "column",
        gap: 4
      },
      brandSalesRankingSummaryTotalTitle: {
        fontSize: 12,
        lineHeight: 16,
        color: theme?.color.primaryBlack
      },
      brandSalesRankingSummaryTotal: {
        fontSize: 26,
        lineHeight: 34,
        color: theme?.color.primaryBlack
      },
      brandSalesRankingLegendContainer: {
        width: theme?.media.isMobile ? "100%" : "50%"
      },
      centerDivider: {
        marginVertical: theme?.media.isMobile ? 16 : 32
      },
      salesAndExpenseContainer: {
        width: "100%",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center"
      },
      salesAndExpenseGraphContainer: {
        width: theme?.media.isMobile ? "100%" : "50%"
      },
      salesAndExpenseLegendContainer: {
        width: theme?.media.isMobile ? "100%" : "50%"
      },
      salesAndExpenseTitle: {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 18,
        textAlign: "center",
        marginTop: theme?.media.isMobile ? 16 : 18
      },
      salesAndExpenseDivider: {
        marginVertical: 16
      },
      salesAndExpenseTotalContainer: {
        flexDirection: "row",
        justifyContent: "space-between"
      },
      salesAndExpenseTotalTitle: {
        fontSize: 16,
        lineHeight: 20
      },
      salesAndExpenseTotalValue: {
        fontSize: 26,
        lineHeight: 34
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_jsx_runtime30.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(DashboardIndicators, { ...dashboardIndicators }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.brandSalesRankingContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.brandSalesRankingSummaryContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_react_native31.View, { style: styles3.brandSalesRankingSummaryPieContainer, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
          VictoryPie,
          {
            colorScale: brandSalesRankingColorScale,
            data: brandSalesRankingData?.map((bsr) => ({ x: bsr.brandName, y: bsr.sales })),
            padAngle: 1,
            cornerRadius: 15,
            padding: 0,
            innerRadius: 130,
            style: { labels: { display: "none" } }
          }
        ) }),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.brandSalesRankingSummaryTotalContainer, children: [
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.brandSalesRankingSummaryTotalTitle, children: "Brand Sales Ranking" }),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.brandSalesRankingSummaryTotal, children: "$-" })
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_react_native31.View, { style: styles3.brandSalesRankingLegendContainer, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
        DashboardLegendTable,
        {
          items: brandSalesRankingData.map((bsr, i) => ({
            color: brandSalesRankingColorScale[i],
            name: bsr.brandName,
            value: bsr.sales,
            percentage: Math.round(bsr.sales / dataTotal * 100)
          }))
        }
      ) })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Divider, { color: theme?.color.secondaryLightGreen, customStyle: styles3.centerDivider }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseGraphContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.salesAndExpenseTitle, children: "Sales and expense" }),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(VictoryChart, { domainPadding: { x: [20, 0], y: [0, 0] }, children: [
          /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(VictoryStack, { colorScale: salesAndExpenseColorScale, children: [
            /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(VictoryBar, { data: salesAndExpenseList?.map((se) => ({ x: se.month, y: se.expense })) }),
            /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(VictoryBar, { data: salesAndExpenseList?.map((se) => ({ x: se.month, y: se.sales })) })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            VictoryAxis,
            {
              tickFormat: salesAndExpenseList?.map((se) => se.month),
              style: {
                axis: {
                  stroke: "transparent"
                }
              }
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            VictoryAxis,
            {
              dependentAxis: true,
              style: {
                axis: {
                  stroke: "transparent"
                }
              },
              tickFormat: (t) => `$${getAbbreviatedNumber(t)}`
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseLegendContainer, children: [
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
          DashboardLegendTable,
          {
            items: [
              {
                color: salesAndExpenseColorScale[1],
                name: "Sales",
                value: salesTotal
              },
              {
                color: salesAndExpenseColorScale[0],
                name: "Expense",
                value: expenseTotal
              }
            ]
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Divider, { color: theme?.color.secondaryLight, customStyle: styles3.salesAndExpenseDivider }),
        /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_react_native31.View, { style: styles3.salesAndExpenseTotalContainer, children: [
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.salesAndExpenseTotalTitle, children: "Total:" }),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Text3, { style: styles3.salesAndExpenseTotalValue, children: "+ $-" })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(TabGroupSpacer, {})
  ] });
};

// src/components/pages/dashboard/DashboardTabView.tsx
var import_jsx_runtime31 = require("react/jsx-runtime");
var DashboardTabview = (props) => {
  const theme = useTheme();
  const [navigationState, setNavigationState] = (0, import_react27.useState)({
    index: 0,
    routes: [
      { key: "daily", title: "Daily", ...props },
      { key: "weekly", title: "Weekly", ...props },
      { key: "monthly", title: "Monthly", ...props },
      { key: "yearly", title: "Yearly", ...props }
    ]
  });
  const styles3 = (0, import_react27.useMemo)(() => {
    return import_react_native32.StyleSheet.create({
      tabTitle: {
        fontSize: 14,
        lineHeight: 18
      }
    });
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
    import_react_native_tab_view.TabView,
    {
      initialLayout: { width: import_react_native32.Dimensions.get("window").width },
      navigationState,
      renderScene: (0, import_react_native_tab_view.SceneMap)({
        daily: DashboardTab,
        weekly: DashboardTab,
        monthly: DashboardTab,
        yearly: DashboardTab
      }),
      renderTabBar: (props2) => /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
        import_react_native_tab_view.TabBar,
        {
          ...props2,
          indicatorStyle: { backgroundColor: theme?.color.primaryBlack },
          style: { backgroundColor: theme?.color.secondaryWhite },
          activeColor: theme?.color.primaryBlack,
          inactiveColor: theme?.color.secondaryGray,
          renderLabel: ({ color, route }) => /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(Text3, { style: [styles3.tabTitle, { color }], children: route.title })
        }
      ),
      onIndexChange: (index2) => setNavigationState((prev) => ({ ...prev, index: index2 }))
    }
  );
};

// src/components/pages/home/HomeCta.tsx
var import_react28 = require("react");
var import_react_native33 = require("react-native");
var import_jsx_runtime32 = require("react/jsx-runtime");
var HomeCta = ({ logoImagePath, keyImagePath }) => {
  const theme = useTheme();
  const styles3 = (0, import_react28.useMemo)(() => {
    return import_react_native33.StyleSheet.create({
      container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: theme?.media.isMobile ? "flex-end" : "center",
        justifyContent: "space-between"
      },
      baseText: {
        display: theme?.media.isMobile ? "none" : "flex",
        flexDirection: "column",
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["6xl"],
        fontWeight: "bold",
        lineHeight: 60
      },
      mobileBaseText: {
        display: theme?.media.isMobile ? "flex" : "none",
        flexDirection: "column",
        marginTop: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["6xl"],
        fontWeight: "bold"
      },
      textInner: {
        display: "flex"
      },
      textStrong: {
        marginLeft: 16,
        color: theme?.color.mint
      },
      logoImage: {
        width: 64,
        height: 64
      },
      keyImage: {
        width: 200,
        height: 200
      },
      introduction: {
        marginTop: theme?.media.isMobile ? 16 : 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(import_react_native33.View, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(import_react_native33.View, { style: styles3.container, children: [
      /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(import_react_native33.View, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Image, { style: styles3.logoImage, source: { uri: logoImagePath } }),
        /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.baseText, children: [
          "NEW RETAIL",
          /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.textInner, children: [
            "IS",
            /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Text3, { style: styles3.textStrong, children: "HERE." })
          ] })
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Image, { style: styles3.keyImage, source: { uri: keyImagePath } })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.mobileBaseText, children: [
      "NEW RETAIL",
      /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(Text3, { style: styles3.textInner, children: [
        "IS",
        /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Text3, { style: styles3.textStrong, children: "HERE." })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Text3, { style: styles3.introduction, children: "The platform that you become part of your favorite store." })
  ] });
};

// src/components/pages/home/HomeEnterDeStoreSection.tsx
var import_react29 = require("react");
var import_fi2 = require("react-icons/fi");
var import_react_native34 = require("react-native");
var import_jsx_runtime33 = require("react/jsx-runtime");
var HomeEnterDeStoreSection = ({ handleOpenClick }) => {
  const theme = useTheme();
  const styles3 = (0, import_react29.useMemo)(() => {
    return import_react_native34.StyleSheet.create({
      container: {
        width: "full",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        justifyContent: "flex-start",
        borderRadius: 16
      },
      inner: {
        width: "full",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)",
        textAlign: "left"
      },
      title: {
        fontSize: theme?.media.isMobile ? theme.fontSize.xl : theme?.fontSize["2xl"],
        fontWeight: "bold"
      },
      subText: {
        marginTop: 8,
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.md
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(ButtonWithChildren, { customStyle: styles3.container, buttonColor: theme?.color.mint, onPress: handleOpenClick, children: /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_react_native34.View, { style: styles3.inner, children: [
    /* @__PURE__ */ (0, import_jsx_runtime33.jsxs)(import_react_native34.View, { style: styles3.textContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(Text3, { style: styles3.title, children: "ENTER YOUR COMMUNITY" }),
      /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(Text3, { style: styles3.subText, children: "Jump in the store if you have already got a membership" })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_react_native34.View, { style: styles3.iconContainer, children: import_react_native34.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(import_fi2.FiArrowUpRight, { color: "#BDFDC7", size: 22 }) : null })
  ] }) });
};

// src/components/pages/home/HomeExploreDeStoreSection.tsx
var import_react30 = require("react");
var import_fi3 = require("react-icons/fi");
var import_react_native35 = require("react-native");
var import_jsx_runtime34 = require("react/jsx-runtime");
var HomeExploreDeStoreSection = ({ onNavigation }) => {
  const theme = useTheme();
  const styles3 = (0, import_react30.useMemo)(() => {
    return import_react_native35.StyleSheet.create({
      container: {
        width: "full",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        display: "flex",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme?.color.mint,
        borderRadius: 16
      },
      inner: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)",
        textAlign: "left"
      },
      title: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.media.isMobile ? theme.fontSize.xl : theme?.fontSize["2xl"],
        fontWeight: "bold"
      },
      subText: {
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.md
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      }
    });
  }, [theme?.media.isMobile]);
  const webStyle = {
    width: "full",
    padding: theme?.media.isMobile ? "20px 20px" : "32px 40px",
    display: "flex",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme?.color.mint,
    borderRadius: 16,
    textDecoration: "none"
  };
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(Link, { href: "/stores", customStyle: styles3.container, webStyle, onPress: onNavigation, children: /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_react_native35.View, { style: styles3.inner, children: [
    /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(import_react_native35.View, { style: styles3.textContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(Text3, { style: styles3.title, children: "EXPLORE OTHER COMMUNITIES" }),
      /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(Text3, { style: styles3.subText, children: "Check in all communities that are available on the network" })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_react_native35.View, { style: styles3.iconContainer, children: import_react_native35.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(import_fi3.FiArrowUpRight, { color: "#BDFDC7", size: 22 }) : null })
  ] }) });
};

// src/components/pages/live/LiveHeaderCenterChildren.tsx
var import_react31 = require("react");
var import_react_native36 = require("react-native");
var import_jsx_runtime35 = require("react/jsx-runtime");
var LiveHeaderCenterChildren = ({ logoImagePath, address, addressColor }) => {
  const theme = useTheme();
  const styles3 = (0, import_react31.useMemo)(() => {
    return import_react_native36.StyleSheet.create({
      storeLogo: {
        height: 30,
        width: "100%",
        marginBottom: theme?.media.isMobile ? 8 : 15
      },
      storeAddress: {
        fontSize: theme?.media.isMobile ? 12 : 14,
        lineHeight: theme?.media.isMobile ? 14 : 18,
        color: addressColor ?? theme?.color.secondaryWhite
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime35.jsxs)(import_jsx_runtime35.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
      Image,
      {
        source: {
          uri: logoImagePath
        },
        style: styles3.storeLogo,
        resizeMode: "contain"
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(Text3, { style: styles3.storeAddress, children: address })
  ] });
};

// src/components/pages/live/LiveLineItems.tsx
var import_react33 = require("react");
var import_react_native38 = require("react-native");

// src/components/pages/live/LiveLineItem.tsx
var import_react32 = require("react");
var import_react_native37 = require("react-native");
var import_jsx_runtime36 = require("react/jsx-runtime");
var LiveLineItem = ({ lineItem, isNextElementToBeHidden }) => {
  const theme = useTheme();
  const styles3 = (0, import_react32.useMemo)(() => {
    return import_react_native37.StyleSheet.create({
      container: {
        width: theme?.media.isMobile ? LineItemCardSizes.mobile.width : LineItemCardSizes.desktop.width,
        height: theme?.media.isMobile ? LineItemCardSizes.mobile.height : LineItemCardSizes.desktop.height,
        justifyContent: "space-between",
        // secondaryWhite alpha 20%
        backgroundColor: theme?.color.black + "33",
        borderColor: theme?.color.secondaryGray,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        padding: 8,
        opacity: isNextElementToBeHidden ? 0.4 : 1
      },
      product: {
        fontSize: theme?.media.isMobile ? 14 : 18,
        lineHeight: theme?.media.isMobile ? 18 : 24,
        fontWeight: "bold",
        color: theme?.color.secondaryWhite,
        marginBottom: 2
      },
      brand: {
        fontSize: theme?.media.isMobile ? 12 : 14,
        lineHeight: theme?.media.isMobile ? 16 : 18,
        color: theme?.color.secondaryWhite
      },
      amountContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row"
      },
      amount: {
        fontSize: theme?.media.isMobile ? 12 : 18,
        lineHeight: theme?.media.isMobile ? 16 : 24,
        color: theme?.color.mint
      },
      dateTime: {
        fontSize: theme?.media.isMobile ? 12 : 14,
        lineHeight: theme?.media.isMobile ? 16 : 18,
        color: theme?.color.secondaryWhite,
        opacity: 0.5
      }
    });
  }, [theme?.media.isMobile, isNextElementToBeHidden]);
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(import_react_native37.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(import_react_native37.View, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(Text3, { style: styles3.product, numberOfLines: 1, children: [
        lineItem.name,
        lineItem.quantity > 1 && ` (x${lineItem.quantity})`
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(Text3, { style: styles3.brand, numberOfLines: 1, children: lineItem.vendor })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(import_react_native37.View, { style: styles3.amountContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime36.jsxs)(Text3, { style: styles3.amount, numberOfLines: 1, children: [
        "+$",
        lineItem.price.toFixed(2)
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(Text3, { style: styles3.dateTime, numberOfLines: 1, children: lineItem.updatedAt })
    ] })
  ] });
};

// src/components/pages/live/LiveLineItems.tsx
var import_jsx_runtime37 = require("react/jsx-runtime");
var LiveLineItems = ({ lineItems }) => {
  const theme = useTheme();
  const scrollViewRef = (0, import_react33.useRef)(null);
  const isCloseToBottomRef = (0, import_react33.useRef)(true);
  const numberOfDisplay = theme?.media.isMobile ? 3 : 4;
  const spaceBetweenItems = theme?.media.isMobile ? 8 : 16;
  const lineItemWidth = theme?.media.isMobile ? LineItemCardSizes.mobile.width : LineItemCardSizes.desktop.width;
  const lineItemHeight = theme?.media.isMobile ? LineItemCardSizes.mobile.height : LineItemCardSizes.desktop.height;
  const itemsViewWidth = lineItemWidth;
  const itemsViewHeight = lineItemHeight * numberOfDisplay + spaceBetweenItems * (numberOfDisplay > 1 ? numberOfDisplay - 1 : 0);
  const styles3 = (0, import_react33.useMemo)(() => {
    return import_react_native38.StyleSheet.create({
      scrollContainer: {
        width: itemsViewWidth,
        height: itemsViewHeight
      },
      scrollView: {
        flexDirection: "column",
        gap: spaceBetweenItems
      }
    });
  }, [theme?.media.isMobile]);
  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    return layoutMeasurement.height + contentOffset.y >= contentSize.height;
  };
  return /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(import_react_native38.View, { style: styles3.scrollContainer, children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
    import_react_native38.ScrollView,
    {
      showsVerticalScrollIndicator: false,
      ref: scrollViewRef,
      onContentSizeChange: () => {
        if (isCloseToBottomRef.current && scrollViewRef.current) {
          scrollViewRef.current.scrollToEnd();
        }
      },
      onScroll: ({ nativeEvent }) => {
        if (!isCloseToBottomRef)
          return;
        isCloseToBottomRef.current = isCloseToBottom(nativeEvent);
      },
      scrollEventThrottle: 1,
      children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(import_react_native38.View, { style: styles3.scrollView, children: lineItems?.map((li, i) => /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
        LiveLineItem,
        {
          lineItem: li,
          isNextElementToBeHidden: lineItems.length - numberOfDisplay >= i
        },
        `line-item-${i}`
      )) })
    }
  ) });
};

// src/components/pages/live/LiveSalesInfoItem.tsx
var import_react34 = require("react");
var import_react_native39 = require("react-native");
var import_jsx_runtime38 = require("react/jsx-runtime");
var LiveSalesInfoItem = ({ title, value }) => {
  const theme = useTheme();
  const styles3 = (0, import_react34.useMemo)(() => {
    return import_react_native39.StyleSheet.create({
      title: {
        fontSize: theme?.media.isMobile ? 14 : 16,
        lineHeight: theme?.media.isMobile ? 18 : 20,
        color: theme?.color.secondaryWhite,
        marginBottom: theme?.media.isMobile ? 0 : 8
      },
      value: {
        fontSize: theme?.media.isMobile ? 22 : 26,
        lineHeight: theme?.media.isMobile ? 28 : 34,
        color: theme?.color.mint
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime38.jsxs)(import_react_native39.View, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime38.jsxs)(Text3, { style: styles3.title, numberOfLines: 1, children: [
      title,
      ":"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(Text3, { style: styles3.value, numberOfLines: 1, children: value })
  ] });
};

// src/components/pages/live/LiveSalesInfoItems.tsx
var import_react35 = require("react");
var import_react_native40 = require("react-native");
var import_jsx_runtime39 = require("react/jsx-runtime");
var LiveSalesInfoItems = ({ items: items2 }) => {
  const theme = useTheme();
  const styles3 = (0, import_react35.useMemo)(() => {
    return import_react_native40.StyleSheet.create({
      container: {
        flexDirection: "column",
        gap: theme?.media.isMobile ? 4 : 8,
        textAlign: "right"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(import_react_native40.View, { style: styles3.container, children: items2.map(({ title, value }) => /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(LiveSalesInfoItem, { title, value }, title)) });
};

// src/components/pages/live/index.ts
var LineItemCardSizes = {
  mobile: {
    width: 199,
    height: 76
  },
  desktop: {
    width: 380,
    height: 92
  }
};

// src/components/pages/stores/StoreHeaderTitle.tsx
var import_react36 = require("react");
var import_io56 = require("react-icons/io5");
var import_react_native41 = require("react-native");
var import_jsx_runtime40 = require("react/jsx-runtime");
var StoreHeaderTitle = ({ title, handleBackNavigate }) => {
  const theme = useTheme();
  const styles3 = (0, import_react36.useMemo)(() => {
    return import_react_native41.StyleSheet.create({
      container: {
        width: "100%",
        maxWidth: theme?.media.isMobile ? 340 : 640,
        marginHorizontal: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      icnoContainer: {
        backgroundColor: "transparent"
      },
      title: {
        color: theme?.media.isMobile ? theme.color.secondaryGray : theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.lg
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)(import_react_native41.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(ButtonWithChildren, { style: styles3.icnoContainer, onPress: handleBackNavigate, children: /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_io56.IoChevronBack, { size: 16, color: theme?.color.secondaryWhite }) }),
    /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(Text3, { style: styles3.title, children: title }),
    /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(import_react_native41.View, {})
  ] });
};

// src/components/pages/stores/StoreKeyBaseInfo.tsx
var import_react_native42 = require("react-native");
var import_jsx_runtime41 = require("react/jsx-runtime");
var StoreKeyBaseInfo = ({ currentKey, redirectPaperPage }) => {
  const theme = useTheme();
  const styles3 = import_react_native42.StyleSheet.create({
    name: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.md
    },
    price: {
      marginTop: 8,
      color: theme?.color.mint,
      fontSize: theme?.fontSize["2xl"]
    },
    left: {
      marginTop: 8,
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.sm
    },
    redirectPaperButton: {
      marginTop: 16,
      paddingHorizontal: 40,
      paddingVertical: 16
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_react_native42.View, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(Text3, { style: styles3.name, children: [
      currentKey.name,
      " Key"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(Text3, { style: styles3.price, children: [
      "$ ",
      currentKey.price,
      " / NET"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
      PrimaryButton,
      {
        customStyle: styles3.redirectPaperButton,
        title: "Become an Owner",
        onPress: () => redirectPaperPage(currentKey.paperStoreUrl)
      }
    )
  ] });
};

// src/components/pages/stores/StoreKeyDescriptionItem.tsx
var import_react37 = require("react");
var import_bs5 = require("react-icons/bs");
var import_react_native43 = require("react-native");
var import_jsx_runtime42 = require("react/jsx-runtime");
var StoreKeyDescriptionItem = ({ currentKey }) => {
  const theme = useTheme();
  const styles3 = (0, import_react37.useMemo)(() => {
    return import_react_native43.StyleSheet.create({
      container: {
        padding: 32,
        backgroundColor: theme?.color.secondaryWhite,
        borderRadius: 32
      },
      hero: {
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      detailsContainer: {
        marginTop: 16,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        backgroundColor: theme?.color.secondaryLight
      },
      detailsContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: theme?.fontSize.sm
      },
      addressContainer: {
        width: 120
      },
      address: {
        width: theme?.media.isMobile ? 100 : 160,
        fontWeight: "bold"
      },
      detailText: {
        fontWeight: "bold"
      },
      allowsOwnerHero: {
        marginTop: 32,
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      allowsOwnerContainer: {
        marginTop: 16,
        paddingTop: 16,
        display: "flex",
        flexDirection: "column",
        gap: 16
      },
      allowsOwnerContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      },
      allowOwnerText: {
        marginLeft: 16,
        fontSize: theme?.fontSize.md
      },
      iconContainer: {
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.secondaryLiteGray
      },
      activeIconContainer: {
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.mint
      }
    });
  }, []);
  const {
    address,
    standart,
    blockchain,
    isGovernanceRights,
    isVotingRight,
    isPartnerRight,
    isAccesibleBoard,
    isManager
  } = currentKey;
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.hero, children: "NFT Details:" }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.addressContainer, children: "Contract Address" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.address, numberOfLines: 1, children: address })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { children: "Taken Standart" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.detailText, children: standart })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.detailsContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { children: "Blockchain" }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.detailText, children: blockchain })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowsOwnerHero, children: "This ownership allows you to be an executive owner:" }),
    /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isGovernanceRights ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "3x Governance rights" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isVotingRight ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "Voting on which brand Destore sells" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isPartnerRight ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "The right to accept brands as Destore partner" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isAccesibleBoard ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "Access to an executive board" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime42.jsxs)(import_react_native43.View, { style: styles3.allowsOwnerContent, children: [
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_react_native43.View, { style: isManager ? styles3.activeIconContainer : styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(import_bs5.BsCheck, { size: 24, color: theme?.color.primaryBlack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(Text3, { style: styles3.allowOwnerText, children: "Manage financial resources and inventory" })
      ] })
    ] })
  ] });
};

// src/components/pages/stores/StoreKeyList.tsx
var import_react_native45 = require("react-native");

// src/components/pages/stores/StoreKeyListItem.tsx
var import_react38 = require("react");
var import_react_native44 = require("react-native");
var import_jsx_runtime43 = require("react/jsx-runtime");
var StoreKeyListItem = ({
  index: index2,
  isLoggedIn,
  keyData,
  handleNavigateBuyNFT,
  handleShowConfirmModal
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react38.useMemo)(() => {
    return import_react_native44.StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 150 : "auto",
        maxWidth: 150,
        marginLeft: index2 > 0 ? 10 : 0,
        padding: 16,
        display: "flex",
        flexGrow: theme?.media.isMobile ? 0 : 1,
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        textAlign: "center"
      },
      keyImage: {
        marginHorizontal: "auto"
      },
      name: {
        marginTop: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      },
      price: {
        marginTop: 4,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.xl
      },
      left: {
        marginTop: 4,
        color: theme?.color.secondaryLiteGray,
        fontSize: theme?.fontSize.xs
      },
      selectButton: {
        marginTop: 16,
        paddingHorizontal: 16,
        paddingVertical: 8
      }
    });
  }, [theme?.media.isMobile]);
  const { visual, name, price, tokenId, store: store2 } = keyData;
  return /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(import_react_native44.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(Image, { source: { uri: visual, width: 80, height: 120 }, style: styles3.keyImage }),
    /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(Text3, { style: styles3.name, children: [
      name,
      " Key"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime43.jsxs)(Text3, { style: styles3.price, children: [
      "$ ",
      price
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(
      PrimaryButton,
      {
        customStyle: styles3.selectButton,
        title: "Select",
        onPress: isLoggedIn ? () => handleNavigateBuyNFT(store2.url, tokenId) : handleShowConfirmModal
      }
    )
  ] });
};

// src/components/pages/stores/StoreKeyList.tsx
var import_jsx_runtime44 = require("react/jsx-runtime");
var StoreKeyList = ({ isLoggedIn, keys, handleNavigateBuyNFT, handleShowConfirmModal }) => {
  const theme = useTheme();
  const styles3 = import_react_native45.StyleSheet.create({
    ownershipContainer: {
      marginTop: theme?.media.isMobile ? 32 : 72,
      paddingLeft: theme?.media.isMobile ? 32 : 0
    },
    ownershipText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.md
    },
    keyContainer: {
      marginTop: 16,
      display: "flex",
      flexDirection: "row",
      overflowX: "scroll"
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime44.jsxs)(import_jsx_runtime44.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(Text3, { style: styles3.ownershipText, children: "Membership on sale:" }),
    /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(import_react_native45.View, { style: styles3.keyContainer, children: keys.map((keyData, i) => /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
      StoreKeyListItem,
      {
        index: i,
        isLoggedIn,
        keyData,
        handleNavigateBuyNFT,
        handleShowConfirmModal
      },
      keyData.name
    )) })
  ] });
};

// src/components/pages/stores/StoreListItem.tsx
var import_react39 = require("react");
var import_react_native46 = require("react-native");
var import_jsx_runtime45 = require("react/jsx-runtime");
var StoreListItem = ({ index: index2, store: store2, handleStoreNavigate }) => {
  const theme = useTheme();
  const styles3 = (0, import_react39.useMemo)(() => {
    return import_react_native46.StyleSheet.create({
      container: {
        marginTop: theme?.media.isMobile ? index2 === 0 ? 16 : 48 : index2 === 0 ? 72 : 48
      },
      imageContainer: {
        position: "relative"
      },
      grayBackground: {
        width: "100%",
        height: theme?.media.isMobile ? 164 : 320,
        borderRadius: 40,
        backgroundColor: "rgba(17, 16, 16, 0.6)",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1
      },
      comingText: {
        width: 114,
        paddingHorizontal: 20,
        paddingVertical: 8,
        borderTopEndRadius: theme?.radius.lg,
        backgroundColor: "rgba(255, 255, 255, 0.26)",
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.xs,
        position: "absolute",
        top: theme?.media.isMobile ? 64 : 138,
        left: "calc(50% - 57px)",
        zIndex: 2
      },
      visualImage: {
        width: "100%",
        borderRadius: 40
      },
      descriptionContainer: {
        width: "100%",
        marginTop: 20,
        textAlign: "center"
      },
      name: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["2xl"]
      },
      place: {
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.xs
      },
      buyButton: {
        width: "max-content",
        marginTop: 20,
        marginHorizontal: "auto",
        paddingHorizontal: 40,
        paddingVertical: 12,
        display: "flex",
        borderRadius: theme?.radius.full,
        fontSize: theme?.fontSize.sm,
        textAlign: "center"
      }
    });
  }, [index2, theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_react_native46.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_react_native46.View, { style: styles3.imageContainer, children: [
      !store2.isActive && /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_jsx_runtime45.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(import_react_native46.View, { style: styles3.grayBackground }),
        /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Text3, { style: styles3.comingText, children: "Coming soon" })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Image, { source: { uri: store2.visual, height: theme?.media.isMobile ? 164 : 320 }, style: styles3.visualImage })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime45.jsxs)(import_react_native46.View, { style: styles3.descriptionContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Text3, { style: styles3.name, children: store2.name }),
      /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Text3, { style: styles3.place, children: store2.place }),
      store2.isActive && /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
        PrimaryButton,
        {
          customStyle: styles3.buyButton,
          title: "Go to store",
          onPress: () => handleStoreNavigate(store2.id, store2.name)
        }
      )
    ] })
  ] }, store2.id);
};

// src/components/pages/stores/StoreNFTsBaseInfo.tsx
var import_react40 = require("react");
var import_react_native47 = require("react-native");
var import_jsx_runtime46 = require("react/jsx-runtime");
var StoreNFTsBaseInfo = ({ store: store2 }) => {
  const [isTextOpen, setIsTextOpen] = (0, import_react40.useState)(true);
  const theme = useTheme();
  const targetBreakPoint = theme?.breakpoint.md || 0;
  const baseRowCount = window.innerWidth > targetBreakPoint ? 2 : 4;
  const styles3 = (0, import_react40.useMemo)(() => {
    return import_react_native47.StyleSheet.create({
      storeInfoContainer: {
        width: "100%"
      },
      storeBaseInfo: {
        paddingHorizontal: theme?.media.isMobile ? 32 : 0,
        textAlign: "center"
      },
      name: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["5xl"]
      },
      place: {
        marginTop: 12,
        color: theme?.color.secondaryWhite
      },
      title: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md,
        fontWeight: "bold"
      },
      descriptionBox: {
        marginTop: 40,
        paddingHorizontal: theme?.media.isMobile ? 32 : 0
      },
      contentBox: {
        marginTop: theme?.media.isMobile ? 40 : 24,
        paddingHorizontal: theme?.media.isMobile ? 32 : 0
      },
      content: {
        marginTop: 8,
        color: theme?.color.secondaryWhite
      },
      reason: {
        marginTop: 8,
        color: theme?.color.secondaryWhite
      },
      readMore: {
        marginTop: 8,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.sm,
        fontWeight: "bold",
        textDecorationLine: "underline"
      }
    });
  }, [theme?.media.isMobile]);
  (0, import_react40.useEffect)(() => {
    const target = document.getElementById("reason-content");
    if (target === null)
      return;
    const textHeight = target.clientHeight;
    let lineHeight = getComputedStyle(target).getPropertyValue("line-height");
    lineHeight = lineHeight.replace(/[^-\d.]/g, "");
    if (textHeight > Number(lineHeight) * baseRowCount) {
      setIsTextOpen(false);
    }
  }, [theme]);
  const handlePressReadMore = () => {
    setIsTextOpen(true);
  };
  const { name, place, description, reason } = store2;
  return /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(import_jsx_runtime46.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.storeInfoContainer, children: [
    /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.storeBaseInfo, children: [
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.name, children: name }),
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.place, children: place })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.descriptionBox, children: [
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.title, children: "Description:" }),
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.content, children: description })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime46.jsxs)(import_react_native47.View, { style: styles3.contentBox, children: [
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.title, children: "Meet the owner:" }),
      /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.content, numberOfLines: isTextOpen ? void 0 : baseRowCount, nativeID: "reason-content", children: reason }),
      !isTextOpen && /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(Text3, { style: styles3.readMore, onPress: handlePressReadMore, children: "Read More" })
    ] })
  ] }) });
};

// src/components/pages/stores/StoreNFTsVisualImage.tsx
var import_react41 = require("react");
var import_react_native48 = require("react-native");
var import_jsx_runtime47 = require("react/jsx-runtime");
var StoreNFTsVisualImage = ({ storeVisualImage }) => {
  const theme = useTheme();
  const styles3 = (0, import_react41.useMemo)(() => {
    return import_react_native48.StyleSheet.create({
      imageContainer: {
        width: "100%",
        marginTop: theme?.media.isMobile ? 0 : 48,
        position: "relative"
      },
      storeImage: {
        width: "100%",
        borderRadius: theme?.media.isMobile ? 0 : 40,
        opacity: theme?.media.isMobile ? 0.8 : 1
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(import_react_native48.View, { style: styles3.imageContainer, children: /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(Image, { source: { uri: storeVisualImage, height: theme?.media.isMobile ? 360 : 420 }, style: styles3.storeImage }) });
};

// src/components/pages/stores/DeployedContractItem.tsx
var import_react42 = require("react");
var import_react_native49 = require("react-native");
var import_jsx_runtime48 = require("react/jsx-runtime");
var DeployedContractItem = ({
  index: index2,
  handleNavigateToScanPage,
  contractType,
  address
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react42.useMemo)(() => {
    return import_react_native49.StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 150 : "auto",
        maxWidth: theme?.media.isMobile ? 150 : 180,
        marginLeft: theme?.media.isMobile ? index2 % 2 !== 0 ? 10 : 0 : index2 > 0 ? 10 : 0,
        marginBottom: theme?.media.isMobile ? 10 : 0,
        padding: 16,
        flexGrow: 1,
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        textAlign: "center"
      },
      contractType: {
        marginHorizontal: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      },
      address: {
        marginTop: 4,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.xxs
      },
      selectButton: {
        marginTop: 16,
        paddingVertical: 8,
        borderRadius: 16,
        width: "100%",
        alignItems: "center",
        color: theme?.color.secondaryWhite
      }
    });
  }, [theme?.media.isMobile]);
  const abbreviatedAddress = (0, import_react42.useMemo)(() => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  }, [address]);
  return /* @__PURE__ */ (0, import_jsx_runtime48.jsxs)(import_react_native49.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(Text3, { style: styles3.contractType, numberOfLines: 1, children: contractType }),
    /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(Text3, { style: styles3.address, children: abbreviatedAddress }),
    /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.selectButton,
        onPress: () => handleNavigateToScanPage(address),
        buttonColor: theme?.color.secondaryLight,
        children: /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(Text3, { style: { color: theme?.color.black, fontSize: theme?.fontSize.xxs }, children: "View on PolygonScan" })
      }
    )
  ] });
};

// src/components/pages/stores/DeployedContractsList.tsx
var import_react_native50 = require("react-native");
var import_jsx_runtime49 = require("react/jsx-runtime");
var DeployedContractsList = ({ store: store2, handleNavigateToScanPage }) => {
  const theme = useTheme();
  const styles3 = import_react_native50.StyleSheet.create({
    titleText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.lg
    },
    container: {
      marginTop: 24,
      display: "flex",
      flexDirection: "row",
      flexWrap: theme?.media.isMobile ? "wrap" : "nowrap",
      alignContent: theme?.media.isMobile ? "flex-start" : "stretch",
      width: theme?.media.isMobile ? 360 : "auto",
      overflowX: theme?.media.isMobile ? "hidden" : "scroll"
    }
  });
  const { deployedAddresses } = store2;
  return /* @__PURE__ */ (0, import_jsx_runtime49.jsxs)(import_jsx_runtime49.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(Text3, { style: styles3.titleText, children: "Deployed Contract:" }),
    /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(import_react_native50.View, { style: styles3.container, children: deployedAddresses && Object.entries(deployedAddresses).map(([contractType, value], i) => /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(
      DeployedContractItem,
      {
        index: i,
        contractType,
        address: value,
        handleNavigateToScanPage
      },
      i
    )) })
  ] });
};

// src/components/pages/stores/ActiveEventsList.tsx
var import_react_native52 = require("react-native");

// src/components/pages/stores/ActiveEventItem.tsx
var import_react43 = require("react");
var import_react_native51 = require("react-native");
var import_jsx_runtime50 = require("react/jsx-runtime");
var ActiveEventItem = ({ index: index2, handleNavigateEventPage, event }) => {
  const theme = useTheme();
  const styles3 = (0, import_react43.useMemo)(() => {
    return import_react_native51.StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 150 : "auto",
        maxWidth: theme?.media.isMobile ? 150 : 180,
        marginLeft: theme?.media.isMobile ? index2 % 2 !== 0 ? 10 : 0 : index2 > 0 ? 10 : 0,
        marginBottom: theme?.media.isMobile ? 10 : 0,
        padding: 16,
        flexGrow: 1,
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        textAlign: "center"
      },
      contractType: {
        marginHorizontal: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      },
      address: {
        marginTop: 4,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.xxs
      },
      selectButton: {
        marginTop: 16,
        paddingVertical: 8,
        borderRadius: 16,
        width: "100%",
        alignItems: "center",
        color: theme?.color.secondaryWhite
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)(import_react_native51.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(Text3, { style: styles3.contractType, numberOfLines: 1, children: event.eventName }),
    event.eventDetails.map((eventDetail, detailIndex) => /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(
      ButtonWithChildren,
      {
        customStyle: styles3.selectButton,
        onPress: () => handleNavigateEventPage(eventDetail.url),
        buttonColor: detailIndex === 0 ? theme?.color.mint : theme?.color.secondaryLight,
        children: /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(Text3, { style: { color: theme?.color.black, fontSize: theme?.fontSize.xxs }, children: eventDetail.description })
      },
      detailIndex
    ))
  ] });
};

// src/components/pages/stores/ActiveEventsList.tsx
var import_jsx_runtime51 = require("react/jsx-runtime");
var ActiveEventsList = ({ store: store2, handleNavigateEventPage }) => {
  const theme = useTheme();
  const styles3 = import_react_native52.StyleSheet.create({
    titleText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize.lg
    },
    container: {
      marginTop: 24,
      display: "flex",
      flexDirection: "row",
      flexWrap: theme?.media.isMobile ? "wrap" : "nowrap",
      alignContent: theme?.media.isMobile ? "flex-start" : "stretch",
      width: theme?.media.isMobile ? 360 : "auto",
      overflowX: theme?.media.isMobile ? "hidden" : "scroll"
    }
  });
  const { activeEvent } = store2;
  return /* @__PURE__ */ (0, import_jsx_runtime51.jsxs)(import_jsx_runtime51.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(Text3, { style: styles3.titleText, children: "Active events:" }),
    /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(import_react_native52.View, { style: styles3.container, children: activeEvent && activeEvent.map((event, i) => /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(ActiveEventItem, { index: i, event, handleNavigateEventPage }, i)) })
  ] });
};

// src/components/pages/settings/SettingsHeaderCenterChildren.tsx
var import_react44 = require("react");
var import_react_native53 = require("react-native");
var import_jsx_runtime52 = require("react/jsx-runtime");
var SettingsHeaderCenterChildren = ({ avatarPath, name }) => {
  const theme = useTheme();
  const styles3 = (0, import_react44.useMemo)(() => {
    return import_react_native53.StyleSheet.create({
      avatar: {
        width: 64,
        height: 64,
        borderRadius: 125
      },
      name: {
        fontSize: 22,
        lineHeight: 28,
        marginTop: 16,
        color: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(import_jsx_runtime52.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
      Image,
      {
        source: {
          uri: avatarPath
        },
        style: styles3.avatar,
        resizeMode: "cover"
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Text3, { style: styles3.name, children: name })
  ] });
};

// src/components/pages/settings/SettingsItem.tsx
var import_react45 = require("react");
var import_react_native54 = require("react-native");
var import_jsx_runtime53 = require("react/jsx-runtime");
var SettingsItem = ({ chevronRightIconPath: chevronRightIconPath2, icon, onPress, title }) => {
  const theme = useTheme();
  const styles3 = (0, import_react45.useMemo)(() => {
    return import_react_native54.StyleSheet.create({
      button: {
        width: "100%"
      },
      buttonChildrenContainer: {
        width: "100%",
        height: 76,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: theme?.color.secondaryLight,
        alignItems: "center",
        paddingHorizontal: 16
      },
      horizontalContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: 16
      },
      iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24
      },
      titleContainer: {
        fontSize: 16,
        lineHeight: 20,
        color: theme?.color.primaryBlack
      },
      chevronRightIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 25
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(import_react_native54.Pressable, { style: styles3.button, onPress, children: /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(import_react_native54.View, { style: styles3.buttonChildrenContainer, children: [
    /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(import_react_native54.View, { style: styles3.horizontalContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(import_react_native54.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(Image, { source: { uri: icon.path, height: icon.height, width: icon.width } }) }),
      /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(Text3, { style: styles3.titleContainer, children: title })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(import_react_native54.View, { style: styles3.chevronRightIconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(Image, { source: { uri: chevronRightIconPath2, height: 12, width: 6 } }) })
  ] }) });
};

// src/components/pages/settings/SettingsItems.tsx
var import_react46 = require("react");
var import_react_native55 = require("react-native");
var import_jsx_runtime54 = require("react/jsx-runtime");
var SettingsItems = ({ chevronRightIconPath: chevronRightIconPath2, items: items2, version }) => {
  const theme = useTheme();
  const styles3 = (0, import_react46.useMemo)(() => {
    return import_react_native55.StyleSheet.create({
      verticalContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 16,
        gap: 8,
        width: "100%",
        maxWidth: 820
      },
      versionContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 80
      },
      version: {
        fontSize: 12,
        lineHeight: 16,
        color: theme?.color.secondaryLiteGray
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime54.jsxs)(import_react_native55.View, { style: styles3.verticalContainer, children: [
    items2.map((item) => /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(SettingsItem, { chevronRightIconPath: chevronRightIconPath2, ...item }, item.title)),
    /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(import_react_native55.View, { style: styles3.versionContainer, children: /* @__PURE__ */ (0, import_jsx_runtime54.jsxs)(Text3, { style: styles3.version, children: [
      "DeStore. Version ",
      version
    ] }) })
  ] });
};

// src/components/pages/proposals/ProposalsUserRequirementSection.tsx
var import_react47 = require("react");
var import_fi4 = require("react-icons/fi");
var import_react_native56 = require("react-native");
var import_jsx_runtime55 = require("react/jsx-runtime");
var ProposalsUserRequirementSection = ({
  hasVoted,
  inCorrectVotingPeriod,
  isLoggedIn,
  isLoading
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  let titleText = "";
  let subText = "";
  let containerColor = theme?.color.secondaryLightGreen;
  let IconComponent = import_fi4.FiLoader;
  if (!isLoggedIn) {
    titleText = "Please connect your wallet.";
    subText = "You need to be logged in to vote.";
    containerColor = theme?.color.gray;
    IconComponent = import_fi4.FiAlertCircle;
  } else if (inCorrectVotingPeriod) {
    titleText = "Voting period has already ended.";
    subText = "You can vote for this week\u2019s proposal.";
    containerColor = theme?.color.gray;
    IconComponent = import_fi4.FiAlertCircle;
  } else if (isLoading) {
    titleText = "Loading your wallet info.";
    subText = "Wait for a moment.";
    containerColor = theme?.color.gray;
    IconComponent = import_fi4.FiAlertCircle;
  } else if (isLoggedIn && hasVoted && !inCorrectVotingPeriod) {
    titleText = "Thank you for voting!";
    subText = "You have already voted for this week's proposal.";
    containerColor = theme?.color.pink;
    IconComponent = import_fi4.FiCheckCircle;
  } else if (isLoggedIn && !hasVoted && !inCorrectVotingPeriod) {
    titleText = "Vote now!";
    subText = "You can vote for this week\u2019s proposal.";
    containerColor = theme?.color.secondaryLightGreen;
    IconComponent = import_fi4.FiChevronsDown;
  }
  const styles3 = (0, import_react47.useMemo)(() => {
    return import_react_native56.StyleSheet.create({
      container: {
        width: "100%",
        height: theme?.media.isMobile ? 100 : 150,
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        justifyContent: "flex-start",
        borderRadius: 16,
        borderColor: theme?.color.mint
      },
      inner: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)",
        textAlign: "left"
      },
      title: {
        fontSize: theme?.media.isMobile ? theme.fontSize.lg : theme?.fontSize["xl"],
        fontWeight: "bold"
      },
      subText: {
        marginTop: 8,
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.md
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.dark
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
    ButtonWithChildren,
    {
      customStyle: [styles3.container, { backgroundColor: containerColor }],
      buttonColor: containerColor,
      onPress: !isLoggedIn ? () => dispatch(showConnectModal()) : void 0,
      children: /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(import_react_native56.View, { style: styles3.inner, children: [
        /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(import_react_native56.View, { style: styles3.textContainer, children: [
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(Text3, { style: styles3.title, children: titleText }),
          /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(Text3, { style: styles3.subText, children: subText })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(import_react_native56.View, { style: styles3.iconContainer, children: import_react_native56.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(IconComponent, { color: theme?.color.secondaryWhite, size: 22 }) : null })
      ] })
    }
  );
};

// src/components/pages/proposals/ProposalRequirementSection.tsx
var import_react48 = require("react");
var import_fi5 = require("react-icons/fi");
var import_react_native57 = require("react-native");
var import_jsx_runtime56 = require("react/jsx-runtime");
var ProposalRequirementSection = ({
  startTime,
  endTime,
  voteContractAddress
}) => {
  const theme = useTheme();
  const formatDate = (timestamp2) => {
    const date2 = new Date(timestamp2 * 1e3);
    return date2.toLocaleString();
  };
  const scanUrl = () => {
    if (process.env.REACT_APP_VERCEL_ENV === "production") {
      return `https://polygonscan.com/address/${voteContractAddress}`;
    } else {
      return `https://mumbai.polygonscan.com/address/${voteContractAddress}`;
    }
  };
  const openScanUrl = () => {
    const url = scanUrl();
    import_react_native57.Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        import_react_native57.Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: " + url);
      }
    });
  };
  const styles3 = (0, import_react48.useMemo)(() => {
    return import_react_native57.StyleSheet.create({
      container: {
        width: "100%",
        height: theme?.media.isMobile ? 100 : 150,
        paddingHorizontal: theme?.media.isMobile ? 4 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        marginVertical: theme?.media.isMobile ? 20 : 32,
        marginLeft: theme?.media.isMobile ? 4 : 32,
        justifyContent: "flex-start",
        borderRadius: 16,
        borderColor: theme?.color.mint
      },
      inner: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)"
      },
      title: {
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize["md"],
        fontWeight: "bold",
        textAlign: "left"
      },
      subText: {
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.sm,
        textAlign: "left"
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.dark
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(ButtonWithChildren, { customStyle: styles3.container, buttonColor: theme?.color.secondaryLight, children: /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(import_react_native57.View, { style: styles3.inner, children: [
    /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(import_react_native57.View, { style: styles3.textContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(Text3, { style: styles3.title, children: "Voting periods:" }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsxs)(Text3, { style: styles3.subText, children: [
        formatDate(startTime),
        "~",
        formatDate(endTime)
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(Text3, { style: styles3.title, children: "Voting contract detail:" }),
      /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(Text3, { style: styles3.subText, onPress: openScanUrl, children: voteContractAddress })
    ] }),
    import_react_native57.Platform.OS === "web" && !theme?.media.isMobile ? /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_react_native57.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_react_native57.TouchableOpacity, { onPress: openScanUrl, children: /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(import_fi5.FiArrowUpRight, { color: theme?.color.secondaryWhite, size: 22 }) }) }) : null
  ] }) });
};

// src/components/pages/checkin/CheckIn.tsx
var import_react49 = require("react");
var import_react_native58 = require("react-native");
var import_jsx_runtime57 = require("react/jsx-runtime");
var CheckInCta = ({ logoImagePath, keyImagePath }) => {
  const theme = useTheme();
  const styles3 = (0, import_react49.useMemo)(() => {
    return import_react_native58.StyleSheet.create({
      container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: theme?.media.isMobile ? "flex-end" : "center",
        justifyContent: "space-between"
      },
      baseText: {
        display: theme?.media.isMobile ? "none" : "flex",
        flexDirection: "column",
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["6xl"],
        fontWeight: "bold",
        lineHeight: 60
      },
      mobileBaseText: {
        display: theme?.media.isMobile ? "flex" : "none",
        flexDirection: "column",
        marginTop: 16,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["6xl"],
        fontWeight: "bold"
      },
      textInner: {
        display: "flex"
      },
      textStrong: {
        marginLeft: 16,
        color: theme?.color.mint
      },
      logoImage: {
        width: 64,
        height: 64
      },
      keyImage: {
        width: 200,
        height: 200
      },
      introduction: {
        marginTop: theme?.media.isMobile ? 16 : 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.md
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(import_react_native58.View, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(import_react_native58.View, { style: styles3.container, children: [
      /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(import_react_native58.View, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(Image, { style: styles3.logoImage, source: { uri: logoImagePath } }),
        /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(Text3, { style: styles3.baseText, children: [
          "Get",
          /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(Text3, { style: styles3.textInner, children: [
            "CheckIn",
            /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(Text3, { style: styles3.textStrong, children: "Token" })
          ] })
        ] })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(Image, { style: styles3.keyImage, source: { uri: keyImagePath } })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(Text3, { style: styles3.mobileBaseText, children: [
      "Get",
      /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(Text3, { style: styles3.textInner, children: [
        "CheckIn",
        /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(Text3, { style: styles3.textStrong, children: "Token" })
      ] })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(Text3, { style: styles3.introduction, children: "Use this check-in token for discounts at stores. Sent to your wallet, enhancing your shopping experience." })
  ] });
};

// src/components/pages/checkin/GetTokenReceiveSection.tsx
var import_react50 = require("react");
var import_fi6 = require("react-icons/fi");
var import_react_native59 = require("react-native");
var import_jsx_runtime58 = require("react/jsx-runtime");
var GetTokenReceiveSection = ({ handleOpenClick }) => {
  const theme = useTheme();
  const styles3 = (0, import_react50.useMemo)(() => {
    return import_react_native59.StyleSheet.create({
      container: {
        width: "full",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: theme?.media.isMobile ? 20 : 32,
        justifyContent: "flex-start",
        borderRadius: 16
      },
      inner: {
        width: "full",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      },
      textContainer: {
        width: "calc(100% - 74px)",
        textAlign: "left"
      },
      title: {
        fontSize: theme?.media.isMobile ? theme.fontSize.xl : theme?.fontSize["2xl"],
        fontWeight: "bold"
      },
      subText: {
        marginTop: 8,
        fontSize: theme?.media.isMobile ? theme.fontSize.sm : theme?.fontSize.md
      },
      iconContainer: {
        width: 54,
        marginLeft: 20,
        padding: 16,
        borderRadius: theme?.radius.full,
        backgroundColor: theme?.color.primaryBlack
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(ButtonWithChildren, { customStyle: styles3.container, buttonColor: theme?.color.mint, onPress: handleOpenClick, children: /* @__PURE__ */ (0, import_jsx_runtime58.jsxs)(import_react_native59.View, { style: styles3.inner, children: [
    /* @__PURE__ */ (0, import_jsx_runtime58.jsxs)(import_react_native59.View, { style: styles3.textContainer, children: [
      /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(Text3, { style: styles3.title, children: "Receive Token" }),
      /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(Text3, { style: styles3.subText, children: "Tap to receive your exclusive check-in token and enjoy special discounts at participating stores" })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(import_react_native59.View, { style: styles3.iconContainer, children: import_react_native59.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(import_fi6.FiArrowUpRight, { color: "#BDFDC7", size: 22 }) : null })
  ] }) });
};

// src/components/pages/checkin/CheckInRewardModal.tsx
var import_react51 = require("react");
var import_bs6 = require("react-icons/bs");
var import_react_native60 = require("react-native");
var import_jsx_runtime59 = require("react/jsx-runtime");
var ModalHeader5 = ({ headerText }) => {
  const theme = useTheme();
  const styles3 = (0, import_react51.useMemo)(() => {
    return import_react_native60.StyleSheet.create({
      iconContainer: {
        width: 60,
        height: 60,
        marginHorizontal: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        backgroundColor: theme?.color.primaryBlack
      },
      title: {
        marginTop: 24,
        fontSize: theme?.media.isMobile ? theme.fontSize["2xl"] : theme?.fontSize["4xl"],
        fontWeight: "bold",
        textAlign: "center"
      }
    });
  }, [theme?.media.isMobile]);
  return /* @__PURE__ */ (0, import_jsx_runtime59.jsxs)(import_jsx_runtime59.Fragment, { children: [
    import_react_native60.Platform.OS === "web" ? /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(import_react_native60.View, { style: styles3.iconContainer, children: /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(import_bs6.BsExclamationCircleFill, { color: "#BDFDC7", size: 32 }) }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(Text3, { style: styles3.title, children: headerText })
  ] });
};
var ModalBody5 = ({
  onNavigation,
  visibleNavtoMywalletButton
}) => {
  const theme = useTheme();
  const styles3 = (0, import_react51.useMemo)(() => {
    return import_react_native60.StyleSheet.create({
      container: {
        width: "100%",
        marginTop: 32
      },
      exploreButton: {
        width: "100%",
        maxWidth: 310,
        marginHorizontal: "auto",
        paddingHorizontal: theme?.media.isMobile ? 20 : 40,
        paddingVertical: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme?.radius.full
      },
      buttonText: {
        fontSize: theme?.fontSize.md
      }
    });
  }, [theme?.media.isMobile]);
  const webStyle = {
    width: "100%",
    maxWidth: 310,
    margin: "0 auto",
    padding: theme?.media.isMobile ? "16px 20px" : "16px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme?.radius.full,
    background: theme?.color.mint,
    color: theme?.color.primaryBlack,
    textDecoration: "none"
  };
  return /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(import_react_native60.View, { style: styles3.container, children: visibleNavtoMywalletButton && /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(Link, { href: "/mywallet", customStyle: styles3.exploreButton, webStyle, onPress: onNavigation, children: "Confirm your wallet" }) });
};
var CheckInRewardModal = ({
  onNavigation,
  headerText,
  visibleNavtoMywalletButton,
  ...props
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(
    Modal,
    {
      headerChildren: /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(ModalHeader5, { headerText }),
      bodyChildren: /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(ModalBody5, { onNavigation, visibleNavtoMywalletButton }),
      ...props
    }
  );
};

// src/components/pages/mywallet/MembershipNFTsItem.tsx
var import_react52 = require("react");
var import_react_native61 = require("react-native");
var import_jsx_runtime60 = require("react/jsx-runtime");
var MembershipNFTsItem = ({ index: index2, membershipNFT }) => {
  const theme = useTheme();
  const abbreviatedAddress = (0, import_react52.useMemo)(() => {
    if (!membershipNFT?.contractAddress)
      return "";
    return `${membershipNFT?.contractAddress.substring(0, 6)}...${membershipNFT?.contractAddress.substring(
      membershipNFT?.contractAddress.length - 4
    )}`;
  }, [membershipNFT?.contractAddress]);
  const styles3 = (0, import_react52.useMemo)(() => {
    return import_react_native61.StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 160 : "auto",
        maxWidth: 160,
        marginLeft: index2 > 0 ? 10 : 0,
        padding: 16,
        display: "flex",
        flexGrow: theme?.media.isMobile ? 0 : 1,
        borderRadius: 16,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        textAlign: "center"
      },
      nftImage: {
        marginHorizontal: "auto"
      },
      nftTokenId: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.sm
      },
      title: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["lg"],
        paddingVertical: 8
      },
      address: {
        marginTop: 4,
        color: theme?.color.mint,
        fontSize: theme?.fontSize.xxs
      }
    });
  }, [theme, index2]);
  return /* @__PURE__ */ (0, import_jsx_runtime60.jsxs)(import_react_native61.View, { style: styles3.container, children: [
    /* @__PURE__ */ (0, import_jsx_runtime60.jsx)(import_react_native61.Image, { source: { uri: membershipNFT.tokenUri, width: 80, height: 120 }, style: styles3.nftImage }),
    /* @__PURE__ */ (0, import_jsx_runtime60.jsx)(import_react_native61.Text, { style: styles3.title, children: membershipNFT.title }),
    /* @__PURE__ */ (0, import_jsx_runtime60.jsxs)(import_react_native61.Text, { style: styles3.nftTokenId, children: [
      "No. ",
      membershipNFT.tokenId
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime60.jsx)(import_react_native61.Text, { style: styles3.address, children: abbreviatedAddress })
  ] });
};

// src/components/pages/mywallet/MembershipNFTsList.tsx
var import_react_native62 = require("react-native");
var import_jsx_runtime61 = require("react/jsx-runtime");
var MembershipNFTsList = ({ usersMembershipNFTs }) => {
  const theme = useTheme();
  const styles3 = import_react_native62.StyleSheet.create({
    myMembershipNFTsText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize["xl"]
    },
    membershipNFTsContainer: {
      marginTop: 16,
      display: "flex",
      flexDirection: "row",
      overflowX: "scroll"
    },
    myWalletMembershipNFTsContainer: {
      marginHorizontal: 80,
      paddingVertical: 32
    },
    mobileMyWalletMembershipNFTsContainer: {
      marginHorizontal: 40,
      paddingVertical: 12
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime61.jsx)(import_jsx_runtime61.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime61.jsxs)(
    import_react_native62.View,
    {
      style: theme?.media.isMobile ? styles3.mobileMyWalletMembershipNFTsContainer : styles3.myWalletMembershipNFTsContainer,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime61.jsx)(import_react_native62.Text, { style: styles3.myMembershipNFTsText, children: "Community Membership:" }),
        /* @__PURE__ */ (0, import_jsx_runtime61.jsx)(import_react_native62.View, { style: styles3.membershipNFTsContainer, children: usersMembershipNFTs.map((membershipNFT, i) => /* @__PURE__ */ (0, import_jsx_runtime61.jsx)(MembershipNFTsItem, { index: i, membershipNFT }, i)) })
      ]
    }
  ) });
};

// src/components/pages/mywallet/POAPNFTsList.tsx
var import_react_native63 = require("react-native");
var import_jsx_runtime62 = require("react/jsx-runtime");
var POAPNFTsList = ({ usersPOAPNFTSList }) => {
  const theme = useTheme();
  const styles3 = import_react_native63.StyleSheet.create({
    myMembershipNFTsText: {
      color: theme?.color.secondaryWhite,
      fontSize: theme?.fontSize["xl"]
    },
    membershipNFTsContainer: {
      marginTop: 16,
      display: "flex",
      flexDirection: "row",
      overflowX: "scroll"
    },
    myWalletMembershipNFTsContainer: {
      marginHorizontal: 80,
      paddingVertical: 32
    },
    mobileMyWalletMembershipNFTsContainer: {
      marginHorizontal: 40,
      paddingVertical: 12
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime62.jsx)(import_jsx_runtime62.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime62.jsxs)(
    import_react_native63.View,
    {
      style: theme?.media.isMobile ? styles3.mobileMyWalletMembershipNFTsContainer : styles3.myWalletMembershipNFTsContainer,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime62.jsx)(import_react_native63.Text, { style: styles3.myMembershipNFTsText, children: "Collectibles:" }),
        /* @__PURE__ */ (0, import_jsx_runtime62.jsx)(import_react_native63.View, { style: styles3.membershipNFTsContainer, children: usersPOAPNFTSList.map((membershipNFT, i) => /* @__PURE__ */ (0, import_jsx_runtime62.jsx)(MembershipNFTsItem, { index: i, membershipNFT }, i)) })
      ]
    }
  ) });
};

// src/components/pages/mywallet/FungibleTokenList.tsx
var import_react53 = require("react");
var import_react_native64 = require("react-native");
var import_jsx_runtime63 = require("react/jsx-runtime");
var FungibleTokenList = ({ usersFTokens }) => {
  const theme = useTheme();
  const styles3 = (0, import_react53.useMemo)(() => {
    return import_react_native64.StyleSheet.create({
      mywalletCurrentInfos: {
        paddingTop: theme?.media.isMobile ? 60 : 40,
        paddingBottom: theme?.media.isMobile ? 16 : 40,
        paddingHorizontal: theme?.media.isMobile ? 32 : 0,
        textAlign: "center"
      },
      mywalletBalance: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["6xl"]
      },
      destoreTokenInfo: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize["lg"]
      }
    });
  }, [theme]);
  const totalAmount = usersFTokens.reduce((sum, token) => sum + token.amount, 0);
  return /* @__PURE__ */ (0, import_jsx_runtime63.jsx)(import_jsx_runtime63.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime63.jsxs)(import_react_native64.View, { style: styles3.mywalletCurrentInfos, children: [
    /* @__PURE__ */ (0, import_jsx_runtime63.jsxs)(import_react_native64.Text, { style: styles3.mywalletBalance, children: [
      "$ ",
      totalAmount
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime63.jsx)(import_react_native64.Text, { style: styles3.destoreTokenInfo, children: "COIN balance" })
  ] }) });
};

// src/mocks/keys.ts
var KeyData = {
  data: [
    // {
    //   tokenId: 0,
    //   visual: '/key/key_rainbow.gif',
    //   name: 'Raibnow',
    //   price: 0.59,
    //   left: 30,
    //   address: '0x8cF3084E9B634272577BA5BfD4aA07b93Bd5eC6a',
    //   standart: 'ERC-1155',
    //   blockchain: 'Polygon',
    //   isGovernanceRights: true,
    //   isVotingRight: true,
    //   isPartnerRight: true,
    //   isAccesibleBoard: true,
    //   isManager: true,
    //   paperStoreUrl: 'https://paper.xyz/checkout/56145944-6ca3-496c-ac22-bfa4e0675ebe',
    //   store: {
    //     id: 1,
    //     url: 'Zero',
    //   },
    // },
    // {
    //   tokenId: 1,
    //   visual: '/key/key_golden.gif',
    //   name: 'Golden',
    //   price: 0.19,
    //   address: '0x8cF3084E9B634272577BA5BfD4aA07b93Bd5eC6a',
    //   standart: 'ERC-1155',
    //   blockchain: 'Polygon',
    //   isGovernanceRights: true,
    //   isVotingRight: true,
    //   isPartnerRight: true,
    //   isAccesibleBoard: false,
    //   isManager: false,
    //   paperStoreUrl: 'https://paper.xyz/checkout/be075c82-e4eb-45bc-8496-232ed1521a95',
    //   store: {
    //     id: 1,
    //     url: 'Zero',
    //   },
    // },
    {
      tokenId: 0,
      visual: "/key/key_silver.gif",
      name: "Silver",
      price: 29,
      address: "0xC87738Be56f29a0a2872De046cb4e3dEB9f9aA65",
      standart: "ERC-1155",
      blockchain: "Polygon",
      isGovernanceRights: true,
      isVotingRight: true,
      isPartnerRight: false,
      isAccesibleBoard: false,
      isManager: false,
      paperStoreUrl: "https://withpaper.com/checkout/b6792614-d70e-49c3-bb5a-5b44e2c3ea04",
      store: {
        id: 1,
        url: "StoreZero"
      }
    }
  ]
};

// src/mocks/orders.ts
var LineItemData = {
  data: [
    {
      id: "1",
      quantity: 1,
      price: 36,
      currencyCode: "USD",
      updatedAt: "1 hour ago",
      name: "Organic Skin Loation",
      vendor: "705 Origin"
    },
    {
      id: "2",
      quantity: 2,
      price: 92,
      currencyCode: "USD",
      updatedAt: "26 min ago",
      name: "Organic Skin Milk",
      vendor: "705 Origin"
    },
    {
      id: "3",
      quantity: 1,
      price: 65,
      currencyCode: "USD",
      updatedAt: "3 min ago",
      name: "705 Black Sweat",
      vendor: "705 Origin"
    },
    {
      id: "4",
      quantity: 1,
      price: 65,
      currencyCode: "USD",
      updatedAt: "3 min ago",
      name: "705 Black Sweat",
      vendor: "705 Origin"
    }
  ]
};

// src/mocks/proposal.ts
var ProposalMenuAppData = {
  data: [
    {
      imagePath: "/logo/logo_icon_black.svg",
      name: "Create special Proposal",
      description: "Make  a proposal for sellection brand and product",
      upRightArrowImagePath: "/icon/u_pen.svg"
    },
    {
      imagePath: "/logo/logo_tallyxyz.png",
      name: "Create general Proposal",
      description: "Make proposal for general purpose for DAO at Tally.xyz",
      upRightArrowImagePath: "/icon/fi_link.svg"
    },
    {
      imagePath: "/logo/logo_tallyxyz.png",
      name: "View Governance",
      description: "View governance for DAO at Tally.xyz ",
      upRightArrowImagePath: "/icon/fi_link.svg"
    }
  ]
};

// src/mocks/settings.ts
var ProfileData = {
  name: "Datz Daito",
  avatarPath: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
};
var items = [
  {
    icon: {
      height: 20,
      path: "/icon/user.svg",
      width: 20
    },
    title: "My Account"
  },
  {
    icon: {
      height: 21,
      path: "/icon/shopping-cart.svg",
      width: 22
    },
    title: "Store List"
  },
  {
    icon: {
      height: 20,
      path: "/icon/bell.svg",
      width: 18
    },
    title: "Notification"
  },
  {
    icon: {
      height: 20,
      path: "/icon/help-circle.svg",
      width: 20
    },
    title: "Support"
  }
];
var chevronRightIconPath = "/icon/chevron-right.svg";
var SettingsItemsPropsData = {
  items,
  chevronRightIconPath,
  version: "1.0"
};

// src/constants/launchedTokens.ts
var productionStoreZeroAssets = [];
var devStoreZeroAssets = [
  {
    poapSlug: "DSCE2",
    eventDate: "2023-09-08",
    title: "StoreZero 02 Party",
    tokenId: 551,
    poapDetailUrl: "https://poap.gallery/event/2",
    contractAddress: "0x8bbe92c53a98f2fc199780815d59ab507dd4b4e9",
    isMembership: false
  },
  {
    isFungible: true,
    tokenId: 999,
    contractAddress: "0x8bbe92c53a98f2fc199780815d59ab507dd4b4e9",
    title: "StoreZero Coin",
    isMembership: false
  },
  {
    isMembership: true,
    tokenId: 0,
    title: "StoreZero",
    contractAddress: "0x8bbe92c53a98f2fc199780815d59ab507dd4b4e9"
  },
  {
    tokenId: 3,
    title: "DCGV2",
    contractAddress: "0x8bbe92c53a98f2fc199780815d59ab507dd4b4e9",
    isMembership: false
  },
  {
    poapSlug: "WD",
    tokenId: 5,
    eventDate: "2023-09-08",
    title: "Welcome Drink",
    poapDetailUrl: "https://poap.gallery/event/2",
    contractAddress: "0x8bbe92c53a98f2fc199780815d59ab507dd4b4e9",
    isMembership: false
  },
  {
    poapSlug: "ZGV2",
    tokenId: 17,
    eventDate: "2023-10-28",
    title: "Zero Gathering Vol.002",
    poapDetailUrl: "https://poap.gallery/event/3",
    contractAddress: "0x8bbe92c53a98f2fc199780815d59ab507dd4b4e9",
    isMembership: false
  }
];
var getStoreZeroLaunchedAssets = () => {
  if (process.env["REACT_APP_VERCEL_ENV"] === "production") {
    return productionStoreZeroAssets;
  } else {
    return devStoreZeroAssets;
  }
};

// src/mocks/stores.ts
var storeZeroAssets = getStoreZeroLaunchedAssets();
var storeZeroPoapNFTs = storeZeroAssets.filter(
  (nft) => "isMembership" in nft && !nft.isMembership
);
var storeZeroMembership = storeZeroAssets.filter(
  (nft) => "isMembership" in nft && nft.isMembership
);
var storeZeroFungibleTokens = storeZeroAssets.filter(
  (token) => "isFungible" in token && token.isFungible
);
var storeZeroActiveEvent = [
  {
    url: "proposals/this-week",
    description: "This Week"
  },
  {
    url: "proposals/last-week",
    description: "Last Week"
  }
];
var StoreData = {
  data: [
    {
      id: 1,
      visual: "/store/destore-zero.png",
      name: "StoreZero",
      url: "StoreZero",
      isActive: true,
      place: "348 Hayes Street, San Francisco",
      mission: "build the world\u2019s first DAO-owned retail store.",
      description: "Zero is the most interactive store in the world.A curated blend of new and pre-owned items, handpicked by Datz and Dax, featuring apparel, accessories, and lifestyle goods to cater to a creative lifestyle.",
      reason: "Datz is the first fashion YouTuber in Japan since 15 y.o.Featured as one of the top 10 fashion YouTubers by the largest fashion media in Japan with 1M+ views in total.Collaborated with Dax (from Curated By Dax, the best showroom in NYC), Zero is handpicking niche designer pieces in an inclusive way at the space.",
      deployedAddresses: {
        Treasury: storeZeroAddresses().TREASURY_ADDRESS,
        StoreManager: storeZeroAddresses().STORE_MANAGER_ADDRESS,
        StoreVote: storeZeroAddresses().VOTE_CONTRACT_ADDRESS
      },
      activeEvent: [
        {
          eventName: "Proposals",
          eventDetails: storeZeroActiveEvent
        }
      ],
      poapNFTs: storeZeroPoapNFTs,
      fungibleTokens: storeZeroFungibleTokens,
      membershipNFTs: storeZeroMembership,
      logoImagePathSecondaryWhite: "/mock/store_logo_secondary_white.svg",
      logoImagePathPrimaryBlack: "/mock/store_logo_primary_black.svg"
    },
    {
      id: 2,
      visual: "/store/destore-next.png",
      name: "????",
      url: "",
      isActive: false,
      place: "348 Hayes Street, San Francisco",
      mission: "build the world\u2019s first DAO-owned retail store.",
      description: "SF Destore was successfully leased at 348 Hayes St, San Francisco. It's in front of Away, Warby Parker, and Mejuri. The area is called Hayes Valley, where a number of modern luxury brands dream opening their flag ships.",
      reason: "The key of the retail is the community.The distance between consumers and owners are getting closer these eras.However, there has been a limitation to be beyond evangelist as a consumer.\n\nThat is where DeStore come from with utlizing crypto technolgies.\n\nNow, consumers can easily become owners.\n\nAt the dawn of the crypto age, global of creative enthusiatsts come together to define the future of retail starting at SF Destore.\n\nAfter the success of SF Destore, there will be many of next level of bottom-up community driven store folliwing you.\n\nSTORE_0 is going to be the historical first DAO-owned store in the world.",
      logoImagePathSecondaryWhite: "/mock/store_logo.svg",
      logoImagePathPrimaryBlack: "/mock/store_logo_primary_black.svg"
    }
  ]
};
var StoreMenuAppData = {
  data: [
    {
      imagePath: "/icon/discord.svg",
      name: "Discode",
      description: "Manage roles & guard server",
      upRightArrowImagePath: "/icon/up-right-arrow.svg"
    },
    {
      imagePath: "/icon/snapshot.svg",
      name: "Snapshot",
      description: "Manage roles & guard server",
      upRightArrowImagePath: "/icon/up-right-arrow.svg"
    },
    {
      imagePath: "/icon/notion.svg",
      name: "Notion",
      description: "Manage roles & guard server",
      upRightArrowImagePath: "/icon/up-right-arrow.svg"
    }
  ]
};
var StoreSalesInfoData = {
  data: [
    {
      title: "Daily Sales",
      value: "$320"
    },
    {
      title: "Daily Customers",
      value: "80"
    },
    {
      title: "Daily Sales Target",
      value: "$500"
    }
  ]
};
var StoreLiveStreamingData = {
  data: [
    {
      playbackUrl: process.env["REACT_APP_PLAYBACK_URL"] ?? "",
      internalGif: "/mock/live.gif"
    }
    // {
    //   playbackUrl: process.env['REACT_APP_PLAYBACK_URL'] ?? '',
    //   internalGif: '/mock/live.gif',
    // },
    // {
    //   playbackUrl: process.env['REACT_APP_PLAYBACK_URL'] ?? '',
    //   internalGif: '/mock/live.gif',
    // },
  ]
};
var StoreIndicatorsData = {
  data: {
    treasuryBalance: "$1,000",
    storeBalance: "$45,000",
    expense: "$3,563",
    grossSales: "125",
    members: "382",
    profit: "$3,563"
  }
};
var BrandSalesRankingData = {
  data: [
    { brandName: "705 Origin", sales: 7500 },
    { brandName: "Adidas", sales: 7e3 },
    { brandName: "Hellaco", sales: 6500 },
    { brandName: "ADLAR", sales: 6e3 },
    { brandName: "Klark", sales: 5500 },
    { brandName: "Force Majeure", sales: 75 }
  ]
};
var SalesAndExpenseData = {
  data: [
    {
      sales: 5e3,
      expense: 3e3,
      month: "Jan"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Feb"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Mar"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Apr"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "May"
    },
    {
      sales: 5e3,
      expense: 3e3,
      month: "Jun"
    }
  ]
};

// ../../node_modules/drizzle-orm/alias-cf8e03cd.mjs
var entityKind = Symbol.for("drizzle:entityKind");
var hasOwnEntityKind = Symbol.for("drizzle:hasOwnEntityKind");
function is(value, type) {
  if (!value || typeof value !== "object") {
    return false;
  }
  if (value instanceof type) {
    return true;
  }
  if (!Object.prototype.hasOwnProperty.call(type, entityKind)) {
    throw new Error(`Class "${type.name ?? "<unknown>"}" doesn't look like a Drizzle entity. If this is incorrect and the class is provided by Drizzle, please report this as a bug.`);
  }
  let cls = value.constructor;
  if (cls) {
    while (cls) {
      if (entityKind in cls && cls[entityKind] === type[entityKind]) {
        return true;
      }
      cls = Object.getPrototypeOf(cls);
    }
  }
  return false;
}
var _a;
var Column = class {
  table;
  name;
  primary;
  notNull;
  default;
  defaultFn;
  hasDefault;
  isUnique;
  uniqueName;
  uniqueType;
  dataType;
  columnType;
  enumValues = void 0;
  config;
  constructor(table, config) {
    this.table = table;
    this.config = config;
    this.name = config.name;
    this.notNull = config.notNull;
    this.default = config.default;
    this.defaultFn = config.defaultFn;
    this.hasDefault = config.hasDefault;
    this.primary = config.primaryKey;
    this.isUnique = config.isUnique;
    this.uniqueName = config.uniqueName;
    this.uniqueType = config.uniqueType;
    this.dataType = config.dataType;
    this.columnType = config.columnType;
  }
  mapFromDriverValue(value) {
    return value;
  }
  mapToDriverValue(value) {
    return value;
  }
};
_a = entityKind;
__publicField(Column, _a, "Column");
var ViewBaseConfig = Symbol.for("drizzle:ViewBaseConfig");
var _a2;
var View55 = class {
  /** @internal */
  [(_a2 = entityKind, ViewBaseConfig)];
  constructor({ name, schema, selectedFields, query }) {
    this[ViewBaseConfig] = {
      name,
      originalName: name,
      schema,
      selectedFields,
      query,
      isExisting: !query,
      isAlias: false
    };
  }
  getSQL() {
    return new SQL([this]);
  }
};
__publicField(View55, _a2, "View");
var SubqueryConfig = Symbol.for("drizzle:SubqueryConfig");
var _a3;
var Subquery = class {
  /** @internal */
  [(_a3 = entityKind, SubqueryConfig)];
  constructor(sql2, selection, alias, isWith = false) {
    this[SubqueryConfig] = {
      sql: sql2,
      selection,
      alias,
      isWith
    };
  }
  getSQL() {
    return new SQL([this]);
  }
};
__publicField(Subquery, _a3, "Subquery");
var _a4;
var WithSubquery = class extends Subquery {
};
_a4 = entityKind;
__publicField(WithSubquery, _a4, "WithSubquery");
var _a5;
var _SelectionProxyHandler = class {
  config;
  constructor(config) {
    this.config = { ...config };
  }
  get(subquery, prop) {
    if (prop === SubqueryConfig) {
      return {
        ...subquery[SubqueryConfig],
        selection: new Proxy(subquery[SubqueryConfig].selection, this)
      };
    }
    if (prop === ViewBaseConfig) {
      return {
        ...subquery[ViewBaseConfig],
        selectedFields: new Proxy(subquery[ViewBaseConfig].selectedFields, this)
      };
    }
    if (typeof prop === "symbol") {
      return subquery[prop];
    }
    const columns = is(subquery, Subquery) ? subquery[SubqueryConfig].selection : is(subquery, View55) ? subquery[ViewBaseConfig].selectedFields : subquery;
    const value = columns[prop];
    if (is(value, SQL.Aliased)) {
      if (this.config.sqlAliasedBehavior === "sql" && !value.isSelectionField) {
        return value.sql;
      }
      const newValue = value.clone();
      newValue.isSelectionField = true;
      return newValue;
    }
    if (is(value, SQL)) {
      if (this.config.sqlBehavior === "sql") {
        return value;
      }
      throw new Error(`You tried to reference "${prop}" field from a subquery, which is a raw SQL field, but it doesn't have an alias declared. Please add an alias to the field using ".as('alias')" method.`);
    }
    if (is(value, Column)) {
      if (this.config.alias) {
        return new Proxy(value, new ColumnAliasProxyHandler(new Proxy(value.table, new TableAliasProxyHandler(this.config.alias, this.config.replaceOriginalName ?? false))));
      }
      return value;
    }
    if (typeof value !== "object" || value === null) {
      return value;
    }
    return new Proxy(value, new _SelectionProxyHandler(this.config));
  }
};
var SelectionProxyHandler = _SelectionProxyHandler;
_a5 = entityKind;
__publicField(SelectionProxyHandler, _a5, "SelectionProxyHandler");
function orderSelectedFields(fields, pathPrefix) {
  return Object.entries(fields).reduce((result, [name, field]) => {
    if (typeof name !== "string") {
      return result;
    }
    const newPath = pathPrefix ? [...pathPrefix, name] : [name];
    if (is(field, Column) || is(field, SQL) || is(field, SQL.Aliased)) {
      result.push({ path: newPath, field });
    } else if (is(field, Table)) {
      result.push(...orderSelectedFields(field[Table.Symbol.Columns], newPath));
    } else {
      result.push(...orderSelectedFields(field, newPath));
    }
    return result;
  }, []);
}
function applyMixins(baseClass, extendedClasses) {
  for (const extendedClass of extendedClasses) {
    for (const name of Object.getOwnPropertyNames(extendedClass.prototype)) {
      Object.defineProperty(baseClass.prototype, name, Object.getOwnPropertyDescriptor(extendedClass.prototype, name) || /* @__PURE__ */ Object.create(null));
    }
  }
}
function getTableColumns(table) {
  return table[Table.Symbol.Columns];
}
function getTableLikeName(table) {
  return is(table, Subquery) ? table[SubqueryConfig].alias : is(table, View55) ? table[ViewBaseConfig].name : is(table, SQL) ? void 0 : table[Table.Symbol.IsAlias] ? table[Table.Symbol.Name] : table[Table.Symbol.BaseName];
}
function iife(fn, ...args) {
  return fn(...args);
}
var TableName = Symbol.for("drizzle:Name");
var Schema = Symbol.for("drizzle:Schema");
var Columns = Symbol.for("drizzle:Columns");
var OriginalName = Symbol.for("drizzle:OriginalName");
var BaseName = Symbol.for("drizzle:BaseName");
var IsAlias = Symbol.for("drizzle:IsAlias");
var ExtraConfigBuilder = Symbol.for("drizzle:ExtraConfigBuilder");
var IsDrizzleTable = Symbol.for("drizzle:IsDrizzleTable");
var _a6;
var Table = class {
  /**
   * @internal
   * Can be changed if the table is aliased.
   */
  [(_a6 = entityKind, TableName)];
  /**
   * @internal
   * Used to store the original name of the table, before any aliasing.
   */
  [OriginalName];
  /** @internal */
  [Schema];
  /** @internal */
  [Columns];
  /**
   *  @internal
   * Used to store the table name before the transformation via the `tableCreator` functions.
   */
  [BaseName];
  /** @internal */
  [IsAlias] = false;
  /** @internal */
  [ExtraConfigBuilder] = void 0;
  [IsDrizzleTable] = true;
  constructor(name, schema, baseName) {
    this[TableName] = this[OriginalName] = name;
    this[Schema] = schema;
    this[BaseName] = baseName;
  }
  getSQL() {
    return new SQL([this]);
  }
};
__publicField(Table, _a6, "Table");
/** @internal */
__publicField(Table, "Symbol", {
  Name: TableName,
  Schema,
  OriginalName,
  Columns,
  BaseName,
  IsAlias,
  ExtraConfigBuilder
});
function getTableName(table) {
  return table[TableName];
}
var _a7;
var QueryPromise = class {
  [(_a7 = entityKind, Symbol.toStringTag)] = "QueryPromise";
  catch(onRejected) {
    return this.then(void 0, onRejected);
  }
  finally(onFinally) {
    return this.then((value) => {
      onFinally?.();
      return value;
    }, (reason) => {
      onFinally?.();
      throw reason;
    });
  }
  then(onFulfilled, onRejected) {
    return this.execute().then(onFulfilled, onRejected);
  }
};
__publicField(QueryPromise, _a7, "QueryPromise");
var tracer = {
  startActiveSpan(name, fn) {
    {
      return fn();
    }
  }
};
var _a8;
var _DrizzleError = class extends Error {
  constructor(message) {
    super(message);
    this.name = "DrizzleError";
  }
  static wrap(error, message) {
    return error instanceof Error ? new _DrizzleError(message ? `${message}: ${error.message}` : error.message) : new _DrizzleError(message ?? String(error));
  }
};
var DrizzleError = _DrizzleError;
_a8 = entityKind;
__publicField(DrizzleError, _a8, "DrizzleError");
var _a9;
var TransactionRollbackError = class extends DrizzleError {
  constructor() {
    super("Rollback");
  }
};
_a9 = entityKind;
__publicField(TransactionRollbackError, _a9, "TransactionRollbackError");
var InlineForeignKeys = Symbol.for("drizzle:PgInlineForeignKeys");
var _a10;
var PgTable = class extends Table {
  /**@internal */
  [(_a10 = entityKind, InlineForeignKeys)] = [];
  /** @internal */
  [Table.Symbol.ExtraConfigBuilder] = void 0;
};
__publicField(PgTable, _a10, "PgTable");
/** @internal */
__publicField(PgTable, "Symbol", Object.assign({}, Table.Symbol, {
  InlineForeignKeys
}));
function pgTableWithSchema(name, columns, extraConfig, schema, baseName = name) {
  const rawTable = new PgTable(name, schema, baseName);
  const builtColumns = Object.fromEntries(Object.entries(columns).map(([name2, colBuilderBase]) => {
    const colBuilder = colBuilderBase;
    const column = colBuilder.build(rawTable);
    rawTable[InlineForeignKeys].push(...colBuilder.buildForeignKeys(column, rawTable));
    return [name2, column];
  }));
  const table = Object.assign(rawTable, builtColumns);
  table[Table.Symbol.Columns] = builtColumns;
  if (extraConfig) {
    table[PgTable.Symbol.ExtraConfigBuilder] = extraConfig;
  }
  return table;
}
var pgTable = (name, columns, extraConfig) => {
  return pgTableWithSchema(name, columns, extraConfig, void 0);
};
var _a11;
var CheckBuilder = class {
  name;
  value;
  brand;
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
  /** @internal */
  build(table) {
    return new Check(table, this);
  }
};
_a11 = entityKind;
__publicField(CheckBuilder, _a11, "PgCheckBuilder");
var _a12;
var Check = class {
  table;
  name;
  value;
  constructor(table, builder) {
    this.table = table;
    this.name = builder.name;
    this.value = builder.value;
  }
};
_a12 = entityKind;
__publicField(Check, _a12, "PgCheck");
var _a13;
var ForeignKeyBuilder = class {
  /** @internal */
  reference;
  /** @internal */
  _onUpdate = "no action";
  /** @internal */
  _onDelete = "no action";
  constructor(config, actions) {
    this.reference = () => {
      const { columns, foreignColumns } = config();
      return { columns, foreignTable: foreignColumns[0].table, foreignColumns };
    };
    if (actions) {
      this._onUpdate = actions.onUpdate;
      this._onDelete = actions.onDelete;
    }
  }
  onUpdate(action) {
    this._onUpdate = action === void 0 ? "no action" : action;
    return this;
  }
  onDelete(action) {
    this._onDelete = action === void 0 ? "no action" : action;
    return this;
  }
  /** @internal */
  build(table) {
    return new ForeignKey(table, this);
  }
};
_a13 = entityKind;
__publicField(ForeignKeyBuilder, _a13, "PgForeignKeyBuilder");
var _a14;
var ForeignKey = class {
  table;
  reference;
  onUpdate;
  onDelete;
  constructor(table, builder) {
    this.table = table;
    this.reference = builder.reference;
    this.onUpdate = builder._onUpdate;
    this.onDelete = builder._onDelete;
  }
  getName() {
    const { columns, foreignColumns } = this.reference();
    const columnNames = columns.map((column) => column.name);
    const foreignColumnNames = foreignColumns.map((column) => column.name);
    const chunks = [
      this.table[PgTable.Symbol.Name],
      ...columnNames,
      foreignColumns[0].table[PgTable.Symbol.Name],
      ...foreignColumnNames
    ];
    return `${chunks.join("_")}_fk`;
  }
};
_a14 = entityKind;
__publicField(ForeignKey, _a14, "PgForeignKey");
var _a15;
var IndexBuilderOn = class {
  unique;
  name;
  constructor(unique2, name) {
    this.unique = unique2;
    this.name = name;
  }
  on(...columns) {
    return new IndexBuilder(columns, this.unique, false, this.name);
  }
  onOnly(...columns) {
    return new IndexBuilder(columns, this.unique, true, this.name);
  }
};
_a15 = entityKind;
__publicField(IndexBuilderOn, _a15, "PgIndexBuilderOn");
var _a16;
var IndexBuilder = class {
  /** @internal */
  config;
  constructor(columns, unique2, only, name) {
    this.config = {
      name,
      columns,
      unique: unique2,
      only
    };
  }
  concurrently() {
    this.config.concurrently = true;
    return this;
  }
  using(method) {
    this.config.using = method;
    return this;
  }
  asc() {
    this.config.order = "asc";
    return this;
  }
  desc() {
    this.config.order = "desc";
    return this;
  }
  nullsFirst() {
    this.config.nulls = "first";
    return this;
  }
  nullsLast() {
    this.config.nulls = "last";
    return this;
  }
  where(condition) {
    this.config.where = condition;
    return this;
  }
  /** @internal */
  build(table) {
    return new Index(this.config, table);
  }
};
_a16 = entityKind;
__publicField(IndexBuilder, _a16, "PgIndexBuilder");
var _a17;
var Index = class {
  config;
  constructor(config, table) {
    this.config = { ...config, table };
  }
};
_a17 = entityKind;
__publicField(Index, _a17, "PgIndex");
function uniqueIndex(name) {
  return new IndexBuilderOn(true, name);
}
var _a18;
var PrimaryKeyBuilder = class {
  /** @internal */
  columns;
  constructor(columns) {
    this.columns = columns;
  }
  /** @internal */
  build(table) {
    return new PrimaryKey(table, this.columns);
  }
};
_a18 = entityKind;
__publicField(PrimaryKeyBuilder, _a18, "PgPrimaryKeyBuilder");
var _a19;
var PrimaryKey = class {
  table;
  columns;
  constructor(table, columns) {
    this.table = table;
    this.columns = columns;
  }
  getName() {
    return `${this.table[PgTable.Symbol.Name]}_${this.columns.map((column) => column.name).join("_")}_pk`;
  }
};
_a19 = entityKind;
__publicField(PrimaryKey, _a19, "PgPrimaryKey");
function uniqueKeyName(table, columns) {
  return `${table[PgTable.Symbol.Name]}_${columns.join("_")}_unique`;
}
var _a20;
var UniqueConstraintBuilder = class {
  name;
  /** @internal */
  columns;
  /** @internal */
  nullsNotDistinctConfig = false;
  constructor(columns, name) {
    this.name = name;
    this.columns = columns;
  }
  nullsNotDistinct() {
    this.nullsNotDistinctConfig = true;
    return this;
  }
  /** @internal */
  build(table) {
    return new UniqueConstraint(table, this.columns, this.nullsNotDistinctConfig, this.name);
  }
};
_a20 = entityKind;
__publicField(UniqueConstraintBuilder, _a20, "PgUniqueConstraintBuilder");
var _a21;
var UniqueOnConstraintBuilder = class {
  /** @internal */
  name;
  constructor(name) {
    this.name = name;
  }
  on(...columns) {
    return new UniqueConstraintBuilder(columns, this.name);
  }
};
_a21 = entityKind;
__publicField(UniqueOnConstraintBuilder, _a21, "PgUniqueOnConstraintBuilder");
var _a22;
var UniqueConstraint = class {
  table;
  columns;
  name;
  nullsNotDistinct = false;
  constructor(table, columns, nullsNotDistinct, name) {
    this.table = table;
    this.columns = columns;
    this.name = name ?? uniqueKeyName(this.table, this.columns.map((column) => column.name));
    this.nullsNotDistinct = nullsNotDistinct;
  }
  getName() {
    return this.name;
  }
};
_a22 = entityKind;
__publicField(UniqueConstraint, _a22, "PgUniqueConstraint");
function parsePgArrayValue(arrayString, startFrom, inQuotes) {
  for (let i = startFrom; i < arrayString.length; i++) {
    const char2 = arrayString[i];
    if (char2 === "\\") {
      i++;
      continue;
    }
    if (char2 === '"') {
      return [arrayString.slice(startFrom, i).replace(/\\/g, ""), i + 1];
    }
    if (inQuotes) {
      continue;
    }
    if (char2 === "," || char2 === "}") {
      return [arrayString.slice(startFrom, i).replace(/\\/g, ""), i];
    }
  }
  return [arrayString.slice(startFrom).replace(/\\/g, ""), arrayString.length];
}
function parsePgNestedArray(arrayString, startFrom = 0) {
  const result = [];
  let i = startFrom;
  let lastCharIsComma = false;
  while (i < arrayString.length) {
    const char2 = arrayString[i];
    if (char2 === ",") {
      if (lastCharIsComma || i === startFrom) {
        result.push("");
      }
      lastCharIsComma = true;
      i++;
      continue;
    }
    lastCharIsComma = false;
    if (char2 === "\\") {
      i += 2;
      continue;
    }
    if (char2 === '"') {
      const [value2, startFrom2] = parsePgArrayValue(arrayString, i + 1, true);
      result.push(value2);
      i = startFrom2;
      continue;
    }
    if (char2 === "}") {
      return [result, i + 1];
    }
    if (char2 === "{") {
      const [value2, startFrom2] = parsePgNestedArray(arrayString, i + 1);
      result.push(value2);
      i = startFrom2;
      continue;
    }
    const [value, newStartFrom] = parsePgArrayValue(arrayString, i, false);
    result.push(value);
    i = newStartFrom;
  }
  return [result, i];
}
function parsePgArray(arrayString) {
  const [result] = parsePgNestedArray(arrayString, 1);
  return result;
}
function makePgArray(array) {
  return `{${array.map((item) => {
    if (Array.isArray(item)) {
      return makePgArray(item);
    }
    if (typeof item === "string" && item.includes(",")) {
      return `"${item.replace(/"/g, '\\"')}"`;
    }
    return `${item}`;
  }).join(",")}}`;
}
var _a23;
var ColumnBuilder = class {
  config;
  constructor(name, dataType, columnType) {
    this.config = {
      name,
      notNull: false,
      default: void 0,
      hasDefault: false,
      primaryKey: false,
      isUnique: false,
      uniqueName: void 0,
      uniqueType: void 0,
      dataType,
      columnType
    };
  }
  /**
   * Changes the data type of the column. Commonly used with `json` columns. Also, useful for branded types.
   *
   * @example
   * ```ts
   * const users = pgTable('users', {
   * 	id: integer('id').$type<UserId>().primaryKey(),
   * 	details: json('details').$type<UserDetails>().notNull(),
   * });
   * ```
   */
  $type() {
    return this;
  }
  /**
   * Adds a `not null` clause to the column definition.
   *
   * Affects the `select` model of the table - columns *without* `not null` will be nullable on select.
   */
  notNull() {
    this.config.notNull = true;
    return this;
  }
  /**
   * Adds a `default <value>` clause to the column definition.
   *
   * Affects the `insert` model of the table - columns *with* `default` are optional on insert.
   *
   * If you need to set a dynamic default value, use {@link $defaultFn} instead.
   */
  default(value) {
    this.config.default = value;
    this.config.hasDefault = true;
    return this;
  }
  /**
   * Adds a dynamic default value to the column.
   * The function will be called when the row is inserted, and the returned value will be used as the column value.
   *
   * **Note:** This value does not affect the `drizzle-kit` behavior, it is only used at runtime in `drizzle-orm`.
   */
  $defaultFn(fn) {
    this.config.defaultFn = fn;
    this.config.hasDefault = true;
    return this;
  }
  /**
   * Alias for {@link $defaultFn}.
   */
  $default = this.$defaultFn;
  /**
   * Adds a `primary key` clause to the column definition. This implicitly makes the column `not null`.
   *
   * In SQLite, `integer primary key` implicitly makes the column auto-incrementing.
   */
  primaryKey() {
    this.config.primaryKey = true;
    this.config.notNull = true;
    return this;
  }
};
_a23 = entityKind;
__publicField(ColumnBuilder, _a23, "ColumnBuilder");
var _a24;
var PgColumnBuilder = class extends ColumnBuilder {
  foreignKeyConfigs = [];
  array(size) {
    return new PgArrayBuilder(this.config.name, this, size);
  }
  references(ref, actions = {}) {
    this.foreignKeyConfigs.push({ ref, actions });
    return this;
  }
  unique(name, config) {
    this.config.isUnique = true;
    this.config.uniqueName = name;
    this.config.uniqueType = config?.nulls;
    return this;
  }
  /** @internal */
  buildForeignKeys(column, table) {
    return this.foreignKeyConfigs.map(({ ref, actions }) => {
      return iife((ref2, actions2) => {
        const builder = new ForeignKeyBuilder(() => {
          const foreignColumn = ref2();
          return { columns: [column], foreignColumns: [foreignColumn] };
        });
        if (actions2.onUpdate) {
          builder.onUpdate(actions2.onUpdate);
        }
        if (actions2.onDelete) {
          builder.onDelete(actions2.onDelete);
        }
        return builder.build(table);
      }, ref, actions);
    });
  }
};
_a24 = entityKind;
__publicField(PgColumnBuilder, _a24, "PgColumnBuilder");
var _a25;
var PgColumn = class extends Column {
  table;
  constructor(table, config) {
    if (!config.uniqueName) {
      config.uniqueName = uniqueKeyName(table, [config.name]);
    }
    super(table, config);
    this.table = table;
  }
};
_a25 = entityKind;
__publicField(PgColumn, _a25, "PgColumn");
var _a26;
var PgArrayBuilder = class extends PgColumnBuilder {
  constructor(name, baseBuilder, size) {
    super(name, "array", "PgArray");
    this.config.baseBuilder = baseBuilder;
    this.config.size = size;
  }
  /** @internal */
  build(table) {
    const baseColumn = this.config.baseBuilder.build(table);
    return new PgArray(table, this.config, baseColumn);
  }
};
_a26 = entityKind;
__publicField(PgArrayBuilder, _a26, "PgArrayBuilder");
var _a27;
var _PgArray = class extends PgColumn {
  baseColumn;
  range;
  size;
  constructor(table, config, baseColumn, range) {
    super(table, config);
    this.baseColumn = baseColumn;
    this.range = range;
    this.size = config.size;
  }
  getSQLType() {
    return `${this.baseColumn.getSQLType()}[${typeof this.size === "number" ? this.size : ""}]`;
  }
  mapFromDriverValue(value) {
    if (typeof value === "string") {
      value = parsePgArray(value);
    }
    return value.map((v) => this.baseColumn.mapFromDriverValue(v));
  }
  mapToDriverValue(value, isNestedArray = false) {
    const a = value.map((v) => v === null ? null : is(this.baseColumn, _PgArray) ? this.baseColumn.mapToDriverValue(v, true) : this.baseColumn.mapToDriverValue(v));
    if (isNestedArray)
      return a;
    return makePgArray(a);
  }
};
var PgArray = _PgArray;
_a27 = entityKind;
__publicField(PgArray, _a27, "PgArray");
var _a28;
var PgDateColumnBaseBuilder = class extends PgColumnBuilder {
  defaultNow() {
    return this.default(sql`now()`);
  }
};
_a28 = entityKind;
__publicField(PgDateColumnBaseBuilder, _a28, "PgDateColumnBaseBuilder");
var _a29;
var PgDateBuilder = class extends PgDateColumnBaseBuilder {
  constructor(name) {
    super(name, "date", "PgDate");
  }
  /** @internal */
  build(table) {
    return new PgDate(table, this.config);
  }
};
_a29 = entityKind;
__publicField(PgDateBuilder, _a29, "PgDateBuilder");
var _a30;
var PgDate = class extends PgColumn {
  getSQLType() {
    return "date";
  }
  mapFromDriverValue(value) {
    return new Date(value);
  }
  mapToDriverValue(value) {
    return value.toISOString();
  }
};
_a30 = entityKind;
__publicField(PgDate, _a30, "PgDate");
var _a31;
var PgDateStringBuilder = class extends PgDateColumnBaseBuilder {
  constructor(name) {
    super(name, "string", "PgDateString");
  }
  /** @internal */
  build(table) {
    return new PgDateString(table, this.config);
  }
};
_a31 = entityKind;
__publicField(PgDateStringBuilder, _a31, "PgDateStringBuilder");
var _a32;
var PgDateString = class extends PgColumn {
  getSQLType() {
    return "date";
  }
};
_a32 = entityKind;
__publicField(PgDateString, _a32, "PgDateString");
var _a33;
var PgJsonBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "json", "PgJson");
  }
  /** @internal */
  build(table) {
    return new PgJson(table, this.config);
  }
};
_a33 = entityKind;
__publicField(PgJsonBuilder, _a33, "PgJsonBuilder");
var _a34;
var PgJson = class extends PgColumn {
  constructor(table, config) {
    super(table, config);
  }
  getSQLType() {
    return "json";
  }
  mapToDriverValue(value) {
    return JSON.stringify(value);
  }
  mapFromDriverValue(value) {
    if (typeof value === "string") {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }
    return value;
  }
};
_a34 = entityKind;
__publicField(PgJson, _a34, "PgJson");
var _a35;
var PgJsonbBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "json", "PgJsonb");
  }
  /** @internal */
  build(table) {
    return new PgJsonb(table, this.config);
  }
};
_a35 = entityKind;
__publicField(PgJsonbBuilder, _a35, "PgJsonbBuilder");
var _a36;
var PgJsonb = class extends PgColumn {
  constructor(table, config) {
    super(table, config);
  }
  getSQLType() {
    return "jsonb";
  }
  mapToDriverValue(value) {
    return JSON.stringify(value);
  }
  mapFromDriverValue(value) {
    if (typeof value === "string") {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }
    return value;
  }
};
_a36 = entityKind;
__publicField(PgJsonb, _a36, "PgJsonb");
var _a37;
var PgNumericBuilder = class extends PgColumnBuilder {
  constructor(name, precision, scale) {
    super(name, "string", "PgNumeric");
    this.config.precision = precision;
    this.config.scale = scale;
  }
  /** @internal */
  build(table) {
    return new PgNumeric(table, this.config);
  }
};
_a37 = entityKind;
__publicField(PgNumericBuilder, _a37, "PgNumericBuilder");
var _a38;
var PgNumeric = class extends PgColumn {
  precision;
  scale;
  constructor(table, config) {
    super(table, config);
    this.precision = config.precision;
    this.scale = config.scale;
  }
  getSQLType() {
    if (this.precision !== void 0 && this.scale !== void 0) {
      return `numeric(${this.precision}, ${this.scale})`;
    } else if (this.precision === void 0) {
      return "numeric";
    } else {
      return `numeric(${this.precision})`;
    }
  }
};
_a38 = entityKind;
__publicField(PgNumeric, _a38, "PgNumeric");
var _a39;
var PgTimeBuilder = class extends PgDateColumnBaseBuilder {
  withTimezone;
  precision;
  constructor(name, withTimezone, precision) {
    super(name, "string", "PgTime");
    this.withTimezone = withTimezone;
    this.precision = precision;
    this.config.withTimezone = withTimezone;
    this.config.precision = precision;
  }
  /** @internal */
  build(table) {
    return new PgTime(table, this.config);
  }
};
_a39 = entityKind;
__publicField(PgTimeBuilder, _a39, "PgTimeBuilder");
var _a40;
var PgTime = class extends PgColumn {
  withTimezone;
  precision;
  constructor(table, config) {
    super(table, config);
    this.withTimezone = config.withTimezone;
    this.precision = config.precision;
  }
  getSQLType() {
    const precision = this.precision === void 0 ? "" : `(${this.precision})`;
    return `time${precision}${this.withTimezone ? " with time zone" : ""}`;
  }
};
_a40 = entityKind;
__publicField(PgTime, _a40, "PgTime");
var _a41;
var PgTimestampBuilder = class extends PgDateColumnBaseBuilder {
  constructor(name, withTimezone, precision) {
    super(name, "date", "PgTimestamp");
    this.config.withTimezone = withTimezone;
    this.config.precision = precision;
  }
  /** @internal */
  build(table) {
    return new PgTimestamp(table, this.config);
  }
};
_a41 = entityKind;
__publicField(PgTimestampBuilder, _a41, "PgTimestampBuilder");
var _a42;
var PgTimestamp = class extends PgColumn {
  withTimezone;
  precision;
  constructor(table, config) {
    super(table, config);
    this.withTimezone = config.withTimezone;
    this.precision = config.precision;
  }
  getSQLType() {
    const precision = this.precision === void 0 ? "" : ` (${this.precision})`;
    return `timestamp${precision}${this.withTimezone ? " with time zone" : ""}`;
  }
  mapFromDriverValue = (value) => {
    return new Date(this.withTimezone ? value : value + "+0000");
  };
  mapToDriverValue = (value) => {
    return this.withTimezone ? value.toUTCString() : value.toISOString();
  };
};
_a42 = entityKind;
__publicField(PgTimestamp, _a42, "PgTimestamp");
var _a43;
var PgTimestampStringBuilder = class extends PgDateColumnBaseBuilder {
  constructor(name, withTimezone, precision) {
    super(name, "string", "PgTimestampString");
    this.config.withTimezone = withTimezone;
    this.config.precision = precision;
  }
  /** @internal */
  build(table) {
    return new PgTimestampString(table, this.config);
  }
};
_a43 = entityKind;
__publicField(PgTimestampStringBuilder, _a43, "PgTimestampStringBuilder");
var _a44;
var PgTimestampString = class extends PgColumn {
  withTimezone;
  precision;
  constructor(table, config) {
    super(table, config);
    this.withTimezone = config.withTimezone;
    this.precision = config.precision;
  }
  getSQLType() {
    const precision = this.precision === void 0 ? "" : `(${this.precision})`;
    return `timestamp${precision}${this.withTimezone ? " with time zone" : ""}`;
  }
};
_a44 = entityKind;
__publicField(PgTimestampString, _a44, "PgTimestampString");
function timestamp(name, config = {}) {
  if (config.mode === "string") {
    return new PgTimestampStringBuilder(name, config.withTimezone ?? false, config.precision);
  }
  return new PgTimestampBuilder(name, config.withTimezone ?? false, config.precision);
}
var _a45;
var PgUUIDBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "string", "PgUUID");
  }
  /**
   * Adds `default gen_random_uuid()` to the column definition.
   */
  defaultRandom() {
    return this.default(sql`gen_random_uuid()`);
  }
  /** @internal */
  build(table) {
    return new PgUUID(table, this.config);
  }
};
_a45 = entityKind;
__publicField(PgUUIDBuilder, _a45, "PgUUIDBuilder");
var _a46;
var PgUUID = class extends PgColumn {
  getSQLType() {
    return "uuid";
  }
};
_a46 = entityKind;
__publicField(PgUUID, _a46, "PgUUID");
var _a47;
var PgDialect = class {
  async migrate(migrations, session) {
    const migrationTableCreate = sql`
			CREATE TABLE IF NOT EXISTS "drizzle"."__drizzle_migrations" (
				id SERIAL PRIMARY KEY,
				hash text NOT NULL,
				created_at bigint
			)
		`;
    await session.execute(sql`CREATE SCHEMA IF NOT EXISTS "drizzle"`);
    await session.execute(migrationTableCreate);
    const dbMigrations = await session.all(sql`select id, hash, created_at from "drizzle"."__drizzle_migrations" order by created_at desc limit 1`);
    const lastDbMigration = dbMigrations[0];
    await session.transaction(async (tx) => {
      for await (const migration of migrations) {
        if (!lastDbMigration || Number(lastDbMigration.created_at) < migration.folderMillis) {
          for (const stmt of migration.sql) {
            await tx.execute(sql.raw(stmt));
          }
          await tx.execute(sql`insert into "drizzle"."__drizzle_migrations" ("hash", "created_at") values(${migration.hash}, ${migration.folderMillis})`);
        }
      }
    });
  }
  escapeName(name) {
    return `"${name}"`;
  }
  escapeParam(num) {
    return `$${num + 1}`;
  }
  escapeString(str) {
    return `'${str.replace(/'/g, "''")}'`;
  }
  buildDeleteQuery({ table, where, returning }) {
    const returningSql = returning ? sql` returning ${this.buildSelection(returning, { isSingleTable: true })}` : void 0;
    const whereSql = where ? sql` where ${where}` : void 0;
    return sql`delete from ${table}${whereSql}${returningSql}`;
  }
  buildUpdateSet(table, set) {
    const setEntries = Object.entries(set);
    const setSize = setEntries.length;
    return sql.join(setEntries.flatMap(([colName, value], i) => {
      const col = table[Table.Symbol.Columns][colName];
      const res = sql`${sql.identifier(col.name)} = ${value}`;
      if (i < setSize - 1) {
        return [res, sql.raw(", ")];
      }
      return [res];
    }));
  }
  buildUpdateQuery({ table, set, where, returning }) {
    const setSql = this.buildUpdateSet(table, set);
    const returningSql = returning ? sql` returning ${this.buildSelection(returning, { isSingleTable: true })}` : void 0;
    const whereSql = where ? sql` where ${where}` : void 0;
    return sql`update ${table} set ${setSql}${whereSql}${returningSql}`;
  }
  /**
   * Builds selection SQL with provided fields/expressions
   *
   * Examples:
   *
   * `select <selection> from`
   *
   * `insert ... returning <selection>`
   *
   * If `isSingleTable` is true, then columns won't be prefixed with table name
   */
  buildSelection(fields, { isSingleTable = false } = {}) {
    const columnsLen = fields.length;
    const chunks = fields.flatMap(({ field }, i) => {
      const chunk = [];
      if (is(field, SQL.Aliased) && field.isSelectionField) {
        chunk.push(sql.identifier(field.fieldAlias));
      } else if (is(field, SQL.Aliased) || is(field, SQL)) {
        const query = is(field, SQL.Aliased) ? field.sql : field;
        if (isSingleTable) {
          chunk.push(new SQL(query.queryChunks.map((c) => {
            if (is(c, PgColumn)) {
              return sql.identifier(c.name);
            }
            return c;
          })));
        } else {
          chunk.push(query);
        }
        if (is(field, SQL.Aliased)) {
          chunk.push(sql` as ${sql.identifier(field.fieldAlias)}`);
        }
      } else if (is(field, Column)) {
        if (isSingleTable) {
          chunk.push(sql.identifier(field.name));
        } else {
          chunk.push(field);
        }
      }
      if (i < columnsLen - 1) {
        chunk.push(sql`, `);
      }
      return chunk;
    });
    return sql.join(chunks);
  }
  buildSelectQuery({ withList, fields, fieldsFlat, where, having, table, joins, orderBy, groupBy, limit, offset, lockingClauses, distinct }) {
    const fieldsList = fieldsFlat ?? orderSelectedFields(fields);
    for (const f of fieldsList) {
      if (is(f.field, Column) && getTableName(f.field.table) !== (is(table, Subquery) ? table[SubqueryConfig].alias : is(table, PgViewBase) ? table[ViewBaseConfig].name : is(table, SQL) ? void 0 : getTableName(table)) && !((table2) => joins?.some(({ alias }) => alias === (table2[Table.Symbol.IsAlias] ? getTableName(table2) : table2[Table.Symbol.BaseName])))(f.field.table)) {
        const tableName = getTableName(f.field.table);
        throw new Error(`Your "${f.path.join("->")}" field references a column "${tableName}"."${f.field.name}", but the table "${tableName}" is not part of the query! Did you forget to join it?`);
      }
    }
    const isSingleTable = !joins || joins.length === 0;
    let withSql;
    if (withList?.length) {
      const withSqlChunks = [sql`with `];
      for (const [i, w] of withList.entries()) {
        withSqlChunks.push(sql`${sql.identifier(w[SubqueryConfig].alias)} as (${w[SubqueryConfig].sql})`);
        if (i < withList.length - 1) {
          withSqlChunks.push(sql`, `);
        }
      }
      withSqlChunks.push(sql` `);
      withSql = sql.join(withSqlChunks);
    }
    let distinctSql;
    if (distinct) {
      distinctSql = distinct === true ? sql` distinct` : sql` distinct on (${sql.join(distinct.on, ", ")})`;
    }
    const selection = this.buildSelection(fieldsList, { isSingleTable });
    const tableSql = (() => {
      if (is(table, Table) && table[Table.Symbol.OriginalName] !== table[Table.Symbol.Name]) {
        let fullName = sql`${sql.identifier(table[Table.Symbol.OriginalName])}`;
        if (table[Table.Symbol.Schema]) {
          fullName = sql`${sql.identifier(table[Table.Symbol.Schema])}.${fullName}`;
        }
        return sql`${fullName} ${sql.identifier(table[Table.Symbol.Name])}`;
      }
      return table;
    })();
    const joinsArray = [];
    if (joins) {
      for (const [index2, joinMeta] of joins.entries()) {
        if (index2 === 0) {
          joinsArray.push(sql` `);
        }
        const table2 = joinMeta.table;
        const lateralSql = joinMeta.lateral ? sql` lateral` : void 0;
        if (is(table2, PgTable)) {
          const tableName = table2[PgTable.Symbol.Name];
          const tableSchema = table2[PgTable.Symbol.Schema];
          const origTableName = table2[PgTable.Symbol.OriginalName];
          const alias = tableName === origTableName ? void 0 : joinMeta.alias;
          joinsArray.push(sql`${sql.raw(joinMeta.joinType)} join${lateralSql} ${tableSchema ? sql`${sql.identifier(tableSchema)}.` : void 0}${sql.identifier(origTableName)}${alias && sql` ${sql.identifier(alias)}`} on ${joinMeta.on}`);
        } else if (is(table2, View55)) {
          const viewName = table2[ViewBaseConfig].name;
          const viewSchema = table2[ViewBaseConfig].schema;
          const origViewName = table2[ViewBaseConfig].originalName;
          const alias = viewName === origViewName ? void 0 : joinMeta.alias;
          joinsArray.push(sql`${sql.raw(joinMeta.joinType)} join${lateralSql} ${viewSchema ? sql`${sql.identifier(viewSchema)}.` : void 0}${sql.identifier(origViewName)}${alias && sql` ${sql.identifier(alias)}`} on ${joinMeta.on}`);
        } else {
          joinsArray.push(sql`${sql.raw(joinMeta.joinType)} join${lateralSql} ${table2} on ${joinMeta.on}`);
        }
        if (index2 < joins.length - 1) {
          joinsArray.push(sql` `);
        }
      }
    }
    const joinsSql = sql.join(joinsArray);
    const whereSql = where ? sql` where ${where}` : void 0;
    const havingSql = having ? sql` having ${having}` : void 0;
    let orderBySql;
    if (orderBy && orderBy.length > 0) {
      orderBySql = sql` order by ${sql.join(orderBy, sql`, `)}`;
    }
    let groupBySql;
    if (groupBy && groupBy.length > 0) {
      groupBySql = sql` group by ${sql.join(groupBy, sql`, `)}`;
    }
    const limitSql = limit ? sql` limit ${limit}` : void 0;
    const offsetSql = offset ? sql` offset ${offset}` : void 0;
    const lockingClausesSql = sql.empty();
    if (lockingClauses) {
      for (const { strength, config } of lockingClauses) {
        const clauseSql = sql` for ${sql.raw(strength)}`;
        if (config.of) {
          clauseSql.append(sql` of ${config.of}`);
        }
        if (config.noWait) {
          clauseSql.append(sql` no wait`);
        } else if (config.skipLocked) {
          clauseSql.append(sql` skip locked`);
        }
        lockingClausesSql.append(clauseSql);
      }
    }
    return sql`${withSql}select${distinctSql} ${selection} from ${tableSql}${joinsSql}${whereSql}${groupBySql}${havingSql}${orderBySql}${limitSql}${offsetSql}${lockingClausesSql}`;
  }
  buildInsertQuery({ table, values, onConflict, returning }) {
    const valuesSqlList = [];
    const columns = table[Table.Symbol.Columns];
    const colEntries = Object.entries(columns);
    const insertOrder = colEntries.map(([, column]) => sql.identifier(column.name));
    for (const [valueIndex, value] of values.entries()) {
      const valueList = [];
      for (const [fieldName, col] of colEntries) {
        const colValue = value[fieldName];
        if (colValue === void 0 || is(colValue, Param) && colValue.value === void 0) {
          if (col.defaultFn !== void 0) {
            const defaultFnResult = col.defaultFn();
            const defaultValue = is(defaultFnResult, SQL) ? defaultFnResult : sql.param(defaultFnResult, col);
            valueList.push(defaultValue);
          } else {
            valueList.push(sql`default`);
          }
        } else {
          valueList.push(colValue);
        }
      }
      valuesSqlList.push(valueList);
      if (valueIndex < values.length - 1) {
        valuesSqlList.push(sql`, `);
      }
    }
    const valuesSql = sql.join(valuesSqlList);
    const returningSql = returning ? sql` returning ${this.buildSelection(returning, { isSingleTable: true })}` : void 0;
    const onConflictSql = onConflict ? sql` on conflict ${onConflict}` : void 0;
    return sql`insert into ${table} ${insertOrder} values ${valuesSql}${onConflictSql}${returningSql}`;
  }
  buildRefreshMaterializedViewQuery({ view, concurrently, withNoData }) {
    const concurrentlySql = concurrently ? sql` concurrently` : void 0;
    const withNoDataSql = withNoData ? sql` with no data` : void 0;
    return sql`refresh materialized view${concurrentlySql} ${view}${withNoDataSql}`;
  }
  prepareTyping(encoder) {
    if (is(encoder, PgJsonb) || is(encoder, PgJson)) {
      return "json";
    } else if (is(encoder, PgNumeric)) {
      return "decimal";
    } else if (is(encoder, PgTime)) {
      return "time";
    } else if (is(encoder, PgTimestamp)) {
      return "timestamp";
    } else if (is(encoder, PgDate)) {
      return "date";
    } else if (is(encoder, PgUUID)) {
      return "uuid";
    } else {
      return "none";
    }
  }
  sqlToQuery(sql2) {
    return sql2.toQuery({
      escapeName: this.escapeName,
      escapeParam: this.escapeParam,
      escapeString: this.escapeString,
      prepareTyping: this.prepareTyping
    });
  }
  // buildRelationalQueryWithPK({
  // 	fullSchema,
  // 	schema,
  // 	tableNamesMap,
  // 	table,
  // 	tableConfig,
  // 	queryConfig: config,
  // 	tableAlias,
  // 	isRoot = false,
  // 	joinOn,
  // }: {
  // 	fullSchema: Record<string, unknown>;
  // 	schema: TablesRelationalConfig;
  // 	tableNamesMap: Record<string, string>;
  // 	table: PgTable;
  // 	tableConfig: TableRelationalConfig;
  // 	queryConfig: true | DBQueryConfig<'many', true>;
  // 	tableAlias: string;
  // 	isRoot?: boolean;
  // 	joinOn?: SQL;
  // }): BuildRelationalQueryResult<PgTable, PgColumn> {
  // 	// For { "<relation>": true }, return a table with selection of all columns
  // 	if (config === true) {
  // 		const selectionEntries = Object.entries(tableConfig.columns);
  // 		const selection: BuildRelationalQueryResult<PgTable, PgColumn>['selection'] = selectionEntries.map((
  // 			[key, value],
  // 		) => ({
  // 			dbKey: value.name,
  // 			tsKey: key,
  // 			field: value as PgColumn,
  // 			relationTableTsKey: undefined,
  // 			isJson: false,
  // 			selection: [],
  // 		}));
  // 		return {
  // 			tableTsKey: tableConfig.tsName,
  // 			sql: table,
  // 			selection,
  // 		};
  // 	}
  // 	// let selection: BuildRelationalQueryResult<PgTable, PgColumn>['selection'] = [];
  // 	// let selectionForBuild = selection;
  // 	const aliasedColumns = Object.fromEntries(
  // 		Object.entries(tableConfig.columns).map(([key, value]) => [key, aliasedTableColumn(value, tableAlias)]),
  // 	);
  // 	const aliasedRelations = Object.fromEntries(
  // 		Object.entries(tableConfig.relations).map(([key, value]) => [key, aliasedRelation(value, tableAlias)]),
  // 	);
  // 	const aliasedFields = Object.assign({}, aliasedColumns, aliasedRelations);
  // 	let where, hasUserDefinedWhere;
  // 	if (config.where) {
  // 		const whereSql = typeof config.where === 'function' ? config.where(aliasedFields, operators) : config.where;
  // 		where = whereSql && mapColumnsInSQLToAlias(whereSql, tableAlias);
  // 		hasUserDefinedWhere = !!where;
  // 	}
  // 	where = and(joinOn, where);
  // 	// const fieldsSelection: { tsKey: string; value: PgColumn | SQL.Aliased; isExtra?: boolean }[] = [];
  // 	let joins: Join[] = [];
  // 	let selectedColumns: string[] = [];
  // 	// Figure out which columns to select
  // 	if (config.columns) {
  // 		let isIncludeMode = false;
  // 		for (const [field, value] of Object.entries(config.columns)) {
  // 			if (value === undefined) {
  // 				continue;
  // 			}
  // 			if (field in tableConfig.columns) {
  // 				if (!isIncludeMode && value === true) {
  // 					isIncludeMode = true;
  // 				}
  // 				selectedColumns.push(field);
  // 			}
  // 		}
  // 		if (selectedColumns.length > 0) {
  // 			selectedColumns = isIncludeMode
  // 				? selectedColumns.filter((c) => config.columns?.[c] === true)
  // 				: Object.keys(tableConfig.columns).filter((key) => !selectedColumns.includes(key));
  // 		}
  // 	} else {
  // 		// Select all columns if selection is not specified
  // 		selectedColumns = Object.keys(tableConfig.columns);
  // 	}
  // 	// for (const field of selectedColumns) {
  // 	// 	const column = tableConfig.columns[field]! as PgColumn;
  // 	// 	fieldsSelection.push({ tsKey: field, value: column });
  // 	// }
  // 	let initiallySelectedRelations: {
  // 		tsKey: string;
  // 		queryConfig: true | DBQueryConfig<'many', false>;
  // 		relation: Relation;
  // 	}[] = [];
  // 	// let selectedRelations: BuildRelationalQueryResult<PgTable, PgColumn>['selection'] = [];
  // 	// Figure out which relations to select
  // 	if (config.with) {
  // 		initiallySelectedRelations = Object.entries(config.with)
  // 			.filter((entry): entry is [typeof entry[0], NonNullable<typeof entry[1]>] => !!entry[1])
  // 			.map(([tsKey, queryConfig]) => ({ tsKey, queryConfig, relation: tableConfig.relations[tsKey]! }));
  // 	}
  // 	const manyRelations = initiallySelectedRelations.filter((r) =>
  // 		is(r.relation, Many)
  // 		&& (schema[tableNamesMap[r.relation.referencedTable[Table.Symbol.Name]]!]?.primaryKey.length ?? 0) > 0
  // 	);
  // 	// If this is the last Many relation (or there are no Many relations), we are on the innermost subquery level
  // 	const isInnermostQuery = manyRelations.length < 2;
  // 	const selectedExtras: {
  // 		tsKey: string;
  // 		value: SQL.Aliased;
  // 	}[] = [];
  // 	// Figure out which extras to select
  // 	if (isInnermostQuery && config.extras) {
  // 		const extras = typeof config.extras === 'function'
  // 			? config.extras(aliasedFields, { sql })
  // 			: config.extras;
  // 		for (const [tsKey, value] of Object.entries(extras)) {
  // 			selectedExtras.push({
  // 				tsKey,
  // 				value: mapColumnsInAliasedSQLToAlias(value, tableAlias),
  // 			});
  // 		}
  // 	}
  // 	// Transform `fieldsSelection` into `selection`
  // 	// `fieldsSelection` shouldn't be used after this point
  // 	// for (const { tsKey, value, isExtra } of fieldsSelection) {
  // 	// 	selection.push({
  // 	// 		dbKey: is(value, SQL.Aliased) ? value.fieldAlias : tableConfig.columns[tsKey]!.name,
  // 	// 		tsKey,
  // 	// 		field: is(value, Column) ? aliasedTableColumn(value, tableAlias) : value,
  // 	// 		relationTableTsKey: undefined,
  // 	// 		isJson: false,
  // 	// 		isExtra,
  // 	// 		selection: [],
  // 	// 	});
  // 	// }
  // 	let orderByOrig = typeof config.orderBy === 'function'
  // 		? config.orderBy(aliasedFields, orderByOperators)
  // 		: config.orderBy ?? [];
  // 	if (!Array.isArray(orderByOrig)) {
  // 		orderByOrig = [orderByOrig];
  // 	}
  // 	const orderBy = orderByOrig.map((orderByValue) => {
  // 		if (is(orderByValue, Column)) {
  // 			return aliasedTableColumn(orderByValue, tableAlias) as PgColumn;
  // 		}
  // 		return mapColumnsInSQLToAlias(orderByValue, tableAlias);
  // 	});
  // 	const limit = isInnermostQuery ? config.limit : undefined;
  // 	const offset = isInnermostQuery ? config.offset : undefined;
  // 	// For non-root queries without additional config except columns, return a table with selection
  // 	if (
  // 		!isRoot
  // 		&& initiallySelectedRelations.length === 0
  // 		&& selectedExtras.length === 0
  // 		&& !where
  // 		&& orderBy.length === 0
  // 		&& limit === undefined
  // 		&& offset === undefined
  // 	) {
  // 		return {
  // 			tableTsKey: tableConfig.tsName,
  // 			sql: table,
  // 			selection: selectedColumns.map((key) => ({
  // 				dbKey: tableConfig.columns[key]!.name,
  // 				tsKey: key,
  // 				field: tableConfig.columns[key] as PgColumn,
  // 				relationTableTsKey: undefined,
  // 				isJson: false,
  // 				selection: [],
  // 			})),
  // 		};
  // 	}
  // 	const selectedRelationsWithoutPK:
  // 	// Process all relations without primary keys, because they need to be joined differently and will all be on the same query level
  // 	for (
  // 		const {
  // 			tsKey: selectedRelationTsKey,
  // 			queryConfig: selectedRelationConfigValue,
  // 			relation,
  // 		} of initiallySelectedRelations
  // 	) {
  // 		const normalizedRelation = normalizeRelation(schema, tableNamesMap, relation);
  // 		const relationTableName = relation.referencedTable[Table.Symbol.Name];
  // 		const relationTableTsName = tableNamesMap[relationTableName]!;
  // 		const relationTable = schema[relationTableTsName]!;
  // 		if (relationTable.primaryKey.length > 0) {
  // 			continue;
  // 		}
  // 		const relationTableAlias = `${tableAlias}_${selectedRelationTsKey}`;
  // 		const joinOn = and(
  // 			...normalizedRelation.fields.map((field, i) =>
  // 				eq(
  // 					aliasedTableColumn(normalizedRelation.references[i]!, relationTableAlias),
  // 					aliasedTableColumn(field, tableAlias),
  // 				)
  // 			),
  // 		);
  // 		const builtRelation = this.buildRelationalQueryWithoutPK({
  // 			fullSchema,
  // 			schema,
  // 			tableNamesMap,
  // 			table: fullSchema[relationTableTsName] as PgTable,
  // 			tableConfig: schema[relationTableTsName]!,
  // 			queryConfig: selectedRelationConfigValue,
  // 			tableAlias: relationTableAlias,
  // 			joinOn,
  // 			nestedQueryRelation: relation,
  // 		});
  // 		const field = sql`${sql.identifier(relationTableAlias)}.${sql.identifier('data')}`.as(selectedRelationTsKey);
  // 		joins.push({
  // 			on: sql`true`,
  // 			table: new Subquery(builtRelation.sql as SQL, {}, relationTableAlias),
  // 			alias: relationTableAlias,
  // 			joinType: 'left',
  // 			lateral: true,
  // 		});
  // 		selectedRelations.push({
  // 			dbKey: selectedRelationTsKey,
  // 			tsKey: selectedRelationTsKey,
  // 			field,
  // 			relationTableTsKey: relationTableTsName,
  // 			isJson: true,
  // 			selection: builtRelation.selection,
  // 		});
  // 	}
  // 	const oneRelations = initiallySelectedRelations.filter((r): r is typeof r & { relation: One } =>
  // 		is(r.relation, One)
  // 	);
  // 	// Process all One relations with PKs, because they can all be joined on the same level
  // 	for (
  // 		const {
  // 			tsKey: selectedRelationTsKey,
  // 			queryConfig: selectedRelationConfigValue,
  // 			relation,
  // 		} of oneRelations
  // 	) {
  // 		const normalizedRelation = normalizeRelation(schema, tableNamesMap, relation);
  // 		const relationTableName = relation.referencedTable[Table.Symbol.Name];
  // 		const relationTableTsName = tableNamesMap[relationTableName]!;
  // 		const relationTableAlias = `${tableAlias}_${selectedRelationTsKey}`;
  // 		const relationTable = schema[relationTableTsName]!;
  // 		if (relationTable.primaryKey.length === 0) {
  // 			continue;
  // 		}
  // 		const joinOn = and(
  // 			...normalizedRelation.fields.map((field, i) =>
  // 				eq(
  // 					aliasedTableColumn(normalizedRelation.references[i]!, relationTableAlias),
  // 					aliasedTableColumn(field, tableAlias),
  // 				)
  // 			),
  // 		);
  // 		const builtRelation = this.buildRelationalQueryWithPK({
  // 			fullSchema,
  // 			schema,
  // 			tableNamesMap,
  // 			table: fullSchema[relationTableTsName] as PgTable,
  // 			tableConfig: schema[relationTableTsName]!,
  // 			queryConfig: selectedRelationConfigValue,
  // 			tableAlias: relationTableAlias,
  // 			joinOn,
  // 		});
  // 		const field = sql`case when ${sql.identifier(relationTableAlias)} is null then null else json_build_array(${
  // 			sql.join(
  // 				builtRelation.selection.map(({ field }) =>
  // 					is(field, SQL.Aliased)
  // 						? sql`${sql.identifier(relationTableAlias)}.${sql.identifier(field.fieldAlias)}`
  // 						: is(field, Column)
  // 						? aliasedTableColumn(field, relationTableAlias)
  // 						: field
  // 				),
  // 				sql`, `,
  // 			)
  // 		}) end`.as(selectedRelationTsKey);
  // 		const isLateralJoin = is(builtRelation.sql, SQL);
  // 		joins.push({
  // 			on: isLateralJoin ? sql`true` : joinOn,
  // 			table: is(builtRelation.sql, SQL)
  // 				? new Subquery(builtRelation.sql, {}, relationTableAlias)
  // 				: aliasedTable(builtRelation.sql, relationTableAlias),
  // 			alias: relationTableAlias,
  // 			joinType: 'left',
  // 			lateral: is(builtRelation.sql, SQL),
  // 		});
  // 		selectedRelations.push({
  // 			dbKey: selectedRelationTsKey,
  // 			tsKey: selectedRelationTsKey,
  // 			field,
  // 			relationTableTsKey: relationTableTsName,
  // 			isJson: true,
  // 			selection: builtRelation.selection,
  // 		});
  // 	}
  // 	let distinct: PgSelectConfig['distinct'];
  // 	let tableFrom: PgTable | Subquery = table;
  // 	// Process first Many relation - each one requires a nested subquery
  // 	const manyRelation = manyRelations[0];
  // 	if (manyRelation) {
  // 		const {
  // 			tsKey: selectedRelationTsKey,
  // 			queryConfig: selectedRelationQueryConfig,
  // 			relation,
  // 		} = manyRelation;
  // 		distinct = {
  // 			on: tableConfig.primaryKey.map((c) => aliasedTableColumn(c as PgColumn, tableAlias)),
  // 		};
  // 		const normalizedRelation = normalizeRelation(schema, tableNamesMap, relation);
  // 		const relationTableName = relation.referencedTable[Table.Symbol.Name];
  // 		const relationTableTsName = tableNamesMap[relationTableName]!;
  // 		const relationTableAlias = `${tableAlias}_${selectedRelationTsKey}`;
  // 		const joinOn = and(
  // 			...normalizedRelation.fields.map((field, i) =>
  // 				eq(
  // 					aliasedTableColumn(normalizedRelation.references[i]!, relationTableAlias),
  // 					aliasedTableColumn(field, tableAlias),
  // 				)
  // 			),
  // 		);
  // 		const builtRelationJoin = this.buildRelationalQueryWithPK({
  // 			fullSchema,
  // 			schema,
  // 			tableNamesMap,
  // 			table: fullSchema[relationTableTsName] as PgTable,
  // 			tableConfig: schema[relationTableTsName]!,
  // 			queryConfig: selectedRelationQueryConfig,
  // 			tableAlias: relationTableAlias,
  // 			joinOn,
  // 		});
  // 		const builtRelationSelectionField = sql`case when ${
  // 			sql.identifier(relationTableAlias)
  // 		} is null then '[]' else json_agg(json_build_array(${
  // 			sql.join(
  // 				builtRelationJoin.selection.map(({ field }) =>
  // 					is(field, SQL.Aliased)
  // 						? sql`${sql.identifier(relationTableAlias)}.${sql.identifier(field.fieldAlias)}`
  // 						: is(field, Column)
  // 						? aliasedTableColumn(field, relationTableAlias)
  // 						: field
  // 				),
  // 				sql`, `,
  // 			)
  // 		})) over (partition by ${sql.join(distinct.on, sql`, `)}) end`.as(selectedRelationTsKey);
  // 		const isLateralJoin = is(builtRelationJoin.sql, SQL);
  // 		joins.push({
  // 			on: isLateralJoin ? sql`true` : joinOn,
  // 			table: isLateralJoin
  // 				? new Subquery(builtRelationJoin.sql as SQL, {}, relationTableAlias)
  // 				: aliasedTable(builtRelationJoin.sql as PgTable, relationTableAlias),
  // 			alias: relationTableAlias,
  // 			joinType: 'left',
  // 			lateral: isLateralJoin,
  // 		});
  // 		// Build the "from" subquery with the remaining Many relations
  // 		const builtTableFrom = this.buildRelationalQueryWithPK({
  // 			fullSchema,
  // 			schema,
  // 			tableNamesMap,
  // 			table,
  // 			tableConfig,
  // 			queryConfig: {
  // 				...config,
  // 				where: undefined,
  // 				orderBy: undefined,
  // 				limit: undefined,
  // 				offset: undefined,
  // 				with: manyRelations.slice(1).reduce<NonNullable<typeof config['with']>>(
  // 					(result, { tsKey, queryConfig: configValue }) => {
  // 						result[tsKey] = configValue;
  // 						return result;
  // 					},
  // 					{},
  // 				),
  // 			},
  // 			tableAlias,
  // 		});
  // 		selectedRelations.push({
  // 			dbKey: selectedRelationTsKey,
  // 			tsKey: selectedRelationTsKey,
  // 			field: builtRelationSelectionField,
  // 			relationTableTsKey: relationTableTsName,
  // 			isJson: true,
  // 			selection: builtRelationJoin.selection,
  // 		});
  // 		// selection = builtTableFrom.selection.map((item) =>
  // 		// 	is(item.field, SQL.Aliased)
  // 		// 		? { ...item, field: sql`${sql.identifier(tableAlias)}.${sql.identifier(item.field.fieldAlias)}` }
  // 		// 		: item
  // 		// );
  // 		// selectionForBuild = [{
  // 		// 	dbKey: '*',
  // 		// 	tsKey: '*',
  // 		// 	field: sql`${sql.identifier(tableAlias)}.*`,
  // 		// 	selection: [],
  // 		// 	isJson: false,
  // 		// 	relationTableTsKey: undefined,
  // 		// }];
  // 		// const newSelectionItem: (typeof selection)[number] = {
  // 		// 	dbKey: selectedRelationTsKey,
  // 		// 	tsKey: selectedRelationTsKey,
  // 		// 	field,
  // 		// 	relationTableTsKey: relationTableTsName,
  // 		// 	isJson: true,
  // 		// 	selection: builtRelationJoin.selection,
  // 		// };
  // 		// selection.push(newSelectionItem);
  // 		// selectionForBuild.push(newSelectionItem);
  // 		tableFrom = is(builtTableFrom.sql, PgTable)
  // 			? builtTableFrom.sql
  // 			: new Subquery(builtTableFrom.sql, {}, tableAlias);
  // 	}
  // 	if (selectedColumns.length === 0 && selectedRelations.length === 0 && selectedExtras.length === 0) {
  // 		throw new DrizzleError(`No fields selected for table "${tableConfig.tsName}" ("${tableAlias}")`);
  // 	}
  // 	let selection: BuildRelationalQueryResult<PgTable, PgColumn>['selection'];
  // 	function prepareSelectedColumns() {
  // 		return selectedColumns.map((key) => ({
  // 			dbKey: tableConfig.columns[key]!.name,
  // 			tsKey: key,
  // 			field: tableConfig.columns[key] as PgColumn,
  // 			relationTableTsKey: undefined,
  // 			isJson: false,
  // 			selection: [],
  // 		}));
  // 	}
  // 	function prepareSelectedExtras() {
  // 		return selectedExtras.map((item) => ({
  // 			dbKey: item.value.fieldAlias,
  // 			tsKey: item.tsKey,
  // 			field: item.value,
  // 			relationTableTsKey: undefined,
  // 			isJson: false,
  // 			selection: [],
  // 		}));
  // 	}
  // 	if (isRoot) {
  // 		selection = [
  // 			...prepareSelectedColumns(),
  // 			...prepareSelectedExtras(),
  // 		];
  // 	}
  // 	if (hasUserDefinedWhere || orderBy.length > 0) {
  // 		tableFrom = new Subquery(
  // 			this.buildSelectQuery({
  // 				table: is(tableFrom, PgTable) ? aliasedTable(tableFrom, tableAlias) : tableFrom,
  // 				fields: {},
  // 				fieldsFlat: selectionForBuild.map(({ field }) => ({
  // 					path: [],
  // 					field: is(field, Column) ? aliasedTableColumn(field, tableAlias) : field,
  // 				})),
  // 				joins,
  // 				distinct,
  // 			}),
  // 			{},
  // 			tableAlias,
  // 		);
  // 		selectionForBuild = selection.map((item) =>
  // 			is(item.field, SQL.Aliased)
  // 				? { ...item, field: sql`${sql.identifier(tableAlias)}.${sql.identifier(item.field.fieldAlias)}` }
  // 				: item
  // 		);
  // 		joins = [];
  // 		distinct = undefined;
  // 	}
  // 	const result = this.buildSelectQuery({
  // 		table: is(tableFrom, PgTable) ? aliasedTable(tableFrom, tableAlias) : tableFrom,
  // 		fields: {},
  // 		fieldsFlat: selectionForBuild.map(({ field }) => ({
  // 			path: [],
  // 			field: is(field, Column) ? aliasedTableColumn(field, tableAlias) : field,
  // 		})),
  // 		where,
  // 		limit,
  // 		offset,
  // 		joins,
  // 		orderBy,
  // 		distinct,
  // 	});
  // 	return {
  // 		tableTsKey: tableConfig.tsName,
  // 		sql: result,
  // 		selection,
  // 	};
  // }
  buildRelationalQueryWithoutPK({ fullSchema, schema, tableNamesMap, table, tableConfig, queryConfig: config, tableAlias, nestedQueryRelation, joinOn }) {
    let selection = [];
    let limit, offset, orderBy = [], where;
    const joins = [];
    if (config === true) {
      const selectionEntries = Object.entries(tableConfig.columns);
      selection = selectionEntries.map(([key, value]) => ({
        dbKey: value.name,
        tsKey: key,
        field: aliasedTableColumn(value, tableAlias),
        relationTableTsKey: void 0,
        isJson: false,
        selection: []
      }));
    } else {
      const aliasedColumns = Object.fromEntries(Object.entries(tableConfig.columns).map(([key, value]) => [key, aliasedTableColumn(value, tableAlias)]));
      if (config.where) {
        const whereSql = typeof config.where === "function" ? config.where(aliasedColumns, getOperators()) : config.where;
        where = whereSql && mapColumnsInSQLToAlias(whereSql, tableAlias);
      }
      const fieldsSelection = [];
      let selectedColumns = [];
      if (config.columns) {
        let isIncludeMode = false;
        for (const [field, value] of Object.entries(config.columns)) {
          if (value === void 0) {
            continue;
          }
          if (field in tableConfig.columns) {
            if (!isIncludeMode && value === true) {
              isIncludeMode = true;
            }
            selectedColumns.push(field);
          }
        }
        if (selectedColumns.length > 0) {
          selectedColumns = isIncludeMode ? selectedColumns.filter((c) => config.columns?.[c] === true) : Object.keys(tableConfig.columns).filter((key) => !selectedColumns.includes(key));
        }
      } else {
        selectedColumns = Object.keys(tableConfig.columns);
      }
      for (const field of selectedColumns) {
        const column = tableConfig.columns[field];
        fieldsSelection.push({ tsKey: field, value: column });
      }
      let selectedRelations = [];
      if (config.with) {
        selectedRelations = Object.entries(config.with).filter((entry) => !!entry[1]).map(([tsKey, queryConfig]) => ({ tsKey, queryConfig, relation: tableConfig.relations[tsKey] }));
      }
      let extras;
      if (config.extras) {
        extras = typeof config.extras === "function" ? config.extras(aliasedColumns, { sql }) : config.extras;
        for (const [tsKey, value] of Object.entries(extras)) {
          fieldsSelection.push({
            tsKey,
            value: mapColumnsInAliasedSQLToAlias(value, tableAlias)
          });
        }
      }
      for (const { tsKey, value } of fieldsSelection) {
        selection.push({
          dbKey: is(value, SQL.Aliased) ? value.fieldAlias : tableConfig.columns[tsKey].name,
          tsKey,
          field: is(value, Column) ? aliasedTableColumn(value, tableAlias) : value,
          relationTableTsKey: void 0,
          isJson: false,
          selection: []
        });
      }
      let orderByOrig = typeof config.orderBy === "function" ? config.orderBy(aliasedColumns, getOrderByOperators()) : config.orderBy ?? [];
      if (!Array.isArray(orderByOrig)) {
        orderByOrig = [orderByOrig];
      }
      orderBy = orderByOrig.map((orderByValue) => {
        if (is(orderByValue, Column)) {
          return aliasedTableColumn(orderByValue, tableAlias);
        }
        return mapColumnsInSQLToAlias(orderByValue, tableAlias);
      });
      limit = config.limit;
      offset = config.offset;
      for (const { tsKey: selectedRelationTsKey, queryConfig: selectedRelationConfigValue, relation } of selectedRelations) {
        const normalizedRelation = normalizeRelation(schema, tableNamesMap, relation);
        const relationTableName = relation.referencedTable[Table.Symbol.Name];
        const relationTableTsName = tableNamesMap[relationTableName];
        const relationTableAlias = `${tableAlias}_${selectedRelationTsKey}`;
        const joinOn2 = and(...normalizedRelation.fields.map((field2, i) => eq(aliasedTableColumn(normalizedRelation.references[i], relationTableAlias), aliasedTableColumn(field2, tableAlias))));
        const builtRelation = this.buildRelationalQueryWithoutPK({
          fullSchema,
          schema,
          tableNamesMap,
          table: fullSchema[relationTableTsName],
          tableConfig: schema[relationTableTsName],
          queryConfig: is(relation, One) ? selectedRelationConfigValue === true ? { limit: 1 } : { ...selectedRelationConfigValue, limit: 1 } : selectedRelationConfigValue,
          tableAlias: relationTableAlias,
          joinOn: joinOn2,
          nestedQueryRelation: relation
        });
        const field = sql`${sql.identifier(relationTableAlias)}.${sql.identifier("data")}`.as(selectedRelationTsKey);
        joins.push({
          on: sql`true`,
          table: new Subquery(builtRelation.sql, {}, relationTableAlias),
          alias: relationTableAlias,
          joinType: "left",
          lateral: true
        });
        selection.push({
          dbKey: selectedRelationTsKey,
          tsKey: selectedRelationTsKey,
          field,
          relationTableTsKey: relationTableTsName,
          isJson: true,
          selection: builtRelation.selection
        });
      }
    }
    if (selection.length === 0) {
      throw new DrizzleError(`No fields selected for table "${tableConfig.tsName}" ("${tableAlias}")`);
    }
    let result;
    where = and(joinOn, where);
    if (nestedQueryRelation) {
      let field = sql`json_build_array(${sql.join(selection.map(({ field: field2, tsKey, isJson }) => isJson ? sql`${sql.identifier(`${tableAlias}_${tsKey}`)}.${sql.identifier("data")}` : is(field2, SQL.Aliased) ? field2.sql : field2), sql`, `)})`;
      if (is(nestedQueryRelation, Many)) {
        field = sql`coalesce(json_agg(${field}${orderBy.length > 0 ? sql` order by ${sql.join(orderBy, sql`, `)}` : void 0}), '[]'::json)`;
      }
      const nestedSelection = [{
        dbKey: "data",
        tsKey: "data",
        field: field.as("data"),
        isJson: true,
        relationTableTsKey: tableConfig.tsName,
        selection
      }];
      const needsSubquery = limit !== void 0 || offset !== void 0 || orderBy.length > 0;
      if (needsSubquery) {
        result = this.buildSelectQuery({
          table: aliasedTable(table, tableAlias),
          fields: {},
          fieldsFlat: [{
            path: [],
            field: sql.raw("*")
          }],
          where,
          limit,
          offset,
          orderBy
        });
        where = void 0;
        limit = void 0;
        offset = void 0;
        orderBy = [];
      } else {
        result = aliasedTable(table, tableAlias);
      }
      result = this.buildSelectQuery({
        table: is(result, PgTable) ? result : new Subquery(result, {}, tableAlias),
        fields: {},
        fieldsFlat: nestedSelection.map(({ field: field2 }) => ({
          path: [],
          field: is(field2, Column) ? aliasedTableColumn(field2, tableAlias) : field2
        })),
        joins,
        where,
        limit,
        offset,
        orderBy
      });
    } else {
      result = this.buildSelectQuery({
        table: aliasedTable(table, tableAlias),
        fields: {},
        fieldsFlat: selection.map(({ field }) => ({
          path: [],
          field: is(field, Column) ? aliasedTableColumn(field, tableAlias) : field
        })),
        joins,
        where,
        limit,
        offset,
        orderBy
      });
    }
    return {
      tableTsKey: tableConfig.tsName,
      sql: result,
      selection
    };
  }
};
_a47 = entityKind;
__publicField(PgDialect, _a47, "PgDialect");
var _a48;
var TypedQueryBuilder = class {
  /** @internal */
  getSelectedFields() {
    return this._.selectedFields;
  }
};
_a48 = entityKind;
__publicField(TypedQueryBuilder, _a48, "TypedQueryBuilder");
var _a49;
var PgSelectBuilder = class {
  fields;
  session;
  dialect;
  withList = [];
  distinct;
  constructor(config) {
    this.fields = config.fields;
    this.session = config.session;
    this.dialect = config.dialect;
    if (config.withList) {
      this.withList = config.withList;
    }
    this.distinct = config.distinct;
  }
  /**
   * Specify the table, subquery, or other target that you're
   * building a select query against.
   *
   * {@link https://www.postgresql.org/docs/current/sql-select.html#SQL-FROM|Postgres from documentation}
   */
  from(source) {
    const isPartialSelect = !!this.fields;
    let fields;
    if (this.fields) {
      fields = this.fields;
    } else if (is(source, Subquery)) {
      fields = Object.fromEntries(Object.keys(source[SubqueryConfig].selection).map((key) => [key, source[key]]));
    } else if (is(source, PgViewBase)) {
      fields = source[ViewBaseConfig].selectedFields;
    } else if (is(source, SQL)) {
      fields = {};
    } else {
      fields = getTableColumns(source);
    }
    return new PgSelect({
      table: source,
      fields,
      isPartialSelect,
      session: this.session,
      dialect: this.dialect,
      withList: this.withList,
      distinct: this.distinct
    });
  }
};
_a49 = entityKind;
__publicField(PgSelectBuilder, _a49, "PgSelectBuilder");
var _a50;
var PgSelectQueryBuilder = class extends TypedQueryBuilder {
  _;
  config;
  joinsNotNullableMap;
  tableName;
  isPartialSelect;
  session;
  dialect;
  constructor({ table, fields, isPartialSelect, session, dialect, withList, distinct }) {
    super();
    this.config = {
      withList,
      table,
      fields: { ...fields },
      distinct
    };
    this.isPartialSelect = isPartialSelect;
    this.session = session;
    this.dialect = dialect;
    this._ = {
      selectedFields: fields
    };
    this.tableName = getTableLikeName(table);
    this.joinsNotNullableMap = typeof this.tableName === "string" ? { [this.tableName]: true } : {};
  }
  createJoin(joinType) {
    return (table, on) => {
      const baseTableName = this.tableName;
      const tableName = getTableLikeName(table);
      if (typeof tableName === "string" && this.config.joins?.some((join) => join.alias === tableName)) {
        throw new Error(`Alias "${tableName}" is already used in this query`);
      }
      if (!this.isPartialSelect) {
        if (Object.keys(this.joinsNotNullableMap).length === 1 && typeof baseTableName === "string") {
          this.config.fields = {
            [baseTableName]: this.config.fields
          };
        }
        if (typeof tableName === "string" && !is(table, SQL)) {
          const selection = is(table, Subquery) ? table[SubqueryConfig].selection : is(table, View55) ? table[ViewBaseConfig].selectedFields : table[Table.Symbol.Columns];
          this.config.fields[tableName] = selection;
        }
      }
      if (typeof on === "function") {
        on = on(new Proxy(this.config.fields, new SelectionProxyHandler({ sqlAliasedBehavior: "sql", sqlBehavior: "sql" })));
      }
      if (!this.config.joins) {
        this.config.joins = [];
      }
      this.config.joins.push({ on, table, joinType, alias: tableName });
      if (typeof tableName === "string") {
        switch (joinType) {
          case "left": {
            this.joinsNotNullableMap[tableName] = false;
            break;
          }
          case "right": {
            this.joinsNotNullableMap = Object.fromEntries(Object.entries(this.joinsNotNullableMap).map(([key]) => [key, false]));
            this.joinsNotNullableMap[tableName] = true;
            break;
          }
          case "inner": {
            this.joinsNotNullableMap[tableName] = true;
            break;
          }
          case "full": {
            this.joinsNotNullableMap = Object.fromEntries(Object.entries(this.joinsNotNullableMap).map(([key]) => [key, false]));
            this.joinsNotNullableMap[tableName] = false;
            break;
          }
        }
      }
      return this;
    };
  }
  /**
   * For each row of the table, include
   * values from a matching row of the joined
   * table, if there is a matching row. If not,
   * all of the columns of the joined table
   * will be set to null.
   */
  leftJoin = this.createJoin("left");
  /**
   * Includes all of the rows of the joined table.
   * If there is no matching row in the main table,
   * all the columns of the main table will be
   * set to null.
   */
  rightJoin = this.createJoin("right");
  /**
   * This is the default type of join.
   *
   * For each row of the table, the joined table
   * needs to have a matching row, or it will
   * be excluded from results.
   */
  innerJoin = this.createJoin("inner");
  /**
   * Rows from both the main & joined are included,
   * regardless of whether or not they have matching
   * rows in the other table.
   */
  fullJoin = this.createJoin("full");
  /**
   * Specify a condition to narrow the result set. Multiple
   * conditions can be combined with the `and` and `or`
   * functions.
   *
   * ## Examples
   *
   * ```ts
   * // Find cars made in the year 2000
   * db.select().from(cars).where(eq(cars.year, 2000));
   * ```
   */
  where(where) {
    if (typeof where === "function") {
      where = where(new Proxy(this.config.fields, new SelectionProxyHandler({ sqlAliasedBehavior: "sql", sqlBehavior: "sql" })));
    }
    this.config.where = where;
    return this;
  }
  /**
   * Sets the HAVING clause of this query, which often
   * used with GROUP BY and filters rows after they've been
   * grouped together and combined.
   *
   * {@link https://www.postgresql.org/docs/current/sql-select.html#SQL-HAVING|Postgres having clause documentation}
   */
  having(having) {
    if (typeof having === "function") {
      having = having(new Proxy(this.config.fields, new SelectionProxyHandler({ sqlAliasedBehavior: "sql", sqlBehavior: "sql" })));
    }
    this.config.having = having;
    return this;
  }
  groupBy(...columns) {
    if (typeof columns[0] === "function") {
      const groupBy = columns[0](new Proxy(this.config.fields, new SelectionProxyHandler({ sqlAliasedBehavior: "alias", sqlBehavior: "sql" })));
      this.config.groupBy = Array.isArray(groupBy) ? groupBy : [groupBy];
    } else {
      this.config.groupBy = columns;
    }
    return this;
  }
  orderBy(...columns) {
    if (typeof columns[0] === "function") {
      const orderBy = columns[0](new Proxy(this.config.fields, new SelectionProxyHandler({ sqlAliasedBehavior: "alias", sqlBehavior: "sql" })));
      this.config.orderBy = Array.isArray(orderBy) ? orderBy : [orderBy];
    } else {
      this.config.orderBy = columns;
    }
    return this;
  }
  /**
   * Set the maximum number of rows that will be
   * returned by this query.
   *
   * ## Examples
   *
   * ```ts
   * // Get the first 10 people from this query.
   * db.select().from(people).limit(10);
   * ```
   *
   * {@link https://www.postgresql.org/docs/current/sql-select.html#SQL-LIMIT|Postgres LIMIT documentation}
   */
  limit(limit) {
    this.config.limit = limit;
    return this;
  }
  /**
   * Skip a number of rows when returning results
   * from this query.
   *
   * ## Examples
   *
   * ```ts
   * // Get the 10th-20th people from this query.
   * db.select().from(people).offset(10).limit(10);
   * ```
   */
  offset(offset) {
    this.config.offset = offset;
    return this;
  }
  /**
   * The FOR clause specifies a lock strength for this query
   * that controls how strictly it acquires exclusive access to
   * the rows being queried.
   *
   * {@link https://www.postgresql.org/docs/current/sql-select.html#SQL-FOR-UPDATE-SHARE|Postgres locking clause documentation}
   */
  for(strength, config = {}) {
    if (!this.config.lockingClauses) {
      this.config.lockingClauses = [];
    }
    this.config.lockingClauses.push({ strength, config });
    return this;
  }
  /** @internal */
  getSQL() {
    return this.dialect.buildSelectQuery(this.config);
  }
  toSQL() {
    const { typings: _typings, ...rest } = this.dialect.sqlToQuery(this.getSQL());
    return rest;
  }
  as(alias) {
    return new Proxy(new Subquery(this.getSQL(), this.config.fields, alias), new SelectionProxyHandler({ alias, sqlAliasedBehavior: "alias", sqlBehavior: "error" }));
  }
};
_a50 = entityKind;
__publicField(PgSelectQueryBuilder, _a50, "PgSelectQueryBuilder");
var _a51;
var PgSelect = class extends PgSelectQueryBuilder {
  _prepare(name) {
    const { session, config, dialect, joinsNotNullableMap } = this;
    if (!session) {
      throw new Error("Cannot execute a query on a query builder. Please use a database instance instead.");
    }
    return tracer.startActiveSpan("drizzle.prepareQuery", () => {
      const fieldsList = orderSelectedFields(config.fields);
      const query = session.prepareQuery(dialect.sqlToQuery(this.getSQL()), fieldsList, name);
      query.joinsNotNullableMap = joinsNotNullableMap;
      return query;
    });
  }
  /**
   * Create a prepared statement for this query. This allows
   * the database to remember this query for the given session
   * and call it by name, rather than specifying the full query.
   *
   * {@link https://www.postgresql.org/docs/current/sql-prepare.html|Postgres prepare documentation}
   */
  prepare(name) {
    return this._prepare(name);
  }
  execute = (placeholderValues) => {
    return tracer.startActiveSpan("drizzle.operation", () => {
      return this._prepare().execute(placeholderValues);
    });
  };
};
_a51 = entityKind;
__publicField(PgSelect, _a51, "PgSelect");
applyMixins(PgSelect, [QueryPromise]);
var _a52;
var QueryBuilder = class {
  dialect;
  $with(alias) {
    const queryBuilder = this;
    return {
      as(qb) {
        if (typeof qb === "function") {
          qb = qb(queryBuilder);
        }
        return new Proxy(new WithSubquery(qb.getSQL(), qb.getSelectedFields(), alias, true), new SelectionProxyHandler({ alias, sqlAliasedBehavior: "alias", sqlBehavior: "error" }));
      }
    };
  }
  with(...queries) {
    const self = this;
    function select(fields) {
      return new PgSelectBuilder({
        fields: fields ?? void 0,
        session: void 0,
        dialect: self.getDialect(),
        withList: queries
      });
    }
    function selectDistinct(fields) {
      return new PgSelectBuilder({
        fields: fields ?? void 0,
        session: void 0,
        dialect: self.getDialect(),
        distinct: true
      });
    }
    function selectDistinctOn(on, fields) {
      return new PgSelectBuilder({
        fields: fields ?? void 0,
        session: void 0,
        dialect: self.getDialect(),
        distinct: { on }
      });
    }
    return { select, selectDistinct, selectDistinctOn };
  }
  select(fields) {
    return new PgSelectBuilder({
      fields: fields ?? void 0,
      session: void 0,
      dialect: this.getDialect()
    });
  }
  selectDistinct(fields) {
    return new PgSelectBuilder({
      fields: fields ?? void 0,
      session: void 0,
      dialect: this.getDialect(),
      distinct: true
    });
  }
  selectDistinctOn(on, fields) {
    return new PgSelectBuilder({
      fields: fields ?? void 0,
      session: void 0,
      dialect: this.getDialect(),
      distinct: { on }
    });
  }
  // Lazy load dialect to avoid circular dependency
  getDialect() {
    if (!this.dialect) {
      this.dialect = new PgDialect();
    }
    return this.dialect;
  }
};
_a52 = entityKind;
__publicField(QueryBuilder, _a52, "PgQueryBuilder");
var _a53;
var DefaultViewBuilderCore = class {
  name;
  schema;
  constructor(name, schema) {
    this.name = name;
    this.schema = schema;
  }
  config = {};
  with(config) {
    this.config.with = config;
    return this;
  }
};
_a53 = entityKind;
__publicField(DefaultViewBuilderCore, _a53, "PgDefaultViewBuilderCore");
var _a54;
var ViewBuilder = class extends DefaultViewBuilderCore {
  as(qb) {
    if (typeof qb === "function") {
      qb = qb(new QueryBuilder());
    }
    const selectionProxy = new SelectionProxyHandler({
      alias: this.name,
      sqlBehavior: "error",
      sqlAliasedBehavior: "alias",
      replaceOriginalName: true
    });
    const aliasedSelection = new Proxy(qb.getSelectedFields(), selectionProxy);
    return new Proxy(new PgView({
      pgConfig: this.config,
      config: {
        name: this.name,
        schema: this.schema,
        selectedFields: aliasedSelection,
        query: qb.getSQL().inlineParams()
      }
    }), selectionProxy);
  }
};
_a54 = entityKind;
__publicField(ViewBuilder, _a54, "PgViewBuilder");
var _a55;
var ManualViewBuilder = class extends DefaultViewBuilderCore {
  columns;
  constructor(name, columns, schema) {
    super(name, schema);
    this.columns = getTableColumns(pgTable(name, columns));
  }
  existing() {
    return new Proxy(new PgView({
      pgConfig: void 0,
      config: {
        name: this.name,
        schema: this.schema,
        selectedFields: this.columns,
        query: void 0
      }
    }), new SelectionProxyHandler({
      alias: this.name,
      sqlBehavior: "error",
      sqlAliasedBehavior: "alias",
      replaceOriginalName: true
    }));
  }
  as(query) {
    return new Proxy(new PgView({
      pgConfig: this.config,
      config: {
        name: this.name,
        schema: this.schema,
        selectedFields: this.columns,
        query: query.inlineParams()
      }
    }), new SelectionProxyHandler({
      alias: this.name,
      sqlBehavior: "error",
      sqlAliasedBehavior: "alias",
      replaceOriginalName: true
    }));
  }
};
_a55 = entityKind;
__publicField(ManualViewBuilder, _a55, "PgManualViewBuilder");
var _a56;
var MaterializedViewBuilderCore = class {
  name;
  schema;
  constructor(name, schema) {
    this.name = name;
    this.schema = schema;
  }
  config = {};
  using(using) {
    this.config.using = using;
    return this;
  }
  with(config) {
    this.config.with = config;
    return this;
  }
  tablespace(tablespace) {
    this.config.tablespace = tablespace;
    return this;
  }
  withNoData() {
    this.config.withNoData = true;
    return this;
  }
};
_a56 = entityKind;
__publicField(MaterializedViewBuilderCore, _a56, "PgMaterializedViewBuilderCore");
var _a57;
var MaterializedViewBuilder = class extends MaterializedViewBuilderCore {
  as(qb) {
    if (typeof qb === "function") {
      qb = qb(new QueryBuilder());
    }
    const selectionProxy = new SelectionProxyHandler({
      alias: this.name,
      sqlBehavior: "error",
      sqlAliasedBehavior: "alias",
      replaceOriginalName: true
    });
    const aliasedSelection = new Proxy(qb.getSelectedFields(), selectionProxy);
    return new Proxy(new PgMaterializedView({
      pgConfig: {
        with: this.config.with,
        using: this.config.using,
        tablespace: this.config.tablespace,
        withNoData: this.config.withNoData
      },
      config: {
        name: this.name,
        schema: this.schema,
        selectedFields: aliasedSelection,
        query: qb.getSQL().inlineParams()
      }
    }), selectionProxy);
  }
};
_a57 = entityKind;
__publicField(MaterializedViewBuilder, _a57, "PgMaterializedViewBuilder");
var _a58;
var ManualMaterializedViewBuilder = class extends MaterializedViewBuilderCore {
  columns;
  constructor(name, columns, schema) {
    super(name, schema);
    this.columns = getTableColumns(pgTable(name, columns));
  }
  existing() {
    return new Proxy(new PgMaterializedView({
      pgConfig: void 0,
      config: {
        name: this.name,
        schema: this.schema,
        selectedFields: this.columns,
        query: void 0
      }
    }), new SelectionProxyHandler({
      alias: this.name,
      sqlBehavior: "error",
      sqlAliasedBehavior: "alias",
      replaceOriginalName: true
    }));
  }
  as(query) {
    return new Proxy(new PgMaterializedView({
      pgConfig: void 0,
      config: {
        name: this.name,
        schema: this.schema,
        selectedFields: this.columns,
        query: query.inlineParams()
      }
    }), new SelectionProxyHandler({
      alias: this.name,
      sqlBehavior: "error",
      sqlAliasedBehavior: "alias",
      replaceOriginalName: true
    }));
  }
};
_a58 = entityKind;
__publicField(ManualMaterializedViewBuilder, _a58, "PgManualMaterializedViewBuilder");
var _a59;
var PgViewBase = class extends View55 {
};
_a59 = entityKind;
__publicField(PgViewBase, _a59, "PgViewBase");
var PgViewConfig = Symbol.for("drizzle:PgViewConfig");
var _a60;
var PgView = class extends PgViewBase {
  [(_a60 = entityKind, PgViewConfig)];
  constructor({ pgConfig, config }) {
    super(config);
    if (pgConfig) {
      this[PgViewConfig] = {
        with: pgConfig.with
      };
    }
  }
};
__publicField(PgView, _a60, "PgView");
var PgMaterializedViewConfig = Symbol.for("drizzle:PgMaterializedViewConfig");
var _a61;
var PgMaterializedView = class extends PgViewBase {
  [(_a61 = entityKind, PgMaterializedViewConfig)];
  constructor({ pgConfig, config }) {
    super(config);
    this[PgMaterializedViewConfig] = {
      with: pgConfig?.with,
      using: pgConfig?.using,
      tablespace: pgConfig?.tablespace,
      withNoData: pgConfig?.withNoData
    };
  }
};
__publicField(PgMaterializedView, _a61, "PgMaterializedView");
function pgViewWithSchema(name, selection, schema) {
  if (selection) {
    return new ManualViewBuilder(name, selection, schema);
  }
  return new ViewBuilder(name, schema);
}
function pgMaterializedViewWithSchema(name, selection, schema) {
  if (selection) {
    return new ManualMaterializedViewBuilder(name, selection, schema);
  }
  return new MaterializedViewBuilder(name, schema);
}
var _a62;
var Relation = class {
  sourceTable;
  referencedTable;
  relationName;
  referencedTableName;
  fieldName;
  constructor(sourceTable, referencedTable, relationName) {
    this.sourceTable = sourceTable;
    this.referencedTable = referencedTable;
    this.relationName = relationName;
    this.referencedTableName = referencedTable[Table.Symbol.Name];
  }
};
_a62 = entityKind;
__publicField(Relation, _a62, "Relation");
var _a63;
var Relations = class {
  table;
  config;
  constructor(table, config) {
    this.table = table;
    this.config = config;
  }
};
_a63 = entityKind;
__publicField(Relations, _a63, "Relations");
var _a64;
var _One = class extends Relation {
  config;
  isNullable;
  constructor(sourceTable, referencedTable, config, isNullable) {
    super(sourceTable, referencedTable, config?.relationName);
    this.config = config;
    this.isNullable = isNullable;
  }
  withFieldName(fieldName) {
    const relation = new _One(this.sourceTable, this.referencedTable, this.config, this.isNullable);
    relation.fieldName = fieldName;
    return relation;
  }
};
var One = _One;
_a64 = entityKind;
__publicField(One, _a64, "One");
var _a65;
var _Many = class extends Relation {
  config;
  constructor(sourceTable, referencedTable, config) {
    super(sourceTable, referencedTable, config?.relationName);
    this.config = config;
  }
  withFieldName(fieldName) {
    const relation = new _Many(this.sourceTable, this.referencedTable, this.config);
    relation.fieldName = fieldName;
    return relation;
  }
};
var Many = _Many;
_a65 = entityKind;
__publicField(Many, _a65, "Many");
function getOperators() {
  return {
    and,
    between,
    eq,
    exists,
    gt,
    gte,
    ilike,
    inArray,
    isNull,
    isNotNull,
    like,
    lt,
    lte,
    ne,
    not,
    notBetween,
    notExists,
    notLike,
    notIlike,
    notInArray,
    or,
    sql
  };
}
function getOrderByOperators() {
  return {
    sql,
    asc,
    desc
  };
}
function normalizeRelation(schema, tableNamesMap, relation) {
  if (is(relation, One) && relation.config) {
    return {
      fields: relation.config.fields,
      references: relation.config.references
    };
  }
  const referencedTableTsName = tableNamesMap[relation.referencedTable[Table.Symbol.Name]];
  if (!referencedTableTsName) {
    throw new Error(`Table "${relation.referencedTable[Table.Symbol.Name]}" not found in schema`);
  }
  const referencedTableConfig = schema[referencedTableTsName];
  if (!referencedTableConfig) {
    throw new Error(`Table "${referencedTableTsName}" not found in schema`);
  }
  const sourceTable = relation.sourceTable;
  const sourceTableTsName = tableNamesMap[sourceTable[Table.Symbol.Name]];
  if (!sourceTableTsName) {
    throw new Error(`Table "${sourceTable[Table.Symbol.Name]}" not found in schema`);
  }
  const reverseRelations = [];
  for (const referencedTableRelation of Object.values(referencedTableConfig.relations)) {
    if (relation.relationName && relation !== referencedTableRelation && referencedTableRelation.relationName === relation.relationName || !relation.relationName && referencedTableRelation.referencedTable === relation.sourceTable) {
      reverseRelations.push(referencedTableRelation);
    }
  }
  if (reverseRelations.length > 1) {
    throw relation.relationName ? new Error(`There are multiple relations with name "${relation.relationName}" in table "${referencedTableTsName}"`) : new Error(`There are multiple relations between "${referencedTableTsName}" and "${relation.sourceTable[Table.Symbol.Name]}". Please specify relation name`);
  }
  if (reverseRelations[0] && is(reverseRelations[0], One) && reverseRelations[0].config) {
    return {
      fields: reverseRelations[0].config.references,
      references: reverseRelations[0].config.fields
    };
  }
  throw new Error(`There is not enough information to infer relation "${sourceTableTsName}.${relation.fieldName}"`);
}
function bindIfParam(value, column) {
  if (isDriverValueEncoder(column) && !isSQLWrapper(value) && !is(value, Param) && !is(value, Placeholder) && !is(value, Column) && !is(value, Table) && !is(value, View55)) {
    return new Param(value, column);
  }
  return value;
}
var eq = (left, right) => {
  return sql`${left} = ${bindIfParam(right, left)}`;
};
var ne = (left, right) => {
  return sql`${left} <> ${bindIfParam(right, left)}`;
};
function and(...unfilteredConditions) {
  const conditions = unfilteredConditions.filter((c) => c !== void 0);
  if (conditions.length === 0) {
    return void 0;
  }
  if (conditions.length === 1) {
    return new SQL(conditions);
  }
  return new SQL([
    new StringChunk("("),
    sql.join(conditions, new StringChunk(" and ")),
    new StringChunk(")")
  ]);
}
function or(...unfilteredConditions) {
  const conditions = unfilteredConditions.filter((c) => c !== void 0);
  if (conditions.length === 0) {
    return void 0;
  }
  if (conditions.length === 1) {
    return new SQL(conditions);
  }
  return new SQL([
    new StringChunk("("),
    sql.join(conditions, new StringChunk(" or ")),
    new StringChunk(")")
  ]);
}
function not(condition) {
  return sql`not ${condition}`;
}
var gt = (left, right) => {
  return sql`${left} > ${bindIfParam(right, left)}`;
};
var gte = (left, right) => {
  return sql`${left} >= ${bindIfParam(right, left)}`;
};
var lt = (left, right) => {
  return sql`${left} < ${bindIfParam(right, left)}`;
};
var lte = (left, right) => {
  return sql`${left} <= ${bindIfParam(right, left)}`;
};
function inArray(column, values) {
  if (Array.isArray(values)) {
    if (values.length === 0) {
      throw new Error("inArray requires at least one value");
    }
    return sql`${column} in ${values.map((v) => bindIfParam(v, column))}`;
  }
  return sql`${column} in ${bindIfParam(values, column)}`;
}
function notInArray(column, values) {
  if (Array.isArray(values)) {
    if (values.length === 0) {
      throw new Error("notInArray requires at least one value");
    }
    return sql`${column} not in ${values.map((v) => bindIfParam(v, column))}`;
  }
  return sql`${column} not in ${bindIfParam(values, column)}`;
}
function isNull(value) {
  return sql`${value} is null`;
}
function isNotNull(value) {
  return sql`${value} is not null`;
}
function exists(subquery) {
  return sql`exists (${subquery})`;
}
function notExists(subquery) {
  return sql`not exists (${subquery})`;
}
function between(column, min, max) {
  return sql`${column} between ${bindIfParam(min, column)} and ${bindIfParam(max, column)}`;
}
function notBetween(column, min, max) {
  return sql`${column} not between ${bindIfParam(min, column)} and ${bindIfParam(max, column)}`;
}
function like(column, value) {
  return sql`${column} like ${value}`;
}
function notLike(column, value) {
  return sql`${column} not like ${value}`;
}
function ilike(column, value) {
  return sql`${column} ilike ${value}`;
}
function notIlike(column, value) {
  return sql`${column} not ilike ${value}`;
}
function asc(column) {
  return sql`${column} asc`;
}
function desc(column) {
  return sql`${column} desc`;
}
var _a66;
var FakePrimitiveParam = class {
};
_a66 = entityKind;
__publicField(FakePrimitiveParam, _a66, "FakePrimitiveParam");
function isSQLWrapper(value) {
  return typeof value === "object" && value !== null && "getSQL" in value && typeof value.getSQL === "function";
}
function mergeQueries(queries) {
  const result = { sql: "", params: [] };
  for (const query of queries) {
    result.sql += query.sql;
    result.params.push(...query.params);
    if (query.typings?.length) {
      if (!result.typings) {
        result.typings = [];
      }
      result.typings.push(...query.typings);
    }
  }
  return result;
}
var _a67;
var StringChunk = class {
  value;
  constructor(value) {
    this.value = Array.isArray(value) ? value : [value];
  }
  getSQL() {
    return new SQL([this]);
  }
};
_a67 = entityKind;
__publicField(StringChunk, _a67, "StringChunk");
var _a68;
var _SQL = class {
  queryChunks;
  /** @internal */
  decoder = noopDecoder;
  shouldInlineParams = false;
  constructor(queryChunks) {
    this.queryChunks = queryChunks;
  }
  append(query) {
    this.queryChunks.push(...query.queryChunks);
    return this;
  }
  toQuery(config) {
    return tracer.startActiveSpan("drizzle.buildSQL", (span) => {
      const query = this.buildQueryFromSourceParams(this.queryChunks, config);
      span?.setAttributes({
        "drizzle.query.text": query.sql,
        "drizzle.query.params": JSON.stringify(query.params)
      });
      return query;
    });
  }
  buildQueryFromSourceParams(chunks, _config) {
    const config = Object.assign({}, _config, {
      inlineParams: _config.inlineParams || this.shouldInlineParams,
      paramStartIndex: _config.paramStartIndex || { value: 0 }
    });
    const { escapeName, escapeParam, prepareTyping, inlineParams, paramStartIndex } = config;
    return mergeQueries(chunks.map((chunk) => {
      if (is(chunk, StringChunk)) {
        return { sql: chunk.value.join(""), params: [] };
      }
      if (is(chunk, Name)) {
        return { sql: escapeName(chunk.value), params: [] };
      }
      if (chunk === void 0) {
        return { sql: "", params: [] };
      }
      if (Array.isArray(chunk)) {
        const result = [new StringChunk("(")];
        for (const [i, p] of chunk.entries()) {
          result.push(p);
          if (i < chunk.length - 1) {
            result.push(new StringChunk(", "));
          }
        }
        result.push(new StringChunk(")"));
        return this.buildQueryFromSourceParams(result, config);
      }
      if (is(chunk, _SQL)) {
        return this.buildQueryFromSourceParams(chunk.queryChunks, {
          ...config,
          inlineParams: inlineParams || chunk.shouldInlineParams
        });
      }
      if (is(chunk, Table)) {
        const schemaName = chunk[Table.Symbol.Schema];
        const tableName = chunk[Table.Symbol.Name];
        return {
          sql: schemaName === void 0 ? escapeName(tableName) : escapeName(schemaName) + "." + escapeName(tableName),
          params: []
        };
      }
      if (is(chunk, Column)) {
        return { sql: escapeName(chunk.table[Table.Symbol.Name]) + "." + escapeName(chunk.name), params: [] };
      }
      if (is(chunk, View55)) {
        const schemaName = chunk[ViewBaseConfig].schema;
        const viewName = chunk[ViewBaseConfig].name;
        return {
          sql: schemaName === void 0 ? escapeName(viewName) : escapeName(schemaName) + "." + escapeName(viewName),
          params: []
        };
      }
      if (is(chunk, Param)) {
        const mappedValue = chunk.value === null ? null : chunk.encoder.mapToDriverValue(chunk.value);
        if (is(mappedValue, _SQL)) {
          return this.buildQueryFromSourceParams([mappedValue], config);
        }
        if (inlineParams) {
          return { sql: this.mapInlineParam(mappedValue, config), params: [] };
        }
        let typings;
        if (prepareTyping !== void 0) {
          typings = [prepareTyping(chunk.encoder)];
        }
        return { sql: escapeParam(paramStartIndex.value++, mappedValue), params: [mappedValue], typings };
      }
      if (is(chunk, Placeholder)) {
        return { sql: escapeParam(paramStartIndex.value++, chunk), params: [chunk] };
      }
      if (is(chunk, _SQL.Aliased) && chunk.fieldAlias !== void 0) {
        return { sql: escapeName(chunk.fieldAlias), params: [] };
      }
      if (is(chunk, Subquery)) {
        if (chunk[SubqueryConfig].isWith) {
          return { sql: escapeName(chunk[SubqueryConfig].alias), params: [] };
        }
        return this.buildQueryFromSourceParams([
          new StringChunk("("),
          chunk[SubqueryConfig].sql,
          new StringChunk(") "),
          new Name(chunk[SubqueryConfig].alias)
        ], config);
      }
      if (isSQLWrapper(chunk)) {
        return this.buildQueryFromSourceParams([
          new StringChunk("("),
          chunk.getSQL(),
          new StringChunk(")")
        ], config);
      }
      if (is(chunk, Relation)) {
        return this.buildQueryFromSourceParams([
          chunk.sourceTable,
          new StringChunk("."),
          sql.identifier(chunk.fieldName)
        ], config);
      }
      if (inlineParams) {
        return { sql: this.mapInlineParam(chunk, config), params: [] };
      }
      return { sql: escapeParam(paramStartIndex.value++, chunk), params: [chunk] };
    }));
  }
  mapInlineParam(chunk, { escapeString }) {
    if (chunk === null) {
      return "null";
    }
    if (typeof chunk === "number" || typeof chunk === "boolean") {
      return chunk.toString();
    }
    if (typeof chunk === "string") {
      return escapeString(chunk);
    }
    if (typeof chunk === "object") {
      const mappedValueAsString = chunk.toString();
      if (mappedValueAsString === "[object Object]") {
        return escapeString(JSON.stringify(chunk));
      }
      return escapeString(mappedValueAsString);
    }
    throw new Error("Unexpected param value: " + chunk);
  }
  getSQL() {
    return this;
  }
  as(alias) {
    if (alias === void 0) {
      return this;
    }
    return new _SQL.Aliased(this, alias);
  }
  mapWith(decoder) {
    this.decoder = typeof decoder === "function" ? { mapFromDriverValue: decoder } : decoder;
    return this;
  }
  inlineParams() {
    this.shouldInlineParams = true;
    return this;
  }
};
var SQL = _SQL;
_a68 = entityKind;
__publicField(SQL, _a68, "SQL");
var _a69;
var Name = class {
  value;
  brand;
  constructor(value) {
    this.value = value;
  }
  getSQL() {
    return new SQL([this]);
  }
};
_a69 = entityKind;
__publicField(Name, _a69, "Name");
function isDriverValueEncoder(value) {
  return typeof value === "object" && value !== null && "mapToDriverValue" in value && typeof value.mapToDriverValue === "function";
}
var noopDecoder = {
  mapFromDriverValue: (value) => value
};
var noopEncoder = {
  mapToDriverValue: (value) => value
};
var noopMapper = {
  ...noopDecoder,
  ...noopEncoder
};
var _a70;
var Param = class {
  value;
  encoder;
  brand;
  /**
   * @param value - Parameter value
   * @param encoder - Encoder to convert the value to a driver parameter
   */
  constructor(value, encoder = noopEncoder) {
    this.value = value;
    this.encoder = encoder;
  }
  getSQL() {
    return new SQL([this]);
  }
};
_a70 = entityKind;
__publicField(Param, _a70, "Param");
function sql(strings, ...params) {
  const queryChunks = [];
  if (params.length > 0 || strings.length > 0 && strings[0] !== "") {
    queryChunks.push(new StringChunk(strings[0]));
  }
  for (const [paramIndex, param] of params.entries()) {
    queryChunks.push(param, new StringChunk(strings[paramIndex + 1]));
  }
  return new SQL(queryChunks);
}
(function(sql2) {
  function empty() {
    return new SQL([]);
  }
  sql2.empty = empty;
  function fromList(list) {
    return new SQL(list);
  }
  sql2.fromList = fromList;
  function raw(str) {
    return new SQL([new StringChunk(str)]);
  }
  sql2.raw = raw;
  function join(chunks, separator) {
    const result = [];
    for (const [i, chunk] of chunks.entries()) {
      if (i > 0 && separator !== void 0) {
        result.push(separator);
      }
      result.push(chunk);
    }
    return new SQL(result);
  }
  sql2.join = join;
  function identifier(value) {
    return new Name(value);
  }
  sql2.identifier = identifier;
  function placeholder(name) {
    return new Placeholder(name);
  }
  sql2.placeholder = placeholder;
  function param(value, encoder) {
    return new Param(value, encoder);
  }
  sql2.param = param;
})(sql || (sql = {}));
(function(SQL2) {
  class Aliased {
    sql;
    fieldAlias;
    static [entityKind] = "SQL.Aliased";
    /** @internal */
    isSelectionField = false;
    constructor(sql2, fieldAlias) {
      this.sql = sql2;
      this.fieldAlias = fieldAlias;
    }
    getSQL() {
      return this.sql;
    }
    /** @internal */
    clone() {
      return new Aliased(this.sql, this.fieldAlias);
    }
  }
  SQL2.Aliased = Aliased;
})(SQL || (SQL = {}));
var _a71;
var Placeholder = class {
  name;
  constructor(name) {
    this.name = name;
  }
  getSQL() {
    return new SQL([this]);
  }
};
_a71 = entityKind;
__publicField(Placeholder, _a71, "Placeholder");
Column.prototype.getSQL = function() {
  return new SQL([this]);
};
var _a72;
var ColumnAliasProxyHandler = class {
  table;
  constructor(table) {
    this.table = table;
  }
  get(columnObj, prop) {
    if (prop === "table") {
      return this.table;
    }
    return columnObj[prop];
  }
};
_a72 = entityKind;
__publicField(ColumnAliasProxyHandler, _a72, "ColumnAliasProxyHandler");
var _a73;
var TableAliasProxyHandler = class {
  alias;
  replaceOriginalName;
  constructor(alias, replaceOriginalName) {
    this.alias = alias;
    this.replaceOriginalName = replaceOriginalName;
  }
  get(target, prop) {
    if (prop === Table.Symbol.IsAlias) {
      return true;
    }
    if (prop === Table.Symbol.Name) {
      return this.alias;
    }
    if (this.replaceOriginalName && prop === Table.Symbol.OriginalName) {
      return this.alias;
    }
    if (prop === ViewBaseConfig) {
      return {
        ...target[ViewBaseConfig],
        name: this.alias,
        isAlias: true
      };
    }
    if (prop === Table.Symbol.Columns) {
      const columns = target[Table.Symbol.Columns];
      if (!columns) {
        return columns;
      }
      const proxiedColumns = {};
      Object.keys(columns).map((key) => {
        proxiedColumns[key] = new Proxy(columns[key], new ColumnAliasProxyHandler(new Proxy(target, this)));
      });
      return proxiedColumns;
    }
    const value = target[prop];
    if (is(value, Column)) {
      return new Proxy(value, new ColumnAliasProxyHandler(new Proxy(target, this)));
    }
    return value;
  }
};
_a73 = entityKind;
__publicField(TableAliasProxyHandler, _a73, "TableAliasProxyHandler");
var _a74;
var RelationTableAliasProxyHandler = class {
  alias;
  constructor(alias) {
    this.alias = alias;
  }
  get(target, prop) {
    if (prop === "sourceTable") {
      return aliasedTable(target.sourceTable, this.alias);
    }
    return target[prop];
  }
};
_a74 = entityKind;
__publicField(RelationTableAliasProxyHandler, _a74, "RelationTableAliasProxyHandler");
function aliasedTable(table, tableAlias) {
  return new Proxy(table, new TableAliasProxyHandler(tableAlias, false));
}
function aliasedTableColumn(column, tableAlias) {
  return new Proxy(column, new ColumnAliasProxyHandler(new Proxy(column.table, new TableAliasProxyHandler(tableAlias, false))));
}
function mapColumnsInAliasedSQLToAlias(query, alias) {
  return new SQL.Aliased(mapColumnsInSQLToAlias(query.sql, alias), query.fieldAlias);
}
function mapColumnsInSQLToAlias(query, alias) {
  return sql.join(query.queryChunks.map((c) => {
    if (is(c, Column)) {
      return aliasedTableColumn(c, alias);
    }
    if (is(c, SQL)) {
      return mapColumnsInSQLToAlias(c, alias);
    }
    if (is(c, SQL.Aliased)) {
      return mapColumnsInAliasedSQLToAlias(c, alias);
    }
    return c;
  }));
}

// ../../node_modules/drizzle-orm/pg-core/index.mjs
var _a75;
var PgBigInt53Builder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "number", "PgBigInt53");
  }
  /** @internal */
  build(table) {
    return new PgBigInt53(table, this.config);
  }
};
_a75 = entityKind;
__publicField(PgBigInt53Builder, _a75, "PgBigInt53Builder");
var _a76;
var PgBigInt53 = class extends PgColumn {
  getSQLType() {
    return "bigint";
  }
  mapFromDriverValue(value) {
    if (typeof value === "number") {
      return value;
    }
    return Number(value);
  }
};
_a76 = entityKind;
__publicField(PgBigInt53, _a76, "PgBigInt53");
var _a77;
var PgBigInt64Builder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "bigint", "PgBigInt64");
  }
  /** @internal */
  build(table) {
    return new PgBigInt64(table, this.config);
  }
};
_a77 = entityKind;
__publicField(PgBigInt64Builder, _a77, "PgBigInt64Builder");
var _a78;
var PgBigInt64 = class extends PgColumn {
  getSQLType() {
    return "bigint";
  }
  // eslint-disable-next-line unicorn/prefer-native-coercion-functions
  mapFromDriverValue(value) {
    return BigInt(value);
  }
};
_a78 = entityKind;
__publicField(PgBigInt64, _a78, "PgBigInt64");
var _a79;
var PgBigSerial53Builder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "number", "PgBigSerial53");
    this.config.hasDefault = true;
    this.config.notNull = true;
  }
  /** @internal */
  build(table) {
    return new PgBigSerial53(table, this.config);
  }
};
_a79 = entityKind;
__publicField(PgBigSerial53Builder, _a79, "PgBigSerial53Builder");
var _a80;
var PgBigSerial53 = class extends PgColumn {
  getSQLType() {
    return "bigserial";
  }
  mapFromDriverValue(value) {
    if (typeof value === "number") {
      return value;
    }
    return Number(value);
  }
};
_a80 = entityKind;
__publicField(PgBigSerial53, _a80, "PgBigSerial53");
var _a81;
var PgBigSerial64Builder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "bigint", "PgBigSerial64");
    this.config.hasDefault = true;
  }
  /** @internal */
  build(table) {
    return new PgBigSerial64(table, this.config);
  }
};
_a81 = entityKind;
__publicField(PgBigSerial64Builder, _a81, "PgBigSerial64Builder");
var _a82;
var PgBigSerial64 = class extends PgColumn {
  getSQLType() {
    return "bigserial";
  }
  // eslint-disable-next-line unicorn/prefer-native-coercion-functions
  mapFromDriverValue(value) {
    return BigInt(value);
  }
};
_a82 = entityKind;
__publicField(PgBigSerial64, _a82, "PgBigSerial64");
var _a83;
var PgBooleanBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "boolean", "PgBoolean");
  }
  /** @internal */
  build(table) {
    return new PgBoolean(table, this.config);
  }
};
_a83 = entityKind;
__publicField(PgBooleanBuilder, _a83, "PgBooleanBuilder");
var _a84;
var PgBoolean = class extends PgColumn {
  getSQLType() {
    return "boolean";
  }
};
_a84 = entityKind;
__publicField(PgBoolean, _a84, "PgBoolean");
function boolean(name) {
  return new PgBooleanBuilder(name);
}
var _a85;
var PgCharBuilder = class extends PgColumnBuilder {
  constructor(name, config) {
    super(name, "string", "PgChar");
    this.config.length = config.length;
    this.config.enumValues = config.enum;
  }
  /** @internal */
  build(table) {
    return new PgChar(table, this.config);
  }
};
_a85 = entityKind;
__publicField(PgCharBuilder, _a85, "PgCharBuilder");
var _a86;
var PgChar = class extends PgColumn {
  length = this.config.length;
  enumValues = this.config.enumValues;
  getSQLType() {
    return this.length === void 0 ? `char` : `char(${this.length})`;
  }
};
_a86 = entityKind;
__publicField(PgChar, _a86, "PgChar");
function char(name, config = {}) {
  return new PgCharBuilder(name, config);
}
var _a87;
var PgCidrBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "string", "PgCidr");
  }
  /** @internal */
  build(table) {
    return new PgCidr(table, this.config);
  }
};
_a87 = entityKind;
__publicField(PgCidrBuilder, _a87, "PgCidrBuilder");
var _a88;
var PgCidr = class extends PgColumn {
  getSQLType() {
    return "cidr";
  }
};
_a88 = entityKind;
__publicField(PgCidr, _a88, "PgCidr");
var _a89;
var PgCustomColumnBuilder = class extends PgColumnBuilder {
  constructor(name, fieldConfig, customTypeParams) {
    super(name, "custom", "PgCustomColumn");
    this.config.fieldConfig = fieldConfig;
    this.config.customTypeParams = customTypeParams;
  }
  /** @internal */
  build(table) {
    return new PgCustomColumn(table, this.config);
  }
};
_a89 = entityKind;
__publicField(PgCustomColumnBuilder, _a89, "PgCustomColumnBuilder");
var _a90;
var PgCustomColumn = class extends PgColumn {
  sqlName;
  mapTo;
  mapFrom;
  constructor(table, config) {
    super(table, config);
    this.sqlName = config.customTypeParams.dataType(config.fieldConfig);
    this.mapTo = config.customTypeParams.toDriver;
    this.mapFrom = config.customTypeParams.fromDriver;
  }
  getSQLType() {
    return this.sqlName;
  }
  mapFromDriverValue(value) {
    return typeof this.mapFrom === "function" ? this.mapFrom(value) : value;
  }
  mapToDriverValue(value) {
    return typeof this.mapTo === "function" ? this.mapTo(value) : value;
  }
};
_a90 = entityKind;
__publicField(PgCustomColumn, _a90, "PgCustomColumn");
var _a91;
var PgDoublePrecisionBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "number", "PgDoublePrecision");
  }
  /** @internal */
  build(table) {
    return new PgDoublePrecision(table, this.config);
  }
};
_a91 = entityKind;
__publicField(PgDoublePrecisionBuilder, _a91, "PgDoublePrecisionBuilder");
var _a92;
var PgDoublePrecision = class extends PgColumn {
  getSQLType() {
    return "double precision";
  }
  mapFromDriverValue(value) {
    if (typeof value === "string") {
      return Number.parseFloat(value);
    }
    return value;
  }
};
_a92 = entityKind;
__publicField(PgDoublePrecision, _a92, "PgDoublePrecision");
var isPgEnumSym = Symbol.for("drizzle:isPgEnum");
var _a93;
var PgEnumColumnBuilder = class extends PgColumnBuilder {
  constructor(name, enumInstance) {
    super(name, "string", "PgEnumColumn");
    this.config.enum = enumInstance;
  }
  /** @internal */
  build(table) {
    return new PgEnumColumn(table, this.config);
  }
};
_a93 = entityKind;
__publicField(PgEnumColumnBuilder, _a93, "PgEnumColumnBuilder");
var _a94;
var PgEnumColumn = class extends PgColumn {
  enum = this.config.enum;
  enumValues = this.config.enum.enumValues;
  constructor(table, config) {
    super(table, config);
    this.enum = config.enum;
  }
  getSQLType() {
    return this.enum.enumName;
  }
};
_a94 = entityKind;
__publicField(PgEnumColumn, _a94, "PgEnumColumn");
var _a95;
var PgInetBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "string", "PgInet");
  }
  /** @internal */
  build(table) {
    return new PgInet(table, this.config);
  }
};
_a95 = entityKind;
__publicField(PgInetBuilder, _a95, "PgInetBuilder");
var _a96;
var PgInet = class extends PgColumn {
  getSQLType() {
    return "inet";
  }
};
_a96 = entityKind;
__publicField(PgInet, _a96, "PgInet");
var _a97;
var PgIntegerBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "number", "PgInteger");
  }
  /** @internal */
  build(table) {
    return new PgInteger(table, this.config);
  }
};
_a97 = entityKind;
__publicField(PgIntegerBuilder, _a97, "PgIntegerBuilder");
var _a98;
var PgInteger = class extends PgColumn {
  getSQLType() {
    return "integer";
  }
  mapFromDriverValue(value) {
    if (typeof value === "string") {
      return Number.parseInt(value);
    }
    return value;
  }
};
_a98 = entityKind;
__publicField(PgInteger, _a98, "PgInteger");
var _a99;
var PgIntervalBuilder = class extends PgColumnBuilder {
  constructor(name, intervalConfig) {
    super(name, "string", "PgInterval");
    this.config.intervalConfig = intervalConfig;
  }
  /** @internal */
  build(table) {
    return new PgInterval(table, this.config);
  }
};
_a99 = entityKind;
__publicField(PgIntervalBuilder, _a99, "PgIntervalBuilder");
var _a100;
var PgInterval = class extends PgColumn {
  fields = this.config.intervalConfig.fields;
  precision = this.config.intervalConfig.precision;
  getSQLType() {
    const fields = this.fields ? ` ${this.fields}` : "";
    const precision = this.precision ? `(${this.precision})` : "";
    return `interval${fields}${precision}`;
  }
};
_a100 = entityKind;
__publicField(PgInterval, _a100, "PgInterval");
var _a101;
var PgMacaddrBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "string", "PgMacaddr");
  }
  /** @internal */
  build(table) {
    return new PgMacaddr(table, this.config);
  }
};
_a101 = entityKind;
__publicField(PgMacaddrBuilder, _a101, "PgMacaddrBuilder");
var _a102;
var PgMacaddr = class extends PgColumn {
  getSQLType() {
    return "macaddr";
  }
};
_a102 = entityKind;
__publicField(PgMacaddr, _a102, "PgMacaddr");
var _a103;
var PgMacaddr8Builder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "string", "PgMacaddr8");
  }
  /** @internal */
  build(table) {
    return new PgMacaddr8(table, this.config);
  }
};
_a103 = entityKind;
__publicField(PgMacaddr8Builder, _a103, "PgMacaddr8Builder");
var _a104;
var PgMacaddr8 = class extends PgColumn {
  getSQLType() {
    return "macaddr8";
  }
};
_a104 = entityKind;
__publicField(PgMacaddr8, _a104, "PgMacaddr8");
var _a105;
var PgRealBuilder = class extends PgColumnBuilder {
  constructor(name, length) {
    super(name, "number", "PgReal");
    this.config.length = length;
  }
  /** @internal */
  build(table) {
    return new PgReal(table, this.config);
  }
};
_a105 = entityKind;
__publicField(PgRealBuilder, _a105, "PgRealBuilder");
var _a106;
var PgReal = class extends PgColumn {
  constructor(table, config) {
    super(table, config);
  }
  getSQLType() {
    return "real";
  }
  mapFromDriverValue = (value) => {
    if (typeof value === "string") {
      return Number.parseFloat(value);
    }
    return value;
  };
};
_a106 = entityKind;
__publicField(PgReal, _a106, "PgReal");
var _a107;
var PgSerialBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "number", "PgSerial");
    this.config.hasDefault = true;
    this.config.notNull = true;
  }
  /** @internal */
  build(table) {
    return new PgSerial(table, this.config);
  }
};
_a107 = entityKind;
__publicField(PgSerialBuilder, _a107, "PgSerialBuilder");
var _a108;
var PgSerial = class extends PgColumn {
  getSQLType() {
    return "serial";
  }
};
_a108 = entityKind;
__publicField(PgSerial, _a108, "PgSerial");
function serial(name) {
  return new PgSerialBuilder(name);
}
var _a109;
var PgSmallIntBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "number", "PgSmallInt");
  }
  /** @internal */
  build(table) {
    return new PgSmallInt(table, this.config);
  }
};
_a109 = entityKind;
__publicField(PgSmallIntBuilder, _a109, "PgSmallIntBuilder");
var _a110;
var PgSmallInt = class extends PgColumn {
  getSQLType() {
    return "smallint";
  }
  mapFromDriverValue = (value) => {
    if (typeof value === "string") {
      return Number(value);
    }
    return value;
  };
};
_a110 = entityKind;
__publicField(PgSmallInt, _a110, "PgSmallInt");
var _a111;
var PgSmallSerialBuilder = class extends PgColumnBuilder {
  constructor(name) {
    super(name, "number", "PgSmallSerial");
    this.config.hasDefault = true;
    this.config.notNull = true;
  }
  /** @internal */
  build(table) {
    return new PgSmallSerial(table, this.config);
  }
};
_a111 = entityKind;
__publicField(PgSmallSerialBuilder, _a111, "PgSmallSerialBuilder");
var _a112;
var PgSmallSerial = class extends PgColumn {
  getSQLType() {
    return "smallserial";
  }
};
_a112 = entityKind;
__publicField(PgSmallSerial, _a112, "PgSmallSerial");
var _a113;
var PgTextBuilder = class extends PgColumnBuilder {
  constructor(name, config) {
    super(name, "string", "PgText");
    this.config.enumValues = config.enum;
  }
  /** @internal */
  build(table) {
    return new PgText(table, this.config);
  }
};
_a113 = entityKind;
__publicField(PgTextBuilder, _a113, "PgTextBuilder");
var _a114;
var PgText = class extends PgColumn {
  enumValues = this.config.enumValues;
  getSQLType() {
    return "text";
  }
};
_a114 = entityKind;
__publicField(PgText, _a114, "PgText");
function text(name, config = {}) {
  return new PgTextBuilder(name, config);
}
var _a115;
var PgVarcharBuilder = class extends PgColumnBuilder {
  constructor(name, config) {
    super(name, "string", "PgVarchar");
    this.config.length = config.length;
    this.config.enumValues = config.enum;
  }
  /** @internal */
  build(table) {
    return new PgVarchar(table, this.config);
  }
};
_a115 = entityKind;
__publicField(PgVarcharBuilder, _a115, "PgVarcharBuilder");
var _a116;
var PgVarchar = class extends PgColumn {
  length = this.config.length;
  enumValues = this.config.enumValues;
  getSQLType() {
    return this.length === void 0 ? `varchar` : `varchar(${this.length})`;
  }
};
_a116 = entityKind;
__publicField(PgVarchar, _a116, "PgVarchar");
var _a117;
var PgSchema = class {
  schemaName;
  constructor(schemaName) {
    this.schemaName = schemaName;
  }
  table = (name, columns, extraConfig) => {
    return pgTableWithSchema(name, columns, extraConfig, this.schemaName);
  };
  view = (name, columns) => {
    return pgViewWithSchema(name, columns, this.schemaName);
  };
  materializedView = (name, columns) => {
    return pgMaterializedViewWithSchema(name, columns, this.schemaName);
  };
};
_a117 = entityKind;
__publicField(PgSchema, _a117, "PgSchema");

// src/lib/dbTables/CheckinStoreRecords.ts
var CheckinStoreRecordsTable = pgTable(
  "checkin_store",
  {
    id: serial("id").primaryKey(),
    storeId: serial("store_id").notNull(),
    isTransferred: boolean("is_transferred").notNull().default(false),
    transferTransactionUrl: text("transfer_transaction_url").default(""),
    email: text("email"),
    walletAddress: char("wallet_address", { length: 42 }),
    isDeleted: boolean("is_deleted").notNull().default(false),
    checkInDate: timestamp("check_in_date").defaultNow().notNull()
  },
  (checknStores) => {
    return {
      uniqueIdx: uniqueIndex("unique_idx").on(checknStores.email)
    };
  }
);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ActiveEventItem,
  ActiveEventsList,
  AppsModal,
  AppsSlide,
  AuthorizationSubHeaderForProposal,
  AuthorizedByNFTHeader,
  BrandSalesRankingData,
  Button,
  ButtonWithChildren,
  CheckInCta,
  CheckInRewardModal,
  CheckinStoreRecordsTable,
  ConnectWalletModal,
  ContainerLayout,
  DashboardIndicators,
  DashboardTabview,
  DeStoreThemeProvider,
  DeployedContractItem,
  DeployedContractsList,
  ExploreDeStoreModal,
  FungibleTokenList,
  GetTokenReceiveSection,
  Header,
  HomeCta,
  HomeEnterDeStoreSection,
  HomeExploreDeStoreSection,
  Image,
  InitSpinner,
  KeyData,
  LineItemData,
  Link,
  LiveHeaderCenterChildren,
  LiveLineItems,
  LiveSalesInfoItem,
  LiveSalesInfoItems,
  MembershipNFTsItem,
  MembershipNFTsList,
  MobileHeader,
  Modal,
  NetworkDifferentModal,
  OutlineButton,
  POAPNFTsList,
  PrimaryButton,
  ProfileData,
  ProposalMenuAppData,
  ProposalRequirementSection,
  ProposalsUserRequirementSection,
  SalesAndExpenseData,
  SettingsHeaderCenterChildren,
  SettingsItem,
  SettingsItems,
  SettingsItemsPropsData,
  Spacer,
  Spinner,
  StoreData,
  StoreHeaderTitle,
  StoreIndicatorsData,
  StoreKeyBaseInfo,
  StoreKeyDescriptionItem,
  StoreKeyList,
  StoreKeyListItem,
  StoreListItem,
  StoreLiveStreamingData,
  StoreMenuAppData,
  StoreNFTsBaseInfo,
  StoreNFTsVisualImage,
  StoreSalesInfoData,
  TabGroupSpacer,
  TabOverlay,
  Text,
  Toasts,
  WalletConfirmModal,
  closeAllModal,
  connectWallet,
  disconnectWallet,
  hideToast,
  paperAuthLoadingEnd,
  paperAuthLoadingStart,
  poapAccessEnd,
  poapAccessStart,
  showConfirmModal,
  showConnectModal,
  showToast,
  store,
  storeZeroAddresses,
  useAppDispatch,
  useAppSelector,
  useLinking,
  useMediaQuery,
  useTheme
});
