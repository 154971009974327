import { ethers } from 'ethers'

export const getProvider = () => {
  const alchemyProviderUrl = process.env.REACT_APP_ALCHEMY_PROVIDER_URL

  if (!alchemyProviderUrl) {
    throw new Error('alchemyProvider is not defined')
  }

  return new ethers.JsonRpcProvider(alchemyProviderUrl)
}
