import {
  StoreData,
  useTheme,
  Text,
  PrimaryButton,
  RootState,
  useAppSelector,
  useAppDispatch,
  showConfirmModal,
  Address,
  Button,
} from 'ds_share'
import { useMemo, useState } from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { useLoaderData, useParams } from 'react-router-dom'
import { useAccount } from 'wagmi'

import { useToast } from '../../hooks/useToast'
import { claimPoap } from '../../repository/apis/claimPOAP'
import { NoMatch } from '../noMatch'

export const EventPoap = () => {
  const { isConnected, address } = useAccount()
  const dispatch = useAppDispatch()
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const connectedPaperWalletAddress = useAppSelector(
    (state: RootState) => state.auth.connectedPaperWallet?.walletAddress
  )
  const poapTokenUri = useLoaderData() as string
  const theme = useTheme()
  const { storeName, poapSlug } = useParams()
  const currentStore = StoreData.data.find((store) => store.name === storeName)
  const currentStorePoaps = currentStore?.poapNFTs
  const foundPoap = currentStorePoaps?.find((poap) => poap.poapSlug === poapSlug)
  const storeManagerContractAddress: Address | undefined = currentStore?.deployedAddresses?.StoreManager
  const { showErrorToast, showSuccessToast } = useToast()
  const poapAccessFlag = useAppSelector((state: RootState) => state.auth.poapAccessFlag)

  const [isLoading, setIsLoading] = useState(false)

  if (!storeManagerContractAddress) {
    throw new Error('storeManagerContractAddress is undefined')
  }

  if (!poapTokenUri || !poapAccessFlag) {
    return <NoMatch />
  }
  const claimTokenHandle = (contractAddress: Address, tokenId: number) => {
    if (isConnected || isConnectPaperWallet) {
      const userAddress = () => {
        return isConnected ? address : connectedPaperWalletAddress
      }

      if (!userAddress) {
        showErrorToast('Something went wrong. Please try again.')
      }

      const params = {
        storeManagerContractAddress: contractAddress,
        toAddress: userAddress() as unknown as Address,
        poapTokenId: tokenId,
      }

      setIsLoading(true)
      claimPoap(params)
        .then(() => {
          showSuccessToast('Claim POAP successfully')
        })
        .catch((error) => {
          showErrorToast(error.message)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      console.log('not connected status')
      dispatch(showConfirmModal())
    }
  }

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 250 : 420,
        marginVertical: theme?.media.isMobile ? 48 : 72,
        marginHorizontal: theme?.media.isMobile ? 40 : 72,
        padding: 16,
        borderRadius: 16,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        textAlign: 'center',
        alignContent: 'center',
      },
      imageContainer: {
        marginHorizontal: theme?.media.isMobile ? 12 : 20,
        alignItems: 'center',
      },

      descriptionContainer: {
        marginTop: 20,
        textAlign: 'center',
      },
      name: {
        color: theme?.color.mint,
        fontSize: theme?.fontSize['2xl'],
        paddingVertical: 20,
      },
      eventDate: {
        marginTop: 8,
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize.xs,
      },
      poapClaimButton: {
        width: 'max-content',
        marginVertical: 20,
        marginHorizontal: 'auto',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderRadius: theme?.radius.full,
        fontSize: theme?.fontSize.xl,
        color: theme?.color.primaryBlack,
        backgroundColor: theme?.color.mint,
      },
      poapDetailLink: {
        flex: 1,
        fontSize: theme?.media.isMobile ? 12 : 16,
        color: theme?.color.secondaryWhite,
        textDecorationLine: 'underline',
        padding: 8,
      },
    })
  }, [theme?.media.isMobile])

  if (!currentStore || !foundPoap) return <NoMatch />

  return (
    <View style={styles.container}>
      <Text style={styles.name}>{foundPoap.title}</Text>
      <View style={styles.imageContainer}>
        <Image
          source={{
            uri: poapTokenUri,
            height: theme?.media.isMobile ? 200 : 300,
            width: theme?.media.isMobile ? 200 : 300,
          }}
        />
      </View>
      <View style={styles.descriptionContainer}>
        <Text style={styles.eventDate}>{foundPoap?.eventDate}</Text>
        <Text
          numberOfLines={theme?.media.isMobile ? 4 : 1}
          style={styles.poapDetailLink}
          onPress={() => window.open(foundPoap.poapDetailUrl)}
        >
          Click here to see the detail
        </Text>
        {isLoading ? (
          <Button title="Claiming..." />
        ) : (
          <>
            <PrimaryButton
              customStyle={styles.poapClaimButton}
              title="Claim POAP"
              onPress={() => claimTokenHandle(storeManagerContractAddress, foundPoap.tokenId)}
            />
          </>
        )}
      </View>
    </View>
  )
}
