import axios, { isAxiosError } from 'axios'
import { Address } from 'ds_share'

export const fetchUsersBalancesAndNfts = async (address: Address, tokenAddresses: Address[]) => {
  let vercel_url = process.env.REACT_APP_VERCEL_URL
  if (!vercel_url) {
    vercel_url = 'dev.desto.re'
    console.info('REACT_APP_VERCEL_URL is not set')
  }
  const endpoint = 'https://' + vercel_url + '/api/moralis/getNFTs'
  const headers = {
    'Content-Type': 'application/json',
  }
  try {
    const response = await axios.post(
      endpoint,
      {
        address,
        tokenAddresses,
      },
      { headers: headers }
    )
    if (response.status !== 200) {
      throw new Error(`Unexpected response status: ${response.status}`)
    }

    if (!response.data) {
      throw new Error('No data received from the API')
    }

    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Failed to fetch users balances and nfts:', error)
    if (isAxiosError(error) && error.response) {
      console.error('Error response:', error.response)
    }
    throw error
  }
}
