import { useTheme } from 'ds_share'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

type HeaderProps = {
  selectedStatus: 'All' | 'Transferred' | 'Not Transferred'
  setSelectedStatus: (status: 'All' | 'Transferred' | 'Not Transferred') => void
}

export const TableHeader: React.FC<HeaderProps> = ({ selectedStatus, setSelectedStatus }) => {
  const theme = useTheme()
  const styles = StyleSheet.create({
    tabs: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    tab: {
      flex: 1,
      alignItems: 'center',
      paddingVertical: 8,
      borderBottomWidth: 1,
      borderBottomColor: theme?.color.secondaryGray,
    },
    activeTab: {
      borderBottomColor: theme?.color.pink,
    },
    tabText: {
      color: 'gray',
      fontSize: 12,
    },
    activeTabText: {
      color: theme?.color.pink,
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: theme?.color.secondaryWhite,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderBottomWidth: 1,
      borderBottomColor: theme?.color.secondaryGray,
    },
    headerText: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 14,
      padding: 8,
      textAlign: 'center',
    },
  })

  return (
    <View>
      <View style={styles.tabs}>
        <TouchableOpacity
          style={[styles.tab, selectedStatus === 'All' && styles.activeTab]}
          onPress={() => setSelectedStatus('All')}
        >
          <Text style={[styles.tabText, selectedStatus === 'All' && styles.activeTabText]}>All</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tab, selectedStatus === 'Transferred' && styles.activeTab]}
          onPress={() => setSelectedStatus('Transferred')}
        >
          <Text style={[styles.tabText, selectedStatus === 'Transferred' && styles.activeTabText]}>Transferred</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tab, selectedStatus === 'Not Transferred' && styles.activeTab]}
          onPress={() => setSelectedStatus('Not Transferred')}
        >
          <Text style={[styles.tabText, selectedStatus === 'Not Transferred' && styles.activeTabText]}>
            Not Transferred
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.tableHeader}>
        <Text style={styles.headerText}>Token Type</Text>

        <Text style={styles.headerText}>Wallet Address</Text>
        <Text style={styles.headerText}>Email</Text>

        <Text style={styles.headerText}>Date</Text>
        <Text style={styles.headerText}>Status</Text>
        <Text style={styles.headerText}>Transaction URL</Text>
      </View>
    </View>
  )
}
