import { OutlineButton, PrimaryButton, useTheme } from 'ds_share'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import { DummyData } from '.'

const shortenWalletAddress = (address: string) => {
  return address.substr(0, 6) + '...' + address.substr(-6)
}

const formatCheckInDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }
  return new Date(date).toLocaleString(undefined, options)
}

type TableRowProps = {
  record: DummyData
  isMobile: boolean | undefined
  openTransaction: (url: string) => void
  handleTransfer: (record: DummyData) => void
}

export const TableRow: React.FC<TableRowProps> = ({ record, isMobile, openTransaction, handleTransfer }) => {
  const theme = useTheme()
  const styles = StyleSheet.create({
    cell: {
      flex: 1,
    },
    cellText: {
      flex: 1,
      color: 'gray',
      fontSize: isMobile ? 12 : 16,
      padding: 8,
      textAlign: 'center',
    },
    transactionLink: {
      flex: 1,
      fontSize: isMobile ? 12 : 16,
      color: theme?.color.secondaryWhite,
      textDecorationLine: 'underline',
      padding: 8,
    },
    transferred: {
      color: theme?.color.secondaryLiteGray,
    },
    notTransferred: {
      color: theme?.color.secondaryLightGreen,
    },
    tableRow: {
      flexDirection: 'row',
      paddingVertical: isMobile ? 6 : 12,
      borderBottomWidth: 1,
      borderBottomColor: '#DDDDDD',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    transferButton: {
      backgroundColor: theme?.color.mint,
      fontSize: isMobile ? 12 : 16,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: isMobile ? 6 : 8,
    },
    tokenType: {
      backgroundColor: theme?.color.secondaryGray,
      color: theme?.color.secondaryWhite,
      fontSize: isMobile ? 4 : 6,
      borderRadius: 4,
      paddingHorizontal: isMobile ? 6 : 8,
      textAlign: 'center',
    },
  })

  return (
    <View>
      <TouchableOpacity style={styles.tableRow} onPress={() => openTransaction(record.transferTransactionUrl)}>
        <OutlineButton title={record.tokenType} style={styles.tokenType} />
        <Text numberOfLines={isMobile ? 4 : 1} style={styles.cellText}>
          {shortenWalletAddress(record.walletAddress)}
        </Text>
        <Text numberOfLines={isMobile ? 4 : 1} style={styles.cellText}>
          {record.email}
        </Text>
        <Text numberOfLines={isMobile ? 4 : 1} style={styles.cellText}>
          {formatCheckInDate(record.checkInDate)}
        </Text>
        <Text
          numberOfLines={isMobile ? 4 : 1}
          style={[styles.cellText, record.isTransferred ? styles.transferred : styles.notTransferred]}
        >
          {record.isTransferred ? (
            'Transferred'
          ) : (
            <PrimaryButton
              customStyle={styles.transferButton}
              title="Transfer"
              onPress={() => handleTransfer(record)}
            />
          )}
        </Text>
        <Text numberOfLines={isMobile ? 4 : 1} style={styles.transactionLink}>
          {record.transferTransactionUrl || '-'}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
