import axios from 'axios'
import { Address } from 'ds_share'

interface ClaimPoapProps {
  storeManagerContractAddress: Address
  toAddress: Address
  poapTokenId: number
}

const token = process.env.REACT_APP_VERCEL_FUNCTIONS_AUTH_TOKEN
if (!token) {
  throw new Error('REACT_APP_VERCEL_FUNCTIONS_AUTH_TOKEN is not set')
}

export const claimPoap = (props: ClaimPoapProps) => {
  const { storeManagerContractAddress, toAddress, poapTokenId } = props
  const vercel_url = process.env.REACT_APP_VERCEL_URL
  if (!vercel_url) {
    throw new Error('REACT_APP_VERCEL_URL is not set')
  }
  const endpoint = 'https://' + vercel_url + '/api/claim-poap'

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  try {
    const result = axios.post(
      endpoint,
      {
        storeManagerContractAddress,
        toAddress,
        poapTokenId,
      },
      { headers: headers }
    )
    console.log(result)
    return result
  } catch (error) {
    console.error('Failed to claim poap:', error)
    throw error
  }
}
