import { Button, useTheme, Text } from 'ds_share'
import { useMemo } from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { useNavigate } from 'react-router-dom'

export const NoMatch = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        minWidth: theme?.media.isMobile ? 250 : 420,
        marginVertical: theme?.media.isMobile ? 48 : 72,
        marginHorizontal: theme?.media.isMobile ? 40 : 72,
        padding: 16,
        borderRadius: 16,
        textAlign: 'center',
        alignContent: 'center',
      },
      backtoHomeButton: {
        width: 'max-content',
        marginVertical: 20,
        marginHorizontal: 'auto',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderRadius: theme?.radius.full,
        fontSize: theme?.fontSize.xl,
        color: theme?.color.primaryBlack,
        backgroundColor: theme?.color.pink,
      },
      imageContainer: {
        marginHorizontal: theme?.media.isMobile ? 20 : 32,
        alignItems: 'center',
      },
      title: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize['xl'],
        paddingVertical: 20,
      },
      description: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize['md'],
      },
    })
  }, [theme?.media.isMobile])
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          source={{
            uri: '/404.png',
            height: theme?.media.isMobile ? 100 : 150,
            width: theme?.media.isMobile ? 100 : 150,
          }}
        />
        <Text style={styles.title}>Oops!</Text>
        <Text style={styles.description}>
          The page you are looking for might have been removed had its name changed or its temporarily unavailable
        </Text>
      </View>
      <Button title="Back to Home" onPress={() => navigate('/')} customStyle={styles.backtoHomeButton} />
    </View>
  )
}
