import { Address, StoreData, StoreDataType } from 'ds_share'
import { abi as storeAbi } from 'ds_share/src/artifacts/contracts/src/core/StoreManager.sol/StoreManagerABI'
import { ethers } from 'ethers'
import { LoaderFunctionArgs } from 'react-router-dom'

import { getProvider } from './provider'

export const fetchPoap = async ({ params }: LoaderFunctionArgs): Promise<string | null> => {
  console.log('fetchPoap params', params)
  const currentStore = StoreData.data.find((store: StoreDataType) => store.name === params.storeName)
  const contractStoreManagerAddress: Address | undefined = currentStore?.deployedAddresses?.StoreManager
  if (!currentStore || !contractStoreManagerAddress) {
    console.info('currentStore or contractStore manager are not defined')
    return null
  }
  const currentStorePoaps = currentStore?.poapNFTs
  const foundPoap = currentStorePoaps?.find((poap) => poap.poapSlug === params.poapSlug)

  if (!foundPoap || foundPoap.tokenId === undefined) {
    console.info('POAP not found or tokenId is not defined')
    return null
  }

  const provider = getProvider()
  const contract = new ethers.Contract(contractStoreManagerAddress, storeAbi, provider)
  console.log('contractStoreManagerAddress', contractStoreManagerAddress)

  try {
    const poapTokenURI = await contract.tokenURI(foundPoap.tokenId)
    console.log('poapTokenURI', foundPoap.tokenId, poapTokenURI)
    return poapTokenURI || null
  } catch (error) {
    console.error('Failed to fetch tokenURI:', error)
    return null
  }
}
