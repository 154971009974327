import {
  useAppSelector,
  type RootState,
  useTheme,
  MembershipNFTsList,
  POAPNFTsList,
  useAppDispatch,
  showConfirmModal,
  OutlineButton,
  Address,
  StoreData,
  UserPoapNFT,
  UserMembershipNFT,
  FungibleTokenList,
  UserStoreFungibleTokens,
} from 'ds_share'
import { useEffect, useMemo, useState } from 'react'
import { IoCopyOutline } from 'react-icons/io5'
import { SpinningCircles } from 'react-loading-icons'
import { View, StyleSheet, Text, TouchableOpacity, Clipboard } from 'react-native'
import { useAccount } from 'wagmi'

import { WalletModalHandling } from '../../components/common/WalletModalHandling'
import { fetchUsersBalancesAndNfts } from '../../repository/apis/fetchUserAssets'

type UserAsset = {
  contractType: string
  tokenAddress: string
  tokenId: string
  tokenUri: string
  amount: number
}

export const MyWallet = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const paperWalletAddress = useAppSelector((state: RootState) => state.auth.connectedPaperWallet?.walletAddress)
  const { isConnected, address } = useAccount()
  const userAddress = isConnectPaperWallet ? (paperWalletAddress as Address) : (address as Address | undefined)
  const isMobile = useTheme()?.media.isMobile
  const storeManagertokenAddresses: Address[] = StoreData.data
    .map((store) => store.deployedAddresses?.StoreManager)
    .filter((address): address is Address => address !== undefined)

  const connectedFlag = isConnectPaperWallet || isConnected

  const [usersFTokens, setUsersFTokens] = useState<UserStoreFungibleTokens[]>([])
  const [usersMembershipNFTs, setUsersMembershipNFTs] = useState<UserMembershipNFT[]>([])
  const [usersPOAPNFTSList, setUsersPOAPNFTSList] = useState<UserPoapNFT[]>([])

  const theme = useTheme()
  const styles = useMemo(() => {
    return StyleSheet.create({
      mywalletContainer: {
        width: '100%',
        paddingVertical: theme?.media.isMobile ? 0 : 40,
      },
      mywalletCurrentInfos: {
        paddingVertical: theme?.media.isMobile ? 60 : 80,
        paddingHorizontal: theme?.media.isMobile ? 32 : 0,
        textAlign: 'center',
      },
      myAddressContainer: {
        backgroundColor: theme?.color.primaryBlack,
        color: theme?.color.secondaryWhite,
        borderRadius: 30,
        paddingHorizontal: 4,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: theme?.media.isMobile ? 280 : 500,
        flexDirection: 'row',
      },
      myAddressText: {
        marginHorizontal: 4,
        marginVertical: 4,
        fontSize: theme?.media.isMobile ? theme?.fontSize['xxs'] : theme?.fontSize['md'],
        color: theme?.color.secondaryWhite,
      },
      destoreTokenInfo: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize['lg'],
      },
      connectWalletContainer: {
        paddingVertical: theme?.media.isMobile ? 60 : 80,
        paddingHorizontal: theme?.media.isMobile ? 32 : 0,
        textAlign: 'center',
      },
      connectWalletText: {
        color: theme?.color.secondaryWhite,
        fontSize: theme?.fontSize['3xl'],
      },
      connectWalletButton: {
        backgroundColor: theme?.color.mint,
        fontSize: isMobile ? 12 : 16,
        color: theme?.color.primaryBlack,
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 16,
        marginHorizontal: theme?.media.isMobile ? 60 : 80,
      },
      loadingContainer: {
        flex: 1,
        paddingTop: 200,
        justifyContent: 'center',
        alignItems: 'center',
      },
    })
  }, [theme])
  useEffect(() => {
    const fetchData = async () => {
      if (connectedFlag && userAddress) {
        setIsLoading(true)
        try {
          const result = await fetchUsersBalancesAndNfts(userAddress, storeManagertokenAddresses)
          classifyUserAssets(result)
        } catch (error) {
          console.error('Error fetching data:', error)
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchData()
  }, [isConnectPaperWallet, isConnected])

  const classifyUserAssets = (assets: UserAsset[]) => {
    const fTokens: UserStoreFungibleTokens[] = []
    const membershipNFTs: UserMembershipNFT[] = []
    const poapNFTs: UserPoapNFT[] = []

    assets.forEach((asset) => {
      const storeAsset = StoreData.data
        .flatMap((store) => [
          ...(store.poapNFTs ?? []),
          ...(store.membershipNFTs ?? []),
          ...(store.fungibleTokens ?? []),
        ])
        .find((sa) => sa.tokenId.toString() === asset.tokenId && sa.contractAddress === asset.tokenAddress)

      if (storeAsset) {
        if ('isFungible' in storeAsset) {
          const extendedAsset: UserStoreFungibleTokens = {
            ...storeAsset,
            amount: asset.amount,
            tokenUri: asset.tokenUri,
          }
          fTokens.push(extendedAsset)
        } else if ('isMembership' in storeAsset) {
          const extendedAsset: UserMembershipNFT | UserPoapNFT = {
            ...storeAsset,
            amount: asset.amount,
            tokenUri: asset.tokenUri,
          }
          if (storeAsset.isMembership) {
            membershipNFTs.push(extendedAsset as UserMembershipNFT)
          } else {
            poapNFTs.push(extendedAsset as UserPoapNFT)
          }
        }
      }
    })

    setUsersFTokens(fTokens)
    setUsersMembershipNFTs(membershipNFTs)
    setUsersPOAPNFTSList(poapNFTs)
  }

  const copyToClipboard = () => {
    Clipboard.setString(userAddress as string)
  }

  return (
    <>
      <WalletModalHandling />
      <View style={styles.mywalletContainer}>
        {connectedFlag ? (
          <>
            {isLoading ? (
              <View style={styles.loadingContainer}>
                <SpinningCircles color={theme?.color.mint} width={120} />
              </View>
            ) : (
              <>
                <FungibleTokenList usersFTokens={usersFTokens} />
                {userAddress && (
                  <TouchableOpacity style={styles.myAddressContainer} onPress={() => copyToClipboard()}>
                    <Text style={styles.myAddressText}>{userAddress}</Text>
                    <IoCopyOutline size={24} color={theme?.color.secondaryWhite} />
                  </TouchableOpacity>
                )}
                <MembershipNFTsList usersMembershipNFTs={usersMembershipNFTs} />
                <POAPNFTsList usersPOAPNFTSList={usersPOAPNFTSList} />
              </>
            )}
          </>
        ) : (
          <View style={styles.connectWalletContainer}>
            <Text style={styles.connectWalletText}>Please connect your wallet to confirm your assets</Text>
            <OutlineButton
              title="Connect Wallet"
              customStyle={styles.connectWalletButton}
              onPress={() => dispatch(showConfirmModal())}
            />
          </View>
        )}
      </View>
    </>
  )
}
