import { RootState, poapAccessStart, useAppDispatch, useAppSelector } from 'ds_share'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const PoapAccess = () => {
  const navigate = useNavigate()
  const poapAccessFlag = useAppSelector((state: RootState) => state.auth.poapAccessFlag)
  const { storeName, poapSlug } = useParams()
  const dispatch = useAppDispatch()
  useEffect(() => {
    console.log('poapAccessFlag', poapAccessFlag)
    dispatch(poapAccessStart())
    navigate(`/${storeName}/POAP/${poapSlug}`)
  }, [poapAccessFlag])
  return <></>
}
