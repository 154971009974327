import {
  CheckInCta,
  useTheme,
  useAppSelector,
  type RootState,
  GetTokenReceiveSection,
  useAppDispatch,
  showConfirmModal,
  CheckInRewardModal,
} from 'ds_share'
import { useState } from 'react'
import { useAccount } from 'wagmi'

import { WalletModalHandling } from '../../components/common/WalletModalHandling'

import styles from './CheckinStyle.module.css'

export const CheckIn = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const paperWalletAddress = useAppSelector((state: RootState) => state.auth.connectedPaperWallet?.walletAddress)
  const [isShowRewardModal, setIsShowRewardModal] = useState(false)
  const { isConnected, address } = useAccount()

  const headerText = 'You have received 1 token'

  const handleShowConfirmModal = () => {
    if (isConnectPaperWallet || isConnected) {
      console.log('User Address:', address, paperWalletAddress)
      setIsShowRewardModal(true)
      tokenClaim()
    } else {
      dispatch(showConfirmModal())
    }
  }

  function tokenClaim() {
    console.log('Token Claim')
  }
  return (
    <>
      <WalletModalHandling />
      <CheckInRewardModal
        visible={isShowRewardModal}
        onRequestClose={() => setIsShowRewardModal(false)}
        headerText={headerText}
        visibleNavtoMywalletButton={true}
      />
      <div className={theme?.media.isMobile ? styles.mobileContainer : styles.container}>
        <CheckInCta logoImagePath="/logo/logo_icon_white.svg" keyImagePath="/store/storezero-coin.png" />
        <div className={styles.buttonContainer}>
          <GetTokenReceiveSection handleOpenClick={handleShowConfirmModal} />
        </div>
      </div>
    </>
  )
}
