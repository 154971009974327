import { RouteObject } from 'react-router-dom'

import {
  AuthenticationLayout,
  // AuthorizedByNFTLayout,
  // AuthorizedLayout,
  UnauthorizedLayout,
} from '../components/layout'
//import { Dashboard } from '../pages/dashboard'
import { Admin } from '../pages/admin'
import { CheckIn } from '../pages/checkin'
import { EventPoap } from '../pages/eventpoap'
import { Home } from '../pages/home'
//import { Live } from '../pages/live'
import { MyWallet } from '../pages/mywallet'
import { NoMatch } from '../pages/noMatch'
import { ProposalByWeek } from '../pages/proposals/byWeek'
//import { Settings } from '../pages/settings'
import { PoapAccess } from '../pages/qr/poapAccess'
import { Stores } from '../pages/stores'
import { BuyStoreNFT } from '../pages/stores/buyStoreNFT'
import { StoreNFTs } from '../pages/stores/storeNFTs'
import { fetchPoap } from '../repository/contracts/poap'
import { fetchProposals } from '../repository/contracts/proposals'

const proposalRoutes = [{ path: ':proposalByWeek', element: <ProposalByWeek />, loader: fetchProposals }]

export const webRoutes: RouteObject[] = [
  {
    path: '/',
    element: <AuthenticationLayout />,
    children: [
      {
        path: '/',
        element: <UnauthorizedLayout />,
        children: [
          { index: true, element: <Home /> },
          { index: true, path: 'stores', element: <Stores /> },
          { path: 'mywallet', element: <MyWallet /> },
          { path: ':storeName', element: <StoreNFTs /> },
          { path: ':storeName/nft', element: <BuyStoreNFT /> },
          { path: ':storeName/proposals', children: proposalRoutes },
          { path: ':storeName/POAP/:poapSlug/access', element: <PoapAccess /> },
          { path: ':storeName/POAP/:poapSlug', element: <EventPoap />, loader: fetchPoap },
          { path: ':storeName/checkin', element: <CheckIn /> },
          { path: ':storeName/admin', element: <Admin /> },
          { path: '*', element: <NoMatch /> },
        ],
      },

      // {
      //   path: '/',
      //   element: <AuthorizedLayout />,
      //   children: [
      //     { path: 'nfts/:storeName', element: <BuyStoreNFT /> },
      //     // { path: 'proposals', children: proposalRoutes },
      //     { path: '*', element: <NoMatch /> },
      //   ],
      // },
      // {
      //   path: '/',
      //   element: <AuthorizedByNFTLayout />,
      //   children: [
      //     { path: 'dashboard', element: <Dashboard /> },
      //     { path: 'live', element: <Live /> },
      //     { path: 'settings', element: <Settings /> },
      //     // { path: 'proposals', children: proposalRoutes },
      //     { path: '*', element: <NoMatch /> },
      //   ],
      // },
    ],
  },
]
