import {
  ConnectWalletModal,
  RootState,
  WalletConfirmModal,
  useAppDispatch,
  useAppSelector,
  showConfirmModal,
  closeAllModal,
} from 'ds_share'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'

import { paperSDKLogin } from '../../lib/paperSDK'
import { ConnectWalletLists } from '../ConnectWalletLists'

export const WalletModalHandling = () => {
  const isShowConfirmModal = useAppSelector((state: RootState) => state.modalCtrl.isShowConfirmModal)
  const isShowConnectModal = useAppSelector((state: RootState) => state.modalCtrl.isShowConnectModal)
  const isConnectPaperWallet = useAppSelector((state: RootState) => state.auth.isConnectPaperWallet)
  const dispatch = useAppDispatch()
  const { isConnected } = useAccount()

  useEffect(() => {
    if (isConnectPaperWallet || isConnected) {
      dispatch(closeAllModal())
    }
  }, [isConnectPaperWallet, isConnected])

  return (
    <>
      <WalletConfirmModal
        visible={isShowConfirmModal}
        onRequestClose={() => dispatch(closeAllModal())}
        handleCreateWallet={() =>
          paperSDKLogin().then((res) => {
            console.log(res)
            // setPaperWalletAddress(res.user.walletAddress)
          })
        }
      />
      <ConnectWalletModal
        visible={isShowConnectModal}
        bodyChildren={
          <ConnectWalletLists
            handleNextAction={() => {
              dispatch(showConfirmModal())
            }}
          />
        }
        onRequestClose={() => dispatch(closeAllModal())}
      />
    </>
  )
}
