import { useTheme } from 'ds_share'
import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'

type TokenType = 'Check In' | 'Initial Visit'

export type DummyData = {
  id: number
  tokenType: TokenType
  storeId: number
  isTransferred: boolean
  transferTransactionUrl: string
  email: string
  walletAddress: string
  checkInDate: Date
}

const dummyData: DummyData[] = [
  {
    id: 1,
    tokenType: 'Check In',
    storeId: 101,
    isTransferred: false,
    transferTransactionUrl: '',
    email: 'john@example.com',
    walletAddress: '0x1234567890abcdef1234567890abcdef12345678',
    checkInDate: new Date('2023-10-16T12:00:00Z'),
  },
  {
    id: 2,
    tokenType: 'Initial Visit',
    storeId: 102,
    isTransferred: true,
    transferTransactionUrl: 'https://example.com/transaction/123',
    email: 'jadalsdknalksnklansklasndklansdlknne@example.com',
    walletAddress: '0x9876543210abcdef9876543210abcdef98765432',
    checkInDate: new Date('2023-10-15T15:30:00Z'),
  },
  {
    id: 4,
    tokenType: 'Check In',
    storeId: 102,
    isTransferred: true,
    transferTransactionUrl: 'https://example.com/transaction/123',
    email: 'jane@example.com',
    walletAddress: '0x9876543210abcdef9876543210abcdef98765432',
    checkInDate: new Date('2023-10-15T15:30:00Z'),
  },
  {
    id: 5,
    tokenType: 'Check In',
    storeId: 101,
    isTransferred: true,
    transferTransactionUrl: '',
    email: 'john@example.com',
    walletAddress: '0x1234567890abcdef1234567890abcdef12345678',
    checkInDate: new Date('2023-10-16T12:00:00Z'),
  },
]

export const Admin: React.FC = () => {
  const isConnectPaperWallet = false
  const isConnected = false
  const isMobile = useTheme()?.media.isMobile
  const [selectedStatus, setSelectedStatus] = useState<'All' | 'Transferred' | 'Not Transferred'>('All')

  const [, setIsShowConnectModal] = useState(false)

  const handleTransfer = (record: DummyData) => {
    if (!record.isTransferred) {
      console.log('Transfer user:', record.id)
    }
  }

  const openTransaction = (url: string) => {
    if (url) {
      console.log('Open transaction:', url)
    }
  }
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 16,
      marginTop: 32,
    },
  })

  useEffect(() => {
    if (isConnectPaperWallet || isConnected) {
      setIsShowConnectModal(false)
    }
  }, [isConnectPaperWallet, isConnected])

  const filteredData = dummyData.filter((record) => {
    if (selectedStatus === 'All') {
      return true
    }
    if (selectedStatus === 'Transferred') {
      return record.isTransferred
    }
    if (selectedStatus === 'Not Transferred') {
      return !record.isTransferred
    }
    return true
  })

  return (
    <ScrollView style={styles.container}>
      <TableHeader selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />

      {filteredData.map((record) => (
        <TableRow
          key={record.id}
          record={record}
          isMobile={isMobile}
          openTransaction={openTransaction}
          handleTransfer={handleTransfer}
        />
      ))}
    </ScrollView>
  )
}
