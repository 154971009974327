import { Address } from 'ds_share'
import { abi as storeManagerAbi } from 'ds_share/src/artifacts/contracts/src/core/StoreManager.sol/StoreManagerABI'
import { abi as voteAbi } from 'ds_share/src/artifacts/contracts/src/core/StoreVote.sol/StoreVoteABI'

export const getWagmiConfig = (contract_address: Address, functionName: string, overrides?: any) => {
  const getAbifromfunctionName = (functionName: string) => {
    switch (functionName) {
      case 'vote':
        return voteAbi
      case 'claimTo':
        return storeManagerAbi
    }
  }
  const chainId =
    process.env['REACT_APP_VERCEL_ENV'] === 'production'
      ? process.env.REACT_APP_MAIN_CHAINID
      : process.env.REACT_APP_TEST_CHAINID

  if (!chainId) {
    throw new Error('chainId is not defined')
  }
  return {
    address: contract_address,
    abi: getAbifromfunctionName(functionName),
    functionName: functionName,
    overrides: overrides,
  }
}
